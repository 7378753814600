import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dumb';

import './rightContent.css';

const AreaRightContent = () => {
	return (
		<div className="areas-right">
			<Button className="areas-right__delete" type="danger">
				<FontAwesomeIcon icon={['fas', 'trash-alt']} />
			</Button>
		</div>
	);
};

export default AreaRightContent;
