import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	executeTask,
	overwriteCountAreaProducts,
} from './countReviewZero.services';
import { resetState } from '../countPage/store/countPage.actions';

import CountReview from './countReviewZero.component';
import { Button, Loader } from '../../../library/components';
import { Navigation } from '@smart';
import history from '../../../routerHistory.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import phrases from './countReviewZero.phrases';
import { calculateItemCounts } from 'services/calculateItemCounts';
import * as Sentry from '@sentry/browser';

import _isEmpty from 'lodash/isEmpty';

class CountReviewZeroContainer extends Component {
	constructor(props) {
		super(props);

		this.onSign = this.onSign.bind(this);


		this.state = {
			loader: false
		};
	}

	componentDidMount() {
		const { selectedTaskDetails, itemList } = this.props;
		// Task details are missing, we will redirect user to front page to select task again
		if (_isEmpty(selectedTaskDetails) || _isEmpty(itemList)) {
			// Maybe message first??
			history.push({
				pathname: '/tasks',
				state: { transition: 'slide-right', duration: 150 },
			});
		}
	}

	componentDidCatch(error) {
		// You can also log the error to an error reporting service
		Sentry.withScope((scope) => {
			scope.setExtra('error', error);
			Sentry.captureMessage('CountReviewZeroContainer ', 'info');
		});

	  }

	onSign() {
		const {
			countedItems,
			resetState,
			selectedTaskDetails,
			selectedWorkplace,
			countData,
		} = this.props;

		this.setState(() => ({loader: true}));
		executeTask(selectedTaskDetails, selectedWorkplace, countedItems)
			.then(() => {
				const countAreas = countData[countData.taskId];
				const countAreaKeys = Object.keys(countAreas);

				countAreaKeys.forEach((countAreaId) => {
					const areaCount = countAreas[countAreaId];

					// If count was somehow is empty, simply skip, so last suggested would stay
					if (_isEmpty(areaCount.counted)) return;

					const rawProductArray = Object.keys(areaCount.counted)
						.map((countId) => areaCount.counted[countId].rawProductId)
						.filter((x) => x);

					return overwriteCountAreaProducts(countAreaId, rawProductArray);
				});

				resetState();
				history.push({
					pathname: '/tasks/counts/confirmation',
					state: { transition: 'slide-left', duration: 150 },
				});
				this.setState(() => ({loader: false}));
			})
			.catch(() => {
				this.setState(() => ({loader: false}));
			});
	}

	onBackClick() {
		history.push({
			pathname: '/tasks/counts/review',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	goBackToAreas() {
		history.push({
			pathname: '/tasks/areas/overview',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	render() {
		const {
			countedItems,
			listOrder,
			countedList,
			itemList,
		} = this.props;

		return (
			<div>
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={phrases.COUNT_ZERO_REVIEW}
					rightContent={
						this.state.loader ? (
							<Loader size="tiny" active />
						) : (
							<Button type="text" onClick={this.onSign}>
								{phrases.SIGN}
							</Button>
						)
					}
				/>

				<CountReview
					onClick={this.goBackToAreas}
					countedItems={countedItems}
					countedList={countedList}
					itemList={itemList}
					listOrder={listOrder}
				/>
			</div>
		);
	}
}

CountReviewZeroContainer.propTypes = {
	countData: PropTypes.object,
	resetState: PropTypes.func,
	selectedTask: PropTypes.object,
	itemList: PropTypes.object,
	selectedTaskDetails: PropTypes.object,
	selectedWorkplace: PropTypes.object,
	countedItems: PropTypes.object,
	countedList: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (state) => ({
	countData: state.count.data,
	countedList: state.count.data.counted,
	countedItems: calculateItemCounts({
		countData: state.count.data,
	}),
	listOrder: state.count.ui.listOrder,

	selectedTaskDetails: state.storeTasks.selectedTask,
	selectedWorkplace: state.workplaces.data.selectedWorkplace,
	//itemsList: state.count.data.itemList,
	itemList:
		state.count.data?.[state.count.data?.taskId]?.[state.count.data?.areaId]
			?.itemList ?? {},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CountReviewZeroContainer);
