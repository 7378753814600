import {
	SET_WORKPLACES,
	WORKPLACE_SELECTED,
	TOGGLE_LOADER,
} from './workplaceSelection.actions';
import listService from '../../../services/listService';

const initState = {
	data: {
		workplaces: [],
		selectedWorkplace: null,
	},
	ui: {
		workplaceOrder: [],
		loading: false,
	},
};

export default (state = initState, action) => {
	switch (action.type) {
	case SET_WORKPLACES: {
		const workplaceOrder = listService.sortObjectByAlphabet(action.payload);
		return {
			...state,
			data: { ...state.data, workplaces: action.payload },
			ui: { ...state.ui, workplaceOrder },
		};
	}
	case WORKPLACE_SELECTED: {
		return {
			...state,
			data: { ...state.data, selectedWorkplace: action.payload },
		};
	}
	case TOGGLE_LOADER: {
		return { ...state, ui: { ...state.ui, loading: action.payload } };
	}

	default:
		return state;
	}
};
