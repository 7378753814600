import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Burger } from '../index.js';

// phrases
import phrases from './navigation.phrases.js';

// auth service
import Auth from './../../../auth';

// styles
import './navigation.css';

const AuthService = new Auth();

class Navigation extends Component {
	componentDidMount() {
		document.addEventListener('touchmove', this.preventDefault, {
			passive: false,
		});

		document.body.classList.add('scroll-lock');
	}

	componentWillUnmount() {
		document.removeEventListener('touchmove', this.preventDefault);

		document.body.classList.remove('scroll-lock');
	}

	preventDefault(e) {
		e.preventDefault();
	}

	_logOut() {
		AuthService.logout();
	}

	render() {
		const { toggleNavigation } = this.props;

		return (
			<div className="navigation">
				<div className="navigation__header">
					<Burger onClick={() => toggleNavigation()} />
				</div>
				<ul className="navigation__body">
					<li>
						<Header>{phrases.ORDER_HEADER}</Header>
						<ul className="navigation__list">
							<li>
								<Link to="/tasks" onClick={() => toggleNavigation()}>
									{phrases.CURRENT_TASKS}
								</Link>
							</li>
							<li>
								<Link to="/workplaces" onClick={() => toggleNavigation()}>
									{phrases.WORKPLACES}
								</Link>
							</li>
							<li>
								<a
									href="https://joejuice.typeform.com/to/EjDK2VVg"
									target="_blank"
									rel="noopener noreferrer">
									{phrases.DELIVERY_REPORTING}
								</a>
							</li>
							<li>
								<a
									href="https://joejuice.typeform.com/to/mrrCTS"
									target="_blank"
									rel="noopener noreferrer">
									{phrases.APPLIENCE_REPORTING}
								</a>
							</li>

							<li>
								<a
									href="https://joejuice.typeform.com/to/D5UgDWlL"
									target="_blank"
									rel="noopener noreferrer">
									{phrases.STORE_TRANSFERS}
								</a>
							</li>
							<li>
								<a
									href="https://joejuice.typeform.com/to/CYztZJ9m"
									target="_blank"
									rel="noopener noreferrer">
									{phrases.STORE_CHECK}
								</a>
							</li>
							<li>
								<span onClick={() => this._logOut()}>{phrases.LOG_OUT}</span>
							</li>
							{/*// eslint-disable-line */}
							{/* <li><Link to="/" onClick={() => toggleNavigation()}>{phrases.HISTORY}</Link></li>
							<li><Link to="/" onClick={() => toggleNavigation()}>{phrases.CONFIGURATION}</Link></li> */}
						</ul>
					</li>
					{/* <li>
						<Header>{phrases.MAINTENANCE}</Header>
						<ul className='navigation__list'>
							<li><Link to="/" onClick={() => toggleNavigation()}>{phrases.NEW_REQUEST}</Link></li>
							<li><Link to="/" onClick={() => toggleNavigation()}>{phrases.STATUS_OVERVIEW}</Link></li>
						</ul>
					</li>
					<li>
						<Header>{phrases.GO_TO}</Header>
						<ul className='navigation__list'>
							<li><Link to="/" onClick={() => toggleNavigation()}>{phrases.SHIFTPLANNING}</Link></li>
						</ul>
					</li> */}
				</ul>
			</div>
		);
	}
}

Navigation.propTypes = {
	toggleNavigation: PropTypes.func,
};

export default Navigation;
