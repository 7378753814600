import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import LogRocket from 'logrocket';
import Fuse from 'fuse.js';

import {
	Header,
	List,
	ListItem,
	Button,
	Searchbar,
} from '../../../library/components';
import { StickyHeader, LetterScroll } from '@smart';

import './orderPage.css';
import { listTypes } from '../../../services/common';

class OrderPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showLetterScroll: false,
		};

		this._getCountClassName = this._getCountClassName.bind(this);
		this._updateOrderListItem = this._updateOrderListItem.bind(this);
		this._renderList = this._renderList.bind(this);
		this._renderListItem = this._renderListItem.bind(this);
		this._onScroll = this._onScroll.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onSearchClear = this.onSearchClear.bind(this);
	}

	componentDidMount() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

		pageWrapper &&
			pageWrapper.addEventListener('scroll', this._onScroll, false);
	}

	componentWillUnmount() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

		pageWrapper &&
			pageWrapper.removeEventListener('scroll', this._onScroll, false);
	}

	_onScroll() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];
		const notConfirmedList = document.getElementsByClassName(
			'notConfirmedList'
		)[0];

		if (
			!this.state.showLetterScroll &&
			notConfirmedList &&
			notConfirmedList.offsetTop <= pageWrapper.scrollTop + 90 &&
			notConfirmedList.clientHeight + notConfirmedList.offsetTop + 30 >=
				pageWrapper.scrollTop + window.innerHeight
		) {
			this.setState(() => ({ showLetterScroll: true }));
		} else if (
			this.state.showLetterScroll &&
			notConfirmedList.offsetTop > pageWrapper.scrollTop + 90
		) {
			this.setState(() => ({ showLetterScroll: false }));
		} else if (
			this.state.showLetterScroll &&
			notConfirmedList.clientHeight + notConfirmedList.offsetTop + 30 <=
				pageWrapper.scrollTop + window.innerHeight
		) {
			this.setState(() => ({ showLetterScroll: false }));
		}
	}

	showActionButtons(event, listItemStatus, index) {
		const {
			selectedListItem,
			selectListItem,
			selectedTaskDetails,
		} = this.props;
		event.stopPropagation();

		if (selectedTaskDetails.status === 'executed') return;

		const expandedListItem =
			selectedListItem !== `${listItemStatus}-${index}`
				? `${listItemStatus}-${index}`
				: '';

		selectListItem(expandedListItem);
	}

	_getUpdateTypeValue(type, item) {
		const { dataOrderList } = this.props;
		const isAdjusted = dataOrderList[item.id].count !== item.count;

		let change;

		switch (type) {
			case 'decrement':
				if (item.count === 0) break;
				change = { count: item.count - 1 };
				break;
			case 'increment':
				change = { count: item.count + 1 };
				break;
			default:
				change = { confirmed: true, adjusted: isAdjusted };
				break;
		}

		return change;
	}

	_updateOrderListItem(item, type, status, key) {
		const { selectedTaskDetails } = this.props;
		const updatedValue = this._getUpdateTypeValue(type, item);

		if (updatedValue) {
			this.props.updateOrderList(
				item.id,
				updatedValue,
				status,
				selectedTaskDetails,
				key
			);
			LogRocket.track(`Ordering ${item.count} ${item.name}`);
		}
	}

	_getCountClassName(product) {
		const { dataOrderList } = this.props;

		if (
			dataOrderList[product.id] &&
			dataOrderList[product.id].count > product.count
		)
			return 'order-page__count--lesser';
		if (
			dataOrderList[product.id] &&
			dataOrderList[product.id].count < product.count
		)
			return 'order-page__count--greater';

		return 'order-page__count';
	}

	_renderListItem(product, key, countClassName, listStatus, itemId) {
		const { selectedListItem } = this.props;

		const rightContent = (
			<span className={countClassName}>
				({product.count} {product.unit})
			</span>
		);
		const isToggled = `${listStatus}-${key}` === selectedListItem;
		// This should be handled by animation package
		const classnamesToggle = cx({
			[`is-open`]: isToggled,
		});

		return (
			<div className={classnamesToggle} id={itemId} key={key}>
				<ListItem
					onClick={(event) => this.showActionButtons(event, listStatus, key)}
					rightContent={rightContent}>
					<div className="order-page__list__name-content">
						<span>{product.name}</span>
						<span className="order-page__list__name-content__description">
							{product.description}
						</span>
					</div>
				</ListItem>

				{isToggled && !product.confirmed && (
					<div className="count-buttons">
						{!product.confirmed && (
							<Button
								className="count-buttons__button"
								onClick={() =>
									this._updateOrderListItem(product, 'confirm', listStatus, key)
								}
								type="alternative"
								group>
								✔
							</Button>
						)}
					</div>
				)}
			</div>
		);
	}

	onSearch(e) {
		const term = e.target.value;
		this.props.setSearch(term);
	}

	onSearchClear() {
		this.props.clearSearch();
	}

	_renderList(list, header, listStatus, listKeys, isCollapsed) {
		const { toggleSuggestionsList, search } = this.props;

		if (!listKeys) listKeys = Object.keys(list);
		if (!Object.keys(list).length) return;

		const lastLetter = '';
		let itemModifier = false;

		let itemList = listKeys
			.map((key) => {
				const product = list[key];

				if (!product) return null;

				product.itemIdName = '';
				product.lastLetter = lastLetter;
				product.itemModifier = itemModifier;
				product.countClassName = this._getCountClassName(product, key, list);

				if (
					listStatus === listTypes.NOT_CONFIRMED_LIST &&
					product.name.charAt(0) !== lastLetter
				) {
					product.lastLetter = product.name.charAt(0);
					product.itemIdName = `${product.name.charAt(0)}-item`;
					itemModifier = this.state.showLetterScroll;
				}

				return product;
			})
			.filter((i) => i);

		// Search
		if (search.length > 1) {
			var options = {
				keys: ['name'],
				threshold: 0.3,
			};
			const fuse = new Fuse(itemList, options);
			itemList = fuse.search(search);
		}

		return (
			<div className={listStatus} key={listStatus}>
				<CSSTransition
					key="navbar"
					in={this.state.showLetterScroll}
					classNames="letter-scroll-slide"
					timeout={{ enter: 250, exit: 250 }}
					unmountOnExit>
					<LetterScroll scrolledElementName="notConfirmedList" />
				</CSSTransition>
				<StickyHeader type="secondary">
					<Header sticky>{header}</Header>
				</StickyHeader>
				{isCollapsed ? (
					<div className="order-page__collapsable">
						<Button onClick={() => toggleSuggestionsList()} type="pink">
							Show Additional Items...
						</Button>
					</div>
				) : itemList.length ? (
					<List smallItem={itemModifier}>
						{itemList.length
							? itemList.map((item, index) =>
									this._renderListItem(
										item,
										index,
										item.countClassName,
										listStatus,
										item.itemIdName
									)
							  )
							: null}
					</List>
				) : null}
			</div>
		);
	}

	render() {
		const {
			confirmedList,
			adjustedList,
			notConfirmedList,
			notConfirmedListOrder,
			confirmedListOrder,
			adjustedListOrder,
			// isSuggestionsCollapsed,
			// suggestionsList,
			// suggestionsListOrder,
			search,
		} = this.props;

		return (
			<div className="order-page">
				<StickyHeader>
					<Searchbar
						value={search}
						onChange={this.onSearch}
						onClear={this.onSearchClear}
					/>
				</StickyHeader>
				{[
					this._renderList(
						confirmedList,
						'Confirmed',
						'confirmedList',
						confirmedListOrder
					),

					this._renderList(
						adjustedList,
						'Adjusted',
						'adjustedList',
						adjustedListOrder
					),

					this._renderList(
						notConfirmedList,
						'Not confirmed',
						'notConfirmedList',
						notConfirmedListOrder
					),

					// For now we disabled "additional list"
					// it does not serve any purpose
					// this._renderList(
					// 	suggestionsList,
					// 	'Additional Items',
					// 	'suggestionsList',
					// 	suggestionsListOrder,
					// 	isSuggestionsCollapsed
					// )
				]}
			</div>
		);
	}
}

OrderPage.propTypes = {
	dataOrderList: PropTypes.object,
	confirmedList: PropTypes.object,
	adjustedList: PropTypes.object,
	notConfirmedList: PropTypes.object,
	updateOrderList: PropTypes.func,
	selectedListItem: PropTypes.string,
	selectListItem: PropTypes.func,
	selectedTaskDetails: PropTypes.object,
	notConfirmedListOrder: PropTypes.array,
	suggestionsListOrder: PropTypes.array,
	confirmedListOrder: PropTypes.array,
	suggestionsList: PropTypes.object,
	adjustedListOrder: PropTypes.array,
	isSuggestionsCollapsed: PropTypes.bool,
	toggleSuggestionsList: PropTypes.func,
	setSearch: PropTypes.func,
	clearSearch: PropTypes.func,
	search: PropTypes.string,
};

export default OrderPage;
