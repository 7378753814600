import { getDay, formatDate, add } from '../../../services/dates';

import {
	STORE_TASKS_FETCH_SUCCESS,
	SET_SELECTED_TASK_DATA,
	FETCHING_STORE_TASKS,
	SET_SELECTED_DATES,
} from './storeTasks.actions.js';

const initState = {
	storeTasks: {},
	selectedTask: {},
	orderedStoreKeys: [],
	fetchedStoreTasks: [],
	fetchingStoreTasks: false,
	selectedDates: {
		from: getDay(),
		to: add(getDay(), 7, 'day'),
	},
};

export default (state = initState, action) => {
	const payload = action.payload;

	switch (action.type) {
		case STORE_TASKS_FETCH_SUCCESS: {
			const fetchedStoreTasks = payload;
			const formattedStoreTasks = {};

			if (!fetchedStoreTasks) {
				return Object.assign({}, state, {
					storeTasks: {},
					orderedStoreKeys: [],
				});
			}

			if (!fetchedStoreTasks) return;

			fetchedStoreTasks.forEach((storeTask, i) => {
				// If the task is hidden, well, we skip it :)
				if (!storeTask.show_in_app) return;

				const startDate = formatDate(storeTask.start, 'YYYY-MM-DD');

				const supplierName = storeTask.supplier
					? storeTask.supplier.name
					: null;

				if (!formattedStoreTasks[startDate]) {
					formattedStoreTasks[startDate] = { suppliers: {} };
				}
				if (!formattedStoreTasks[startDate].suppliers[supplierName]) {
					formattedStoreTasks[startDate].suppliers[supplierName] = {};
				}

				const executedEventId = storeTask.executed_event
					? storeTask.executed_event.data.id
					: storeTask.planned_event.data.id;

				formattedStoreTasks[startDate].suppliers[supplierName][storeTask.id] = {
					title: storeTask.task_type,
					status: storeTask.status,
					id: storeTask.id,
					executedEventId,
				};
			});

			// Order store tasks by date
			const orderedStoreKeys = Object.keys(formattedStoreTasks).sort(
				(date, date2) => {
					return new Date(date) - new Date(date2);
				}
			);

			return { ...state, storeTasks: formattedStoreTasks, orderedStoreKeys };
		}
		case SET_SELECTED_TASK_DATA: {
			return { ...state, selectedTask: payload };
		}
		case FETCHING_STORE_TASKS: {
			return { ...state, fetchingStoreTasks: payload };
		}
		case SET_SELECTED_DATES: {
			return { ...state, selectedDates: payload };
		}
		default: {
			return state;
		}
	}
};
