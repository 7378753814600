export default {
	ORDER_HEADER: 'Order & Count',
	CURRENT_TASKS: 'Current Tasks',
	HISTORY: 'History',
	CONFIGURATION: 'Configuration',
	MAINTENANCE: 'Maintenance',
	NEW_REQUEST: 'New Request',
	STATUS_OVERVIEW: 'Status Overview',
	GO_TO: 'Go To',
	SHIFTPLANNING: 'Shiftplanning',
	WORKPLACES: 'Your Workplaces',
	DELIVERY_REPORTING: 'Delivery Reporting',
	FEEDBACK: 'Feedback',
	LOG_OUT: 'Log out',
	APPLIENCE_REPORTING: 'DOC Reporting',
	STORE_TRANSFERS: 'Store Transfers',
	STORE_CHECK: 'Store check'
};
