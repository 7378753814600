// src/utils/AuthService.js
import ReactGA from 'react-ga';
import { isProduction } from '@tools/environment';

/**
 * @class AnalyticsService
 * @description Methods for adding analytics. Wrapper for https://github.com/react-ga/react-ga
 */

/**
 * @function initialize
 * @description Initialize analytics
 */
export function initialize() {
	ReactGA.initialize('UA-62941934-3');
}

/**
 * @function event
 * @description Track events
 */
export function event({ category = '', action = '', value = '', label = '' }) {
	isProduction() &&
		ReactGA.event({
			category,
			action,
			value,
			label
		});
}
/**
 * @function event
 * @description Track events
 */
export function timing({
	category = '',
	variable = '',
	value = '',
	label = ''
}) {
	isProduction() &&
		ReactGA.timing({
			category,
			variable,
			value,
			label
		});
}

/**
 * @function pageView
 * @description Wraps the page view method
 */
export function pageView(path) {
	isProduction() && ReactGA.pageview(path);
}

/**
 * @function event
 * @description Track events
 * @param {Object} data object
 * @example set({ userId: 123 });
 */

export function set(data) {
	isProduction() && ReactGA.set(data);
}
