
// ******************************
// GLOBAL APP STATE
// ******************************

import { TOGGLE_NAVIGATION, SET_NAVBAR, SET_NAVBAR_VISIBILITY, SET_NAVBAR_RELATIVE, SET_NAVBAR_MOVEMENT } from './navigation.actions.js';

const initState = {
	isNavigationToggled: false,
	isVisible: true,
	content: {
		leftContent: 'burger',
		title: 'Joe',
		rightContent: null
	},
	isNavigationRelative: true,
	isMovementDisabled: false
};

export default (state = initState, action) => {
	switch (action.type) {

	case TOGGLE_NAVIGATION:
		return Object.assign({}, state, { isNavigationToggled: action.payload });

	case SET_NAVBAR:
		return {...state, navbarContent: action.payload };

	case SET_NAVBAR_VISIBILITY:
		return {...state, isVisible: action.payload };

	case SET_NAVBAR_RELATIVE: 
		return {...state, isNavigationRelative: action.payload };

	case SET_NAVBAR_MOVEMENT:
		let isNavigationRelative = action.closeNavbar ? false : state.isNavigationRelative;
		return {...state, isMovementDisabled: action.payload, isVisible: action.closeNavbar, isNavigationRelative };

	default:
		return state;
	}
};