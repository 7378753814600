export default function isTouchDevice() {
	let device = null;

	if (navigator.userAgent.match(/iPad/i)) device = 'iPad';

	if (navigator.userAgent.match(/iPhone/i)) device = 'iPhone';

	if (navigator.userAgent.match(/Android/i)) {
		device = navigator.userAgent.match(/Mobile/i)
			? 'android mobile'
			: 'android tablet';
	}

	return device;
}
