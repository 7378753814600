import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import cx from 'classnames';

import { Header, List, ListItem, Searchbar } from '@dumb';
import CountUnitSection from './components/countUnitSection.jsx';
import { StickyHeader } from '@smart';
import listService from 'services/listService';

import { listTypes } from '../../../services/common';
import './countPage.css';

class CountPage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showLetterScroll: false,
		};

		this.refDict = {};
		this._renderList = this._renderList.bind(this);
		this._onListItemSelected = this._onListItemSelected.bind(this);
		this.onItemCountUpdate = this.onItemCountUpdate.bind(this);
		this._handleEnterPress = this._handleEnterPress.bind(this);
		this.assignEnterPressToInput = this.assignEnterPressToInput.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onSearchClear = this.onSearchClear.bind(this);
		this.focusOnSearchInput = this.focusOnSearchInput.bind(this);
	}

	// Loop through the ref's object, and bind each of them to onkeypress
	assignEnterPressToInput() {
		for (const x in this.refDict) {
			// If there is a ref then attach a onKeyPress that listens to enter press
			// Enter press will move to focusing on the next input
			if (this.refDict[x]) {
				this.refDict[x].refDict.input.onkeypress = (e) =>
					this._handleEnterPress(e, this.refDict[x]);
			}
		}
	}

	onSearch(e) {
		const term = e.target.value;
		this.props.setSearch(term);
	}

	onSearchClear() {
		this.props.clearSearch();
	}

	_handleEnterPress(e, field) {
		const { countedList, suggestionsList, restItems } = this.props;

		if (e.keyCode === 13) {
			e.preventDefault(); // Prevent form submission if button present
			const next = this.refDict[field.props.index + 1];

			// If there is a next count type
			if (next) {
				next.refDict.input.select();
				next.refDict.input.focus();
			} else {
				// If there is no next reference to input select the next item in the list
				const listStatus = this.refDict[field.props.index].props.listStatus;

				// Get the next item in the list
				let nextItemId;
				let focusedList = restItems; // default

				switch (listStatus) {
					case listTypes.COUNTED_LIST:
						focusedList = countedList;
						break;
					case listTypes.SUGGESTIONS_LIST:
						focusedList = suggestionsList;
						break;
					default:
						focusedList = restItems;
				}

				focusedList = listService.sortObjectByAlphabet(focusedList);

				focusedList.forEach((item, index) => {
					if (Number(item) === field.props.product.id) {
						//nextItemKey = index + 1;
						nextItemId = focusedList[index + 1];
					}
				});

				this._onListItemSelected(e, listStatus, nextItemId, true);
			}
		}
	}

	_onListItemSelected(
		event,
		listStatus,
		id,
		autoPicked = false,
		clickedOn = false
	) {
		const { selectListItem, selectedTaskDetails } = this.props;

		const expandedListItem = `${listStatus}-${id}`;
		event.stopPropagation();

		selectListItem(
			expandedListItem,
			selectedTaskDetails,
			autoPicked,
			clickedOn
		);
	}

	onItemCountUpdate(event, listStatus, productId, unit) {
		const { updateItemCount } = this.props;

		const payload = { unit: unit, count: event.target.value };
		updateItemCount(productId, listStatus, payload);
	}

	getRightContent(product) {
		let isContent = false;
		const products = [];

		product.units.forEach((unit, index) => {
			if (!unit.count) return null;

			products.push(
				<span key={unit.unit + index}>
					{index !== 0 && isContent ? ', ' : ''}
					{unit.count} {unit.description}
				</span>
			);
			isContent = true;
		});

		if (isContent) return <div>({products})</div>;
		return null;
	}

	focusOnSearchInput() {
		this.props.moveEditedToCounted();
		this.props.clearSelected();
	}

	_renderListItem(key, product, isToggled, listStatus, index) {
		const regex = RegExp(/^[0-9]+([\,\.][0-9]{1,2})?$/);

		let isValid = true;

		// if (listStatus === 'counted') {
			for (const unit of product.units) {
				if (!regex.test(unit.count) && unit.count !== '') {
					isValid = false;
					break;
				}
			}
		// }

		const classnames = cx('count-page__section', {
			[`count-page__section__invalid`]: !isValid,
		});

		return (
			<div className={classnames} key={key}>
				<ListItem
					onClick={(event) =>
						this._onListItemSelected(event, listStatus, product.id, false, true)
					}
					rightContent={this.getRightContent(product)}
					selected={isToggled}>
					<div className="count-page__section__name-content">
						<span>{product.name}</span>
						{/* <span className="count-page__section__name-content__description">{product.description}</span> */}
					</div>
				</ListItem>

				{isToggled &&
					product.units.map((unit, i) => {
						return (
							<CountUnitSection
								ref={(section) => (this.refDict[i] = section)}
								key={unit.id}
								index={i}
								unit={unit}
								listStatus={listStatus}
								product={product}
								onItemCountUpdate={this.onItemCountUpdate}
							/>
						);
					})}
			</div>
		);
	}

	_renderList(list = {}, listStatus, header, itemListOrder) {
		const { selectedListItem, search } = this.props;

		if (!Object.keys(list).length) return;

		this.assignEnterPressToInput();

		let itemList = itemListOrder
			.map((key, index) => {
				const product = list[key];

				if (!product) return null;

				product.isToggled = `${listStatus}-${product.id}` === selectedListItem;
				product.itemIdName = '';
				product.listStatus = listStatus;
				product.index = index;

				return product;
			})
			.filter((i) => i);

		// Search
		if (search.length > 1) {
			var options = {
				keys: ['name'],
				threshold: 0.3,
			};
			const fuse = new Fuse(itemList, options);
			itemList = fuse.search(search);
		}

		return (
			<div className={listStatus} key={listStatus}>
				<StickyHeader type="secondary">
					<Header>{header}</Header>
				</StickyHeader>
				{itemList.length ? (
					<List>
						{(itemList || []).map((item) =>
							this._renderListItem(
								item.id,
								item,
								item.isToggled,
								listStatus,
								item.index
							)
						)}
					</List>
				) : null}
			</div>
		);
	}

	render() {
		const {
			suggestionsList,
			countedList,
			selectedTaskDetails,
			search,
			restItems,
			listOrder,
		} = this.props;

		const isExecuted =
			selectedTaskDetails && selectedTaskDetails.status === 'executed';

		return (
			<div className="count-page">
				<StickyHeader>
					<Searchbar
						onClick={this.focusOnSearchInput}
						value={search}
						onChange={this.onSearch}
						onClear={this.onSearchClear}
					/>
				</StickyHeader>

				{this._renderList(
					countedList,
					'counted',
					isExecuted ? 'Re-counted' : 'Counted',
					listOrder
				)}

				{this._renderList(
					suggestionsList,
					'suggestions',
					isExecuted ? 'Previous count (suggestions)' : 'Suggestions',
					listOrder
				)}

				{this._renderList(
					restItems,
					'restItems',
					isExecuted ? 'Products' : 'All products',
					listOrder
				)}
			</div>
		);
	}
}

CountPage.propTypes = {
	updateItemCount: PropTypes.func,
	selectListItem: PropTypes.func,
	suggestionsList: PropTypes.object,
	countedList: PropTypes.object,
	selectedListItem: PropTypes.string,
	selectedTaskDetails: PropTypes.object,
	suggestionsListOrder: PropTypes.array,
	countedItemListOrder: PropTypes.array,
	search: PropTypes.string,
	setSearch: PropTypes.func,
	clearSearch: PropTypes.func,
	moveEditedToCounted: PropTypes.func,
	restItems: PropTypes.object,
	restListOrder: PropTypes.array,
	clearSelected: PropTypes.func,
};

export default CountPage;
