import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import LogRocket from 'logrocket';

import storeTasks from './pages/storeTasks/store/storeTasks.reducer';
import navigation from './library/features/navigation/store/navigation.reducer';

// Orders
import order from './pages/orders/orderPage/store/orderPage.reducer';
import orderJustification from './pages/orders/orderJustification/store/orderJustification.reducer';

// Count
import areaConfig from './pages/count/areasList/components/areaConfiguration/store/areaConfiguration.reducer';
import count from './pages/count/countPage/store/countPage.reducer';
import countAreas from './pages/count/areasList/store/areasList.reducer';
import countReview from './pages/count/countReview/store/countReview.reducer';
import auth from 'reducers/auth.reducer';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

// Received
import received from './pages/received/store/receivedCount.reducer';

// Workplaces
import workplaces from './pages/workplaceSelection/store/workplaceSelection.reducer';

// Global app settings - state
const SET_BROWSER_VALIDITY = 'GLOBAL/SET_BROWSER_VALIDITY';
export const setBrowserValidity = (payload) => {
	return { type: SET_BROWSER_VALIDITY, payload };
};

const global = (
	state = {
		isValidBrowser: true,
		isCancelled: false,
	},
	action
) => {
	switch (action.type) {
		case SET_BROWSER_VALIDITY:
			return {
				...state,
				isValidBrowser: action.payload.validity,
				isCancelled: action.payload.cancelled || state.isCancelled,
			};

		default:
			return state;
	}
};

const persistConfig = {
	key: 'storetask',
	storage: storage,
	stateReconciler: hardSet,
};

const reducer = combineReducers({
	order,
	orderJustification,
	navigation,
	storeTasks,
	count,
	auth,
	countAreas,
	areaConfig,
	countReview,
	received,
	workplaces,
	global,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware =
	process.env.NODE_ENV === 'production'
		? [thunk, LogRocket.reduxMiddleware()]
		: [thunk];

const store = createStore(
	persistedReducer,
	{},
	composeWithDevTools(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

export { persistor, store };
