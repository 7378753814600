import React, { Component } from 'react';
import PropTypes from 'prop-types';

// classnames
import cx from 'classnames';

// components
import { StickyHeader } from '@smart';
import { Button, Loader } from './../../library/components';
import { EmptyListView, Pullable } from '../../library/components';
import DayTasks from './components/dayTasks/dayTasks.jsx';

// utils
import isTouchDevice from './utils';
import { formatDate, dateIsSame } from '../../services/dates';

// phrases
import phrases from './storeTasks.phrases';

class StoreTasks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};

		this.touchDevice = !!isTouchDevice();

		this.fetchPreviousTasksWrapper = this.fetchPreviousTasksWrapper.bind(this);
	}

	fetchPreviousTasksWrapper() {
		const { fetchPreviousTasks } = this.props;

		this.setState(() => ({ loading: true }));

		fetchPreviousTasks().then(() => this.setState(() => ({ loading: false })));
	}

	render() {
		const {
			storeTasks,
			orderedStoreKeys,
			onTaskSelected,
			fetchPreviousTasks,
			currentDay,
		} = this.props;

		return Object.keys(storeTasks).length < 1 ? (
			<EmptyListView text={phrases.NO_TASKS} />
		) : (
			<div className="store-tasks">
				{!this.touchDevice && (
					<div className="store-tasks__button-wrapper">
						{this.state.loading ? (
							<Loader />
						) : (
							<Button onClick={this.fetchPreviousTasksWrapper} type="alternative">
								{phrases.LOAD_PREVIOUS_TASKS}
							</Button>
						)}
					</div>
				)}

				<Pullable onRefresh={fetchPreviousTasks} centerSpinner>
					<div onScroll={this.onScroll}>
						{orderedStoreKeys.map((date) => {
							const isToday = dateIsSame(date, currentDay, 'day');
							const headerClass = cx('store-tasks__day-header', {
								[`store-tasks__day-header--highlight`]: isToday,
							});

							return (
								<div key={date} className="store-tasks__day-section">
									<StickyHeader>
										<div className={headerClass} id={isToday ? 'today' : null}>
											{formatDate(date, 'dddd (DD-MM)')} {isToday && 'Today'}
										</div>
									</StickyHeader>
									{Object.keys(storeTasks[date].suppliers).map((supplier) => (
										<DayTasks
											key={date + supplier}
											onTaskSelected={onTaskSelected}
											suppliers={storeTasks[date].suppliers}
											supplier={supplier}
											date={date}
										/>
									))}
								</div>
							);
						})}
					</div>
				</Pullable>
			</div>
		);
	}
}

StoreTasks.propTypes = {
	storeTasks: PropTypes.object,
	currentDay: PropTypes.object,
	orderedStoreKeys: PropTypes.array,
	onTaskSelected: PropTypes.func,
	fetchPreviousTasks: PropTypes.func,
};

export default StoreTasks;
