import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './letterSideScroll.css';

export default class LetterSideScroll extends PureComponent {
	constructor(props) {
		super(props);

		this._scrollTo = this._scrollTo.bind(this);
		this._onTouchStart = this._onTouchStart.bind(this);
		this._onTouchMove = this._onTouchMove.bind(this);
		this._onTouchEnd = this._onTouchEnd.bind(this);
	}

	componentDidMount() {
		const letters = document.getElementsByClassName(
			'letter-side-scroll__letter'
		);

		if (letters.length) {
			for (const letter of Array.from(letters)) {
				if (!(letter instanceof Element) && !(letter instanceof HTMLDocument))
					return;
				letter.addEventListener('touchmove', this._onTouchMove);

				letter.addEventListener('touchstart', this._onTouchStart);

				letter.addEventListener('touchend', this._onTouchEnd);
			}
		}
	}

	componentWillUnmount() {
		const letters = document.getElementsByClassName(
			'letter-side-scroll__letter'
		);

		for (const letter of Array.from(letters)) {
			if (!(letter instanceof Element) && !(letter instanceof HTMLDocument))
				return;
			letter.removeEventListener('touchmove', this._onTouchMove);

			letter.removeEventListener('touchstart', this._onTouchStart);

			letter.removeEventListener('touchend', this._onTouchEnd);
		}
	}

	_onTouchMove(e) {
		e.preventDefault();
		e.stopPropagation();
		const myLocation = e.changedTouches[0];
		const currentTouchedLetter = document.elementFromPoint(
			myLocation.clientX,
			myLocation.clientY
		);
		if (currentTouchedLetter) this._scrollTo(currentTouchedLetter.textContent);
	}

	_onTouchStart(e) {
		const { setNavBarMovement } = this.props;

		e.preventDefault();
		e.stopPropagation();
		setNavBarMovement(true);
		this._scrollTo(e.target.textContent);
	}

	_onTouchEnd(e) {
		const { setNavBarMovement } = this.props;

		e.preventDefault();
		e.stopPropagation();
		setNavBarMovement(false);
	}

	_scrollTo(letter) {
		const { scrolledElementName } = this.props;
		const element = `${letter}-item`;
		const item = document.getElementById(element);
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];
		const scrollableElement = document.getElementsByClassName(
			scrolledElementName
		)[0];

		if (item) {
			// If the item is on the bottom of the list scroll to the bottom of the list so that the focus is not on the next list.
			if (
				scrollableElement &&
				scrollableElement.offsetTop +
					scrollableElement.clientHeight -
					item.offsetTop <
					window.innerHeight
			) {
				return pageWrapper.scrollTo(
					0,
					scrollableElement.clientHeight +
						scrollableElement.offsetTop -
						window.innerHeight
				);
			}
			item.scrollIntoView(true);
			pageWrapper.scrollTop -= 48;
		}
	}

	render() {
		return (
			<div className="letter-side-scroll">
				<div className="letter-side-scroll__letter">A</div>
				<div className="letter-side-scroll__letter">B</div>
				<div className="letter-side-scroll__letter">C</div>
				<div className="letter-side-scroll__letter">D</div>
				<div className="letter-side-scroll__letter">E</div>
				<div className="letter-side-scroll__letter">F</div>
				<div className="letter-side-scroll__letter">G</div>
				<div className="letter-side-scroll__letter">H</div>
				<div className="letter-side-scroll__letter">I</div>
				<div className="letter-side-scroll__letter">J</div>
				<div className="letter-side-scroll__letter">K</div>
				<div className="letter-side-scroll__letter">L</div>
				<div className="letter-side-scroll__letter">M</div>
				<div className="letter-side-scroll__letter">N</div>
				<div className="letter-side-scroll__letter">O</div>
				<div className="letter-side-scroll__letter">P</div>
				<div className="letter-side-scroll__letter">Q</div>
				<div className="letter-side-scroll__letter">R</div>
				<div className="letter-side-scroll__letter">S</div>
				<div className="letter-side-scroll__letter">T</div>
				<div className="letter-side-scroll__letter">U</div>
				<div className="letter-side-scroll__letter">V</div>
				<div className="letter-side-scroll__letter">W</div>
				<div className="letter-side-scroll__letter">X</div>
				<div className="letter-side-scroll__letter">Y</div>
				<div className="letter-side-scroll__letter">Z</div>
			</div>
		);
	}
}

LetterSideScroll.propTypes = {
	setNavBarMovement: PropTypes.func,
	scrolledElementName: PropTypes.string,
};
