import {
	SET_COUNT_AREAS,
	SET_LOADER,
	ADD_COUNT_AREA,
	DELETE_COUNT_AREA,
	TOGGLE_AREA_LOADER,
	SIGN_COUNT_AREA,
	EDIT_COUNT_AREA,
} from './areasList.actions.js';

const initState = {
	data: {
		countAreas: [],
		signed: {},
	},
	ui: {
		loading: false,
		loadingArea: [],
	},
};

export default (state = initState, action) => {
	const payload = action.payload;

	switch (action.type) {
		case SET_COUNT_AREAS: {
			return {
				...state,
				data: {
					...state.data,
					countAreas: payload,
				},
				ui: {
					...state.ui,
					loading: false,
				},
			};
		}
		case SET_LOADER: {
			return {
				...state,
				ui: {
					...state.ui,
					loading: payload,
				},
			};
		}
		case TOGGLE_AREA_LOADER: {
			let loadingArea = [];
			if (state.ui.loadingArea.some((x) => x === payload)) {
				loadingArea = state.ui.loadingArea.filter((x) => x !== payload);
			} else {
				loadingArea = [...state.ui.loadingArea, ...[payload]];
			}

			return {
				...state,
				ui: {
					...state.ui,
					loadingArea,
				},
			};
		}
		case ADD_COUNT_AREA: {
			return {
				...state,
				data: {
					...state.data,
					countAreas: [...state.data.countAreas, ...[payload]],
				},
			};
		}

		case EDIT_COUNT_AREA: {
			return {
				...state,
				data: {
					...state.data,
					countAreas: state.data.countAreas.map((entry) => {
						if (entry.id === payload.id) return payload;

						return entry;
					}),
				},
			};
		}

		case SIGN_COUNT_AREA: {
			return {
				...state,
				data: {
					...state.data,
					signed: state.data.signed[payload.taskId]
						? {
								...state.data.signed,
								[payload.taskId]: {
									...state.data.signed[payload.taskId],
									[payload.areaId]: true,
								},
						  }
						: {
								...state.data.signed,
								[payload.taskId]: { [payload.areaId]: true },
						  },
				},
			};
		}

		case DELETE_COUNT_AREA: {
			return {
				...state,
				data: {
					...state.data,
					countAreas: state.data.countAreas.filter((x) => x.id !== payload),
				},
			};
		}
		default:
			return state;
	}
};
