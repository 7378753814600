import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './navbar.css';

class Navbar extends Component {
	scrollToTop() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

		pageWrapper.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	render() {
		const { leftContent, header, rightContent, relative } = this.props;
		const classnames = cx({
			'navbar': !relative,
			'navbar--relative': relative
		});

		return (
			<header className={classnames}>
				<div className="navbar__content">
					<div className="navbar__content__left">
						{leftContent}
					</div>

					<div onClick={this.scrollToTop} className='navbar__content__body'>
						<span>
							{header}
						</span>
					</div>

					<div className='navbar__content__right'>
						{rightContent}
					</div>
				</div>
			</header>
		);
	}
}

Navbar.defaultProps = {
	leftContent: 'left',
	header: 'Title',
	rightContent: null,
	relative: false
};

Navbar.propTypes = {
	leftContent: PropTypes.node,
	header: PropTypes.node,
	rightContent: PropTypes.node,
	relative: PropTypes.bool
};

export default Navbar;