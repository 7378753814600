
export const UPDATE_ITEM_COUNT = 'COUNT/UPDATE_ITEM_COUNT';
export const LIST_ITEM_SELECTED = 'COUNT/LIST_ITEM_SELECTED';
export const SET_COUNT_AREA = 'COUNT/SET_COUNT_AREA';
export const SET_AREA_COUNT = 'COUNT/SET_AREA_COUNT';
export const SET_SUGGESTED_ITEMS = 'COUNT/SET_SUGGESTED_ITEMS';
export const SET_FETCHING_DATA = 'COUNT/SET_FETCHING_DATA';
export const RESET_STATE = 'COUNT/RESET_STATE';
export const RESET_COUNTS = 'COUNT/RESET_COUNTS';
export const SET_SEARCH = 'COUNT/SET_SEARCH';
export const CLEAR_SEARCH = 'COUNT/CLEAR_SEARCH';
export const REMOVE_COUNT_EVENT = 'COUNT/CLEAR_SEARCH';
export const CLEAR_SELECTED = 'COUNT/CLEAR_SELECTED';
export const UPDATE_EDITED_TO_COUNTED = 'COUNT/UPDATE_EDITED_TO_COUNTED';
export const SET_ACTIVE_COUNT_AREA = 'COUNT/SET_ACTIVE_COUNT_AREA';
export const SET_DATE = 'COUNT/SET_DATE';

/**
 * @function setSearch
 * @description Set search input
 * @param {string} payload search term, should be throttled
 */
export const setSearch = (payload) => {
	return { type: SET_SEARCH, payload };
};

/**
 * @function clearSearch
 * @description Clear search input
 */
export const clearSearch = () => {
	return { type: CLEAR_SEARCH };
};

/**
 * @function clearSearch
 * @description Clear search input
 */
export const clearSelected = () => {
	return { type: CLEAR_SELECTED };
};

/**
 * @function setFetchingData
 * @description Sets the loader status
 * @param {string} payload is loading
 */
export const setFetchingData = (payload) => {
	return { type: SET_FETCHING_DATA, payload };
};

/**
 * @function setActiveCountArea
 * @description Update order list
 * Is only being called when editable components are mounted
 * @param {number} id Order Item id
 */
export const setActiveCountArea = (taskId, areaId) => {
	return { type: SET_ACTIVE_COUNT_AREA, taskId, areaId };
};

/**
 * @function setSuggestedItems
 * @description Update item list and suggested list
 * Is only being called when editable components are mounted
 * @param {object} payload List of suggested items
 */
export const setSuggestedItems = (payload) => {
	return { type: SET_SUGGESTED_ITEMS, payload };
};

/**
 * @function updateItemCount
 * @description Update order list
 * Is only being called when editable components are mounted
 * @param {number} id Order Item id
 * @param {object} payload Object with modified params
 * @param {number} listStatus Order Item id
 */
export const updateItemCount = (id, listStatus, payload) => {

	return { type: UPDATE_ITEM_COUNT, payload, id, listStatus };
};

/**
 * @function selectListItem
 * @description When an item is selected in the order list
 * @param {string} payload takes a string of list name and item key combined
 */
export const selectListItem = (
	payload,
	selectedTaskDetails,
	autoPick,
	clickedOn
) => {
	// Always keep the input visible
	const countSection = document.getElementsByClassName('count-unit-section')[0];

	if (countSection) {
		// Keep count button in same spot when it approaches the bottom of the page
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

		if (autoPick) {
			pageWrapper.scrollTo(0, countSection.offsetTop - window.innerHeight / 4);
		}
	}

	return {
		type: LIST_ITEM_SELECTED,
		payload,
		selectedTaskDetails,
		autoPick,
		clickedOn,
	};
};

/**
 * @function setCountArea
 * @description Sets the name of the area that is being counted. This is stored in redux as it
 * is later used for knowing which search query needs to be applied for back press.
 * @param {string} payload takes a string of list name and item key combined
 */
export const setCountArea = (payload) => {

	return { type: SET_COUNT_AREA, payload };
};

export const setDate = (payload) => {
	return { type: SET_DATE, payload };
};

/**
 * @function resetCounts
 * @deprecated
 */
export const resetCounts = () => {
	return { type: RESET_COUNTS };
};

export const resetState = () => {
	return { type: RESET_STATE };
};

/**
 * @function moveEditedToCounted
 * @description This is last resort to move 'last' edited count item. This is necessary for last use case before navigating or moving away from list.
 */
export const moveEditedToCounted = () => {
	return { type: UPDATE_EDITED_TO_COUNTED };
};
