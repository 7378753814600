import listService from '../../../../services/listService';

import {
	SET_ORDER_DATA,
	UPDATE_ORDER_ITEM,
	LIST_ITEM_SELECTED,
	ORDERS_FETCH_SUCCESS,
	EXECUTING_TASK,
	SET_FETCHING_DATA,
	TOGGLE_COLLAPSE,
	SET_SEARCH,
	SET_ACTIVE_ORDER,
	CLEAR_SEARCH,
} from './orderPage.actions.js';

const initialState = {
	data: {
		orderList: {},
		notConfirmedList: {},
		suggestionsList: {},
		adjustedList: {},
		confirmedList: {},
		taskId: null,
	},
	ui: {
		selectedListItem: '',
		executingTask: false,
		confirmedListOrder: [],
		notConfirmedListOrder: [],
		suggestionsListOrder: [],
		adjustedListOrder: [],
		fetchingData: false,
		isSuggestionsCollapsed: true,
		search: '',
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_ORDER_ITEM: {
			const { id, payload, status } = action;

			const taskId = state.data.taskId;
			const orderTask = state.data?.[taskId];

			const confirmedList = orderTask.confirmedList;
			let confirmedListOrder = state.ui.confirmedListOrder;
			const adjustedList = orderTask.adjustedList;
			let adjustedListOrder = state.ui.adjustedListOrder;
			const updatedList = orderTask[status];
			let suggestionsListOrder = state.ui.suggestionsListOrder;
			let selectedListItem = state.ui.selectedListItem;
			let notConfirmedListOrder = state.ui.notConfirmedListOrder;

			// If an item is confirmed
			if (action.payload.confirmed) {
				// remove from list add to new list make order

				// If the count is different to the initially fetched count
				if (updatedList[id].count !== orderTask.orderList[id].count) {
					adjustedList[id] = { ...updatedList[id], ...payload };
					adjustedListOrder = listService.sortObjectByAlphabet(
						orderTask.adjustedList
					);
				} else {
					// If the count is the same to the initially fetched count
					confirmedList[id] = { ...updatedList[id], ...payload };
					confirmedListOrder = listService.sortObjectByAlphabet(
						orderTask.confirmedList
					);
				}
				delete updatedList[id];

				// Keep count buttons in same spot when they approach the bottom of the page
				listService.fixItemCounterPosition();

				// reorder the approriately changed list
				if (status === 'suggestionsList') {
					suggestionsListOrder = listService.sortObjectByAlphabet(updatedList);
				} else {
					notConfirmedListOrder = listService.sortObjectByAlphabet(updatedList);
				}
				// If the confirmed list is modified
			} else if (status === 'confirmedList') {
				// If the count is not the same as the initially feched count move the item to the confirmed list
				if (orderTask.orderList[id].count !== payload.count) {
					adjustedList[id] = { ...confirmedList[id], ...payload };
					delete updatedList[id];
					// reorder the modified lists
					adjustedListOrder = listService.sortObjectByAlphabet(
						orderTask.adjustedList
					);
					confirmedListOrder = listService.sortObjectByAlphabet(
						orderTask.confirmedList
					);
					// select the item that was moved to the different list
					const index = adjustedListOrder.findIndex(
						(index) => Number(index) === id
					);
					selectedListItem = `adjustedList-${index}`;
					// Scroll to the item that was moved
					setTimeout(() => {
						listService.scrollToCountButtons();
					}, 100);
				}
				// If the adjusted list is modified
			} else if (status === 'adjustedList') {
				// If the count is the same as the initially feched count move the item to the confirmed list
				if (orderTask.orderList[id].count === payload.count) {
					confirmedList[id] = { ...adjustedList[id], ...payload };
					delete updatedList[id];
					// reorder the modified lists
					adjustedListOrder = listService.sortObjectByAlphabet(
						orderTask.adjustedList
					);
					confirmedListOrder = listService.sortObjectByAlphabet(
						orderTask.confirmedList
					);
					// select the item that was moved to the different list
					const itemIndex = confirmedListOrder.findIndex(
						(index) => Number(index) === id
					);
					selectedListItem = `confirmedList-${itemIndex}`;
					// Scroll to the item that was moved
					setTimeout(() => {
						listService.scrollToCountButtons();
					}, 100);
				} else {
					updatedList[id] = { ...updatedList[id], ...payload };
				}
			} else {
				updatedList[id] = { ...updatedList[id], ...payload };
			}

			return {
				...state,
				data: {
					...state.data,
					[taskId]: {
						...state.data[taskId],
						adjustedList,
						confirmedList,
						[status]: { ...updatedList },
					},
				},
				ui: {
					...state.ui,
					selectedListItem,
					suggestionsListOrder,
					notConfirmedListOrder,
					confirmedListOrder,
					adjustedListOrder,
				},
			};
		}

		case LIST_ITEM_SELECTED: {
			return {
				...state,
				ui: { ...state.ui, selectedListItem: action.payload },
			};
		}
		case SET_ORDER_DATA: {
			const confirmedList = action.payload.confirmedList;
			const adjustedList = action.payload.adjustedList;

			const taskId = state.data.taskId;
			//		const orderTask = state.data?.[taskId];

			const confirmedOrder = listService.sortObjectByAlphabet(confirmedList);
			const adjustedOrder = listService.sortObjectByAlphabet(adjustedList);

			return {
				...state,
				ui: {
					...state.ui,
					confirmedListOrder: confirmedOrder,
					adjustedListOrder: adjustedOrder,
				},
				data: {
					...state.data,
					[taskId]: {
						...state.data[taskId],
						adjustedList: listService.sortObjectByAlphabet(confirmedList),
						confirmedList: listService.sortObjectByAlphabet(adjustedList),
					},
				},
			};
		}

		case ORDERS_FETCH_SUCCESS: {
			const notConfirmedList = {};
			const orderList = {};
			const suggestionsList = {};

			const taskId = state.data.taskId;
			const orderTask = state.data?.[taskId];

			action.payload.forEach((item) => {
				const itemId = item.supplier_raw_product.data.id;

				const formattedItem = {
					id: itemId,
					name: item.supplier_raw_product.data.name,
					description: item.supplier_raw_product.data.description,
					count: item.quantity,
					unit: item.supplier_raw_product.data.description
						.split(/\s+/)
						.slice(1, 2)[0],
				};

				orderList[itemId] = formattedItem;

				if (orderTask.confirmedList[itemId] || orderTask.adjustedList[itemId])
					return;

				if (formattedItem.count > 0)
					return (notConfirmedList[itemId] = formattedItem);

				suggestionsList[itemId] = formattedItem;
			});

			// Order notConfirmedList Items by count
			const notConfirmedListOrder = listService.sortObjectByAlphabet(
				notConfirmedList
			);

			// Order notConfirmedList Items by Alphabet
			const suggestionsListOrder = listService.sortObjectByAlphabet(
				suggestionsList
			);

			return {
				...state,
				data: {
					...state.data,
					[taskId]: {
						...state.data[taskId],
						orderList,
						notConfirmedList,
						suggestionsList,
					},
				},
				ui: { ...state.ui, notConfirmedListOrder, suggestionsListOrder },
			};
		}

		case SET_ACTIVE_ORDER: {
			return {
				...state,
				data: {
					...state.data,
					taskId: action.payload,
					[action.payload]: state.data[action.payload]
						? {
								...state.data[action.payload],
						  }
						: {
								orderList: {},
								notConfirmedList: {},
								suggestionsList: {},
								adjustedList: {},
								confirmedList: {},
						  },
				},
			};
		}
		case EXECUTING_TASK: {
			return { ...state, ui: { ...state.ui, executingTask: action.payload } };
		}

		case SET_FETCHING_DATA:
			return { ...state, ui: { ...state.ui, fetchingData: action.payload } };

		case TOGGLE_COLLAPSE: {
			return {
				...state,
				ui: { ...state.ui, isSuggestionsCollapsed: action.payload },
			};
		}
		case SET_SEARCH: {
			return { ...state, ui: { ...state.ui, search: action.payload } };
		}
		case CLEAR_SEARCH: {
			return { ...state, ui: { ...state.ui, search: '' } };
		}
		default:
			return state;
	}
};
