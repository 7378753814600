import constants from '../../../contants';
import fetchWrapper from './../../../services/fetchWrapper';
import { store } from 'store';

export const SET_WORKPLACES = 'WORKPLACES/SET_WORKPLACES';
export const WORKPLACE_SELECTED = 'WORKPLACES/WORKPLACE_SELECTED';
export const TOGGLE_LOADER = 'WORKPLACES/TOGGLE_LOADER';

/**
 * @function fetchWorkplaces
 * @description fetch workplaces from API
 */
export const fetchWorkplaces = () => {
	const state = store.getState();
	const personId = state?.auth?.user.personId;
	return fetchWrapper({
		endpoint: constants.API_ENDPOINT + `/users/${personId}/workplaces`,
		method: 'GET',
	});
};

/**
 * @function setWorkplaces
 * @description set workplaces in state
 * @param {object} payload Object of workplaces
 */
export const setWorkplaces = (payload) => {
	return { type: SET_WORKPLACES, payload };
};

/**
 * @function selectWorkplace
 * @description set workplaces in state
 * @param {object} payload Object of workplaces
 */
export const selectWorkplace = (payload) => {
	return { type: WORKPLACE_SELECTED, payload };
};

/**
 * @function toggleLoader
 */
export const toggleLoader = (payload) => {
	return { type: TOGGLE_LOADER, payload };
};
