import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDate = (date, format) => {
	return dayjs.utc(date).format(format);
};

export const getDay = () => {
	return dayjs();
};

export const dateObject = (string, format) => {
	return dayjs(string, format);
};

export const startOf = (date, unit) => {
	return date.startOf(unit).add(1, 'day');
};

export const endOf = (date, unit) => {
	return date.endOf(unit).add(1, 'day');
};

export const add = (date, number, type) => {
	return date.add(number, type);
};

export const subtract = (date, number, type) => {
	return date.subtract(number, type);
};

export const dateIsSame = (date1, date2, unit) => {
	return dayjs(date1).isSame(date2, unit);
};
