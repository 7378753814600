/**
 * @function formatErrorMessage
 * @description Takes an error object thrown from the api and formats all messages in the error object so its readable
 * @param {Object} response
 * @return {String} errorMessage
 *
 *
 * @example
 * And error object
 *  const errorResponse = {
 * 		"errors":[errorName: [0: "The format is wrong"], errorName: [0: "The format is wrong"]],
 * 		"message":"Domain object was not valid"
 * 	}
 *
 * const formattedError = formatErrorMessage(errorResponse);
 *
 * // Output formatted error
 * console.log(formattedError)	 //Element with index '0' failed to be created with the following message: 'Employment has already a shift for the given period' (shift_employees)
 */

export default function formatErrorMessage(response) {
	// Error msg
	let errorMessage;
	const errorsObj = response.errors ? response.errors : [];

	// Collect errors
	if (errorsObj && Object.keys(errorsObj).length > 0) {
		errorMessage = `Error: ${
			Object.keys(errorsObj).map(key =>
				errorsObj[key].map(errorLine => 
					`${errorLine}`)
			).join(',')}`;
	}
	else errorMessage = `Error: ${response.message}`;
	return errorMessage;
}