import _has from 'lodash/has';

export const initializeCountingAreaDataStructure = ({
	data,
	taskId,
	areaId,
}) => {
	if (_has(data, `['${taskId}']['${areaId}']`))
		return { ...data[taskId][areaId] };

	return {
		[taskId]: data[taskId]
			? {
					...data[taskId],
					[areaId]: data[taskId][areaId]
						? {
								...data[taskId][areaId],
						  }
						: {},
			  }
			: { [areaId]: {} },
	};
};
