export const SET_EXECUTING_LOADER = 'COUNT/SET_EXECUTING_LOADER';

/**
 * @function countOverViewLoader
 * @description set loader
 * @param {boolean} payload
 */
export const countOverViewLoader = payload => {
	return { type: SET_EXECUTING_LOADER, payload };
};
