import constants from '../../../../contants.js';
import fetchWrapper from '../../../../services/fetchWrapper';
import _isString from 'lodash/isString';

/**
 * @function fetchProducts
 * @description Get order items
 * @param {string} payload store task details
 */
export const fetchProducts = (selectedTaskDetails, workplace) => {
	const endpoint =
		selectedTaskDetails.status === 'executed'
			? constants.API_ENDPOINT +
			  `/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/executions/${selectedTaskDetails.executedEventId}`
			: constants.API_ENDPOINT +
			  `/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/products?date=${selectedTaskDetails.date}`;

	return fetchWrapper({ endpoint })
		.then((items) => items)
		.catch((err) => {
			throw err;
		});
};

/**
 * @function executeRecount
 * @description Get order items
 * @param {string} payload store task details
 */
export const executeRecount = (selectedTaskDetails, products, workplace) => {
	const formattedProducts = [];

	Object.keys(products).forEach((key) => {
		const product = products[key];

		product.units.forEach((unit) => {
			const count = _isString(unit.count)
				? unit.count.replace(/,/g, '.')
				: unit.count;

			formattedProducts.push({
				id: Number(unit.id),
				quantity: Number(count),
			});
		});
	});

	const payload = { products: { ...formattedProducts } };

	const endpoint =
		constants.API_ENDPOINT +
		`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/dates/${selectedTaskDetails.date}/execution`;

	return fetchWrapper({
		endpoint,
		method: 'POST',
		body: JSON.stringify(payload),
	});
};
