import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './searchbar.css';

class Searchbar extends Component {
	render() {
		return (
			<div className="search-bar" onClick={this.props.onClick}>
				<input
					type="text"
					onChange={this.props.onChange}
					placeholder="search..."
					value={this.props.value}
				/>
				<div className="search-bar__clear">
					<Button type="inverted" size="default" onClick={this.props.onClear}>
						<FontAwesomeIcon icon={['fas', 'times']} size="1x" />
					</Button>
				</div>
			</div>
		);
	}
}

Searchbar.propTypes = {
	onChange: PropTypes.func,
	onClear: PropTypes.func,
	value: PropTypes.string,
	onClick: PropTypes.func,
};

export default Searchbar;