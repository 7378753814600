import React from 'react';
import PropTypes from 'prop-types';

import { Header, List, ListItem, Message } from '@dumb';
// import phrases from './countReviewZero.phrases';

const CountReviewZero = ({ onClick, itemList, countedItems, listOrder }) => {
	return (
		<div>
			<Message>
				This list is meant to make sure that you have not forgotten to order
				some of the items. The list below has items that have not been counted.
				Please check it!
			</Message>

			<Header>Not counted</Header>
			<List>
				{listOrder
					.map((key) => {
						if (countedItems[key]) return null;
						const product = countedItems[key] || itemList[key];
						const rightContent = (product?.units || []).map((unit, index) => {
							const prefix = index !== 0 ? ', ' : '';
							const count = unit.count === '' ? 0 : unit.count;

							return (
								<span key={index}>
									{prefix}
									{count} {unit.description}
								</span>
							);
						});

						return (
							<div className="count-page__section" key={key} onClick={onClick}>
								<ListItem rightContent={rightContent}>
									<span>{product?.name ?? ''}</span>
								</ListItem>
							</div>
						);
					})
					.filter((i) => i)}
			</List>
		</div>
	);
};

CountReviewZero.propTypes = {
	onClick: PropTypes.func,
	itemList: PropTypes.object,
	listOrder: PropTypes.array,
	countedItems: PropTypes.object,
};

export default CountReviewZero;
