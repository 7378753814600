export const SET_COUNT_AREAS = 'AREA_LIST/SET_COUNT_AREAS';
export const SET_LOADER = 'AREA_LIST/SET_LOADER';
export const DELETE_COUNT_AREA = 'AREA_LIST/DELETE_COUNT_AREA';
export const SIGN_COUNT_AREA = 'AREA_LIST/SIGN_COUNT_AREA';
export const ADD_COUNT_AREA = 'AREA_LIST/ADD_COUNT_AREA';
export const EDIT_COUNT_AREA = 'AREA_LIST/EDIT_COUNT_AREA';
export const TOGGLE_AREA_LOADER = 'AREA_LIST/TOGGLE_AREA_LOADER';

/**
 * @function setCountAreas
 * @description fetch count areas
 * @param {object} payload Object of count areas
 */
export const setCountAreas = (payload) => {
	return { type: SET_COUNT_AREAS, payload };
};

export const setAreasLoader = (payload) => {
	return { type: SET_LOADER, payload };
};

export const toggleAreaLoader = (payload) => {
	return { type: TOGGLE_AREA_LOADER, payload };
};

export const deleteCountArea = (payload) => {
	return { type: DELETE_COUNT_AREA, payload };
};

export const addCountArea = (payload) => {
	return { type: ADD_COUNT_AREA, payload };
};

export const editCountArea = (payload) => {
	return { type: EDIT_COUNT_AREA, payload };
};

export const signCountArea = (payload) => {
	return { type: SIGN_COUNT_AREA, payload };
};
