import React from 'react';
import { PagesRoutes } from './pages';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faAngleLeft,
	faPlus,
	faArrowUp,
	faArrowDown,
	faTrashAlt,
	faTimes
} from '@fortawesome/free-solid-svg-icons';
import {
	faCheckCircle,
	faArrowAltCircleLeft,
	faKeyboard
} from '@fortawesome/free-regular-svg-icons';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

library.add(
	faCheckCircle,
	faArrowAltCircleLeft,
	faAngleLeft,
	faPlus,
	faArrowUp,
	faArrowDown,
	faTrashAlt,
	faKeyboard,
	faTimes
);

const App = () => <PagesRoutes />;
export default App;
