import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
	ActionAnimations,
	SwipeableList,
	SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';

import history from '../../../routerHistory.js';
import { executeZeroCount, deleteCountArea } from './store/areasList.services';
import { Header, List, ListItem, Button, Modal, Loader } from '@dumb';
import { StickyHeader } from '@smart';
import AnimatedLink from '@dumb/animatedLink/animatedLink';
import phrases from './areasList.phrases';
import Arrow from '../../../library/assets/img/areaArrow';
import RightContent from './components/rightContent/rightContent';
import LeftContent from './components/leftContent/leftContent';
import toast from '../../../library/components/toast/toast';
import formatErrorMessage from '../../../services/formatErrorMessage';

class AreasList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOptions: null,
			zeroCountModal: false,
			executing: false,
		};

		this.showOptions = this.showOptions.bind(this);
		this.hideOptions = this.hideOptions.bind(this);
		this._toggleZeroModal = this._toggleZeroModal.bind(this);
		this._executeZeroCount = this._executeZeroCount.bind(this);
		this.handleEditArea = this.handleEditArea.bind(this);
	}

	showOptions(e, key) {
		if (e.deltaX < 0) return this.setState(() => ({ showOptions: key }));

		if (key === this.state.showOptions)
			this.setState(() => ({ showOptions: false }));
	}

	hideOptions(e) {
		this.setState(() => ({ showOptions: null }));
	}

	_toggleZeroModal() {
		this.setState((prevState) => ({
			zeroCountModal: !prevState.zeroCountModal,
		}));
	}

	_executeZeroCount() {
		this.setState({ executing: true });
		executeZeroCount(
			this.props.selectedTaskDetails,
			this.props.selectedWorkplace
		)
			.then(() => {
				this.setState({ executing: false });
				this._toggleZeroModal();

				history.push({
					pathname: '/tasks',
					state: { transition: 'slide-right', duration: 150 },
				});
			})
			.catch((error) => {
				this.setState({ executing: false });
				toast.show(formatErrorMessage(error), 'error', 3000);
			});
	}

	handleEditArea(areaId) {
		history.push({
			pathname: `/tasks/areas/edit/${areaId}`,
		});
	}

	render() {
		const { countAreas, signed } = this.props;
		const { zeroCountModal, executing } = this.state;

		return (
			<div className="areas-list">
				{countAreas.length ? (
					<>
						<StickyHeader>
							<Header>{phrases.LIST_HEADER}</Header>
						</StickyHeader>
						<SwipeableList threshold={0.5}>
							<List>
								{countAreas.map((area) => {
									// if (!signed) return null;
									const counted = signed?.[area.id];
									const rightContent = counted ? (
										<FontAwesomeIcon icon={['far', 'check-circle']} size="lg" />
									) : null;

									return (
										<div key={area.id}>
											<AnimatedLink path={`/tasks/count/${area.id}`}>
												<SwipeableListItem
													swipeLeft={{
														content: <RightContent />,
														actionAnimation: ActionAnimations.REMOVE,
														action: () => deleteCountArea(area.id),
													}}
													swipeRight={{
														content: <LeftContent />,
														actionAnimation: ActionAnimations.REMOVE,
														action: () => this.handleEditArea(area.id),
													}}>
													<ListItem fullWidth rightContent={rightContent}>
														{area.name}
													</ListItem>
												</SwipeableListItem>
											</AnimatedLink>
										</div>
									);
								})}
							</List>
						</SwipeableList>
					</>
				) : (
					<div className="areas-list__add-area">
						<span className="areas-list__add-area__arrow">
							<Arrow />
						</span>
						<FontAwesomeIcon
							className="areas-list__add-area__icon"
							icon="plus"
							size="5x"
						/>
						<div className="areas-list__add-area__text">
							Press “+” to add a counting area!
						</div>
					</div>
				)}
				<div className="areas-list__zero-count-container">
					{zeroCountModal && (
						<Modal onClose={this._toggleZeroModal} isOpen={zeroCountModal}>
							<div className="areas-list__modal">
								<div className="areas-list__modal__content">
									{executing && (
										<div className="areas-list__modal__loader">
											<Loader />
										</div>
									)}
									<span>Create Zero Count?</span>
								</div>
								<div className="areas-list__modal__buttons">
									<Button
										type="success"
										onClick={this._toggleZeroModal}
										disabled={executing}>
										No
									</Button>
									<Button
										type="danger"
										onClick={this._executeZeroCount}
										disabled={executing}>
										Yes
									</Button>
								</div>
							</div>
						</Modal>
					)}
					<Button type="alternative" onClick={this._toggleZeroModal}>
						Zero Count
					</Button>
				</div>

				<div className="areas-list__tip">
					<FontAwesomeIcon
						className="areas-list__tip__icon"
						icon={faInfoCircle}
						size="1px"
					/>
					<span className="areas-list__tip__text">{phrases.TIP_TEXT}</span>
				</div>
			</div>
		);
	}
}

AreasList.propTypes = {
	countAreas: PropTypes.array,
	selectedTaskDetails: PropTypes.object,
	selectedWorkplace: PropTypes.object,
};

export default AreasList;
