// Place for common shared settings, options, formats and etc

export const listTypes = {
	NOT_CONFIRMED_LIST: 'notConfirmedList',
	CONFIRMED_LIST: 'confirmedList',
	ADJUSTED_LIST: 'adjustedList',
	RECEIVED_LIST: 'receivedList',
	COUNTED_LIST: 'counted',
	SUGGESTIONS_LIST: 'suggestions',
	REST_ITEMS: 'restItems'
};
