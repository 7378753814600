import constants from '../../../../contants.js';
import fetchWrapper from '../../../../services/fetchWrapper';
import _isString from 'lodash/isString';

export const UPDATE_ORDER_ITEM = 'ORDER/UPDATE_ORDER_ITEM';
export const SET_ORDER_DATA = 'ORDER/SET_ORDER_DATA';
export const LIST_ITEM_SELECTED = 'ORDER/LIST_ITEM_SELECTED';
export const ORDERS_FETCH_SUCCESS = 'ORDER/ORDERS_FETCH_SUCCESS';
export const RESET_STATE = 'ORDER/RESET_STATE';
export const EXECUTING_TASK = 'ORDER/EXECUTING_TASK';
export const SET_FETCHING_DATA = 'ORDER/SET_FETCHING_DATA';
export const TOGGLE_COLLAPSE = 'ORDER/TOGGLE_COLLAPSE';
export const SET_ACTIVE_ORDER = 'ORDER/SET_ACTIVE_ORDER';
export const SET_SEARCH = 'ORDER/SET_SEARCH';
export const CLEAR_SEARCH = 'ORDER/CLEAR_SEARCH';

// ****************
// DATA FETCHES
// ****************



/**
 * @function fetchOrderItems
 * @description Get order items
 * @param {string} payload store task details
 */
export const fetchOrderItems = (selectedTaskDetails, workplace) => {
	if (selectedTaskDetails.status === 'planned')
		return fetchWrapper({
			endpoint:
				constants.API_ENDPOINT +
				`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/products?date=${selectedTaskDetails.date}`,
		});
	else
		return fetchWrapper({
			endpoint:
				constants.API_ENDPOINT +
				`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/executions/${selectedTaskDetails.executedEventId}`,
		});
};

/**
 * @function executeTask
 * @description Get order items
 * @param {string} payload store task details
 */
export const executeTask = (selectedTaskDetails, products, workplace) => {
	const formattedProducts = Object.keys(products).map((key) => {
		const product = products[key];

		const count = _isString(product.count)
			? product.count.replace(/,/g, '.')
			: product.count;

		return {
			id: Number(product.id),
			quantity: Number(count),
		};
	});

	const payload = { products: formattedProducts };

	return fetchWrapper({
		endpoint:
			constants.API_ENDPOINT +
			`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/dates/${selectedTaskDetails.date}/execution`,
		method: 'POST',
		body: JSON.stringify(payload),
	});
};

// ********************
// EXPORTED ACTIONS
// ********************

/**
 * @function setSearch
 * @description Set search input
 * @param {string} payload search term, should be throttled
 */
export const setSearch = (payload) => {
	return { type: SET_SEARCH, payload };
};

/**
 * @function clearSearch
 * @description Clear search input
 */
export const clearSearch = () => {
	return { type: CLEAR_SEARCH };
};

/**
 * @function setFetchingData
 * @description Sets the loader status
 * @param {string} payload is loading
 */
export const setFetchingData = (payload) => {
	return { type: SET_FETCHING_DATA, payload };
};

/**
 * @function updateOrderList
 * @description Update order list
 * Is only being called when editable components are mounted
 * @param {number} id Order Item id
 * @param {object} payload Object with modified params
 * @param {number} status Order Item id
 */
export const updateOrderList = (id, payload, status, taskDetails, key) => {
	return { type: UPDATE_ORDER_ITEM, payload, id, status, taskDetails, key };
};

/**
 * @function updateOrderList
 * @description Signing task is loading
 * @param {boolean} payload
 */
export const executingTask = (payload) => {
	return { type: EXECUTING_TASK, payload };
};
/**
 * @function setActiveOrder
 * @description Set active order 
 * @param {boolean} payload
 */
export const setActiveOrder = (payload) => {
	return { type: SET_ACTIVE_ORDER, payload };
};

/**
 * @function selectListItem
 * @description When an item is selected in the order list
 * @param {string} payload takes a string of list name and item key combined
 */
export const selectListItem = (payload) => {
	return { type: LIST_ITEM_SELECTED, payload };
};

/**
 * @function setOrderData
 * @description Set order data fetched from local storage
 * @param {string} payload object of order lists
 */
export const setOrderData = (payload) => {
	return { type: SET_ORDER_DATA, payload };
};

/**
 * @function orderFetchSuccess
 * @description Set order data fetched from local storage
 * @param {string} payload object of order lists
 */
export const orderFetchSuccess = (payload, id) => {
	return { type: ORDERS_FETCH_SUCCESS, payload, id };
};

export const toggleSuggestionsList = (payload) => {
	return { type: TOGGLE_COLLAPSE, payload };
};
