import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AnimatedLink from '../../../library/components/animatedLink/animatedLink';

import AreasList from './areasList.component';
import { Button } from '../../../library/components';
import { Navigation } from '@smart';
import history from '../../../routerHistory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderPage from '../../loaderPage/loaderPage.component';

// Tools / Services
import phrases from './areasList.phrases';
import './areasList.css';

class AreasListContainer extends Component {
	onBackClick() {
		history.push({
			pathname: '/tasks',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	render() {
		const {
			countAreas,
			countAreasUi,
			selectedTaskDetails,
			selectedWorkplace,
			signed,
		} = this.props;

		return (
			<div>
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={phrases.NAV_HEADER}
					rightContent={
						<AnimatedLink path="/tasks/areas/add">
							<FontAwesomeIcon icon="plus" size="lg" />
						</AnimatedLink>
					}
				/>

				{countAreasUi.loading ? (
					<LoaderPage text="Fetching areas..." />
				) : (
					<AreasList
						// countAreasUi={countAreasUi}
						countAreas={countAreas}
						signed={signed}
						selectedWorkplace={selectedWorkplace}
						selectedTaskDetails={selectedTaskDetails}
					/>
				)}
			</div>
		);
	}
}

AreasListContainer.propTypes = {
	countAreas: PropTypes.array,
	selectedTaskDetails: PropTypes.object,
	countAreasUi: PropTypes.object,
	selectedWorkplace: PropTypes.object,
	// selectWorkplace: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			// selectWorkplace
		},
		dispatch
	);
};

const mapStateToProps = (state) => ({
	countAreas: state.countAreas.data.countAreas,
	signed: state.countAreas.data.signed[state.storeTasks.selectedTask.taskId],
	countAreasUi: state.countAreas.ui,
	selectedTaskDetails: state.storeTasks.selectedTask,
	selectedWorkplace: state.workplaces.data.selectedWorkplace,
});

export default connect(mapStateToProps, mapDispatchToProps)(AreasListContainer);
