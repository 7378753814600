export const UPDATE_JUSTIFICATION = 'ORDER_JUSTIFICATION/UPDATE_JUSTIFICATION';

/**
 * @function updateOrderJustification
 * @description Update order justification
 * @param {string} payload string of justification.
 */
export const updateOrderJustification = (payload) => {
	return ({ type: UPDATE_JUSTIFICATION, payload });
};
