import listService from '../../../services/listService';
import { listTypes } from '../../../services/common';
import _get from 'lodash/get';

import {
	SET_RECEIVED_ITEMS,
	RESET_STATE,
	EXECUTING_TASK,
	SET_FETCHING_DATA,
	SELECT_LIST_ITEM,
	SET_ACTIVE_RECEIVE_TASK,
	UPDATE_ITEM_COUNT,
	SET_SEARCH,
	CLEAR_SEARCH,
} from './receivedCount.actions';

const initialState = {
	data: {
		receivedDataList: {},
		receivedList: {},
		adjustedList: {},
		confirmedList: {},
		additionalItems: {},
		taskId: null,
	},
	ui: {
		selectedListItem: '',
		executingTask: false,
		confirmedListOrder: [],
		receivedListOrder: [],
		adjustedListOrder: [],
		additionalItemsOrder: [],
		fetchingData: false,
		search: '',
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_ITEM_COUNT: {
			const { itemId, updatedValue, listStatus, actionType } = action;
			const taskId = state.data.taskId;
			const receiveTask = state.data?.[taskId];

			const confirmedList = receiveTask.confirmedList;
			let confirmedListOrder = state.ui.confirmedListOrder;
			const adjustedList = receiveTask.adjustedList;
			let adjustedListOrder = state.ui.adjustedListOrder;
			const updatedList = receiveTask[listStatus];
			let selectedListItem = state.ui.selectedListItem;
			let receivedListOrder = state.ui.receivedListOrder;
			const receivedItemCount = _get(
				receiveTask,
				`receivedDataList[${itemId}].count`,
				undefined
			);

			// If an item is confirmed
			if (actionType === 'confirm') {
				// If the count is different to the initially fetched count

				if (updatedList[itemId].count !== receivedItemCount) {
					adjustedList[itemId] = { ...updatedList[itemId], ...updatedValue };
					adjustedListOrder = listService.sortObjectByAlphabet(
						receiveTask.adjustedList
					);
				} else {
					// If the count is the same to the initially fetched count
					confirmedList[itemId] = { ...updatedList[itemId], ...updatedValue };
					confirmedListOrder = listService.sortObjectByAlphabet(
						receiveTask.confirmedList
					);
				}
				delete updatedList[itemId];

				// Keep count buttons in same spot when they approach the bottom of the page
				listService.fixItemCounterPosition();
				// reorder the approriately changed list
				receivedListOrder = listService.sortObjectByAlphabet(
					receiveTask.receivedList
				);
				// If the confirmed list is modified
			} else if (listStatus === listTypes.CONFIRMED_LIST) {
				// If the count is not the same as the initially feched count move the item to the confirmed list
				if (receivedItemCount !== updatedValue.count) {
					adjustedList[itemId] = { ...confirmedList[itemId], ...updatedValue };
					delete updatedList[itemId];
					// reorder the modified lists
					adjustedListOrder = listService.sortObjectByAlphabet(
						receiveTask.adjustedList
					);
					confirmedListOrder = listService.sortObjectByAlphabet(
						receiveTask.confirmedList
					);
					// select the item that was moved to the different list
					const index = adjustedListOrder.findIndex(
						(id) => Number(id) === itemId
					);
					selectedListItem = `adjustedList-${index}`;
					// Scroll to the item that was moved
					setTimeout(() => {
						listService.scrollToCountButtons();
					}, 100);
				}
				// If the adjusted list is modified
			} else if (listStatus === listTypes.ADJUSTED_LIST) {
				// If the count is the same as the initially feched count move the item to the confirmed list
				if (receivedItemCount === updatedValue.count) {
					confirmedList[itemId] = { ...adjustedList[itemId], ...updatedValue };
					delete updatedList[itemId];
					// reorder the modified lists
					adjustedListOrder = listService.sortObjectByAlphabet(
						receiveTask.adjustedList
					);
					confirmedListOrder = listService.sortObjectByAlphabet(
						receiveTask.confirmedList
					);
					// select the item that was moved to the different list
					const itemIndex = confirmedListOrder.findIndex(
						(id) => Number(id) === itemId
					);
					selectedListItem = `confirmedList-${itemIndex}`;
					// Scroll to the item that was moved
					setTimeout(() => {
						listService.scrollToCountButtons();
					}, 100);
				} else {
					updatedList[itemId] = { ...updatedList[itemId], ...updatedValue };
				}
			} else {
				updatedList[itemId] = { ...updatedList[itemId], ...updatedValue };
			}

			return {
				...state,
				data: {
					...state.data,
					[taskId]: {
						...state.data[taskId],
						adjustedList,
						confirmedList,
						[listStatus]: { ...updatedList },
					},
				},
				ui: {
					...state.ui,
					selectedListItem,
					receivedListOrder,
					confirmedListOrder,
					adjustedListOrder,
				},
			};
		}
		case SELECT_LIST_ITEM:
			return {
				...state,
				ui: { ...state.ui, selectedListItem: action.payload },
			};

		case SET_RECEIVED_ITEMS: {
			const receivedList = {};
			const receivedDataList = {};
			const additionalItems = {};
			const taskId = state.data.taskId;
			const receiveTask = state.data?.[taskId];

			action.payload.forEach((item) => {
				const itemId = item.supplier_raw_product.data.id;

				const formattedItem = {
					id: itemId,
					name: item.supplier_raw_product.data.name,
					description: item.supplier_raw_product.data.description,
					count: item.quantity,
					unit: item.supplier_raw_product.data.description
						.split(/\s+/)
						.slice(1, 2)[0],
				};
				receivedDataList[itemId] = formattedItem;

				if (
					receiveTask?.confirmedList?.[itemId] ||
					receiveTask?.adjustedList?.[itemId]
				)
					return;

				if (formattedItem.count > 0) {
					receivedList[itemId] = formattedItem;
				} else {
					additionalItems[itemId] = formattedItem;
				}
			});

			let confirmedListOrder = [];
			if (Object.keys(receiveTask?.confirmedList).length > 0)
				confirmedListOrder = listService.sortObjectByAlphabet(
					receiveTask.confirmedList
				);

			let adjustedListOrder = [];
			if (Object.keys(receiveTask?.adjustedList).length > 0)
				adjustedListOrder = listService.sortObjectByAlphabet(
					receiveTask.adjustedList
				);

			// Order notConfirmedList Items by alphabet
			const listOrder = listService.sortObjectByAlphabet(receivedList);
			// Order additional items Items by alphabet
			const additionalItemsOrder = listService.sortObjectByAlphabet(
				additionalItems
			);

			return {
				...state,
				data: state.data[taskId]
					? {
							...state.data,
							[taskId]: {
								...state.data[taskId],
								receivedList,
								receivedDataList,
								additionalItems,
							},
					  }
					: {
							...state.data,
							[taskId]: {
								receivedList,
								receivedDataList,
								additionalItems,
							},
					  },
				ui: {
					...state.ui,
					receivedListOrder: listOrder,
					confirmedListOrder,
					adjustedListOrder,
					additionalItemsOrder,
				},
			};
		}

		case RESET_STATE:
			return {
				data: {
					receivedDataList: {},
					receivedList: {},
					adjustedList: {},
					confirmedList: {},
					additionalItems: {},
				},
				ui: {
					selectedListItem: '',
					executingTask: false,
					confirmedListOrder: [],
					receivedListOrder: [],
					adjustedListOrder: [],
					additionalItemsOrder: [],
					fetchingData: false,
					search: '',
				},
			};

		case EXECUTING_TASK:
			return { ...state, ui: { ...state.ui, executingTask: action.payload } };

		case SET_ACTIVE_RECEIVE_TASK: {
			return {
				...state,
				data: {
					...state.data,
					taskId: action.payload,
					[action.payload]: state.data[action.payload]
						? {
								...state.data[action.payload],
						  }
						: {
								receivedDataList: {},
								receivedList: {},
								adjustedList: {},
								confirmedList: {},
								additionalItems: {},
						  },
				},
			};
		}

		case SET_FETCHING_DATA:
			return { ...state, ui: { ...state.ui, fetchingData: action.payload } };

		case SET_SEARCH:
			return { ...state, ui: { ...state.ui, search: action.payload } };

		case CLEAR_SEARCH:
			return { ...state, ui: { ...state.ui, search: '' } };

		default:
			return state;
	}
};
