import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Loader } from '@dumb';
import phrases from './areaConfiguration.phrases';

class AreaConfiguration extends Component {
	constructor(props) {
		super(props);

		this.onTitleChange = this.onTitleChange.bind(this);
	}

	onTitleChange(event) {
		const payload = { name: event.target.value };
		this.props.updateCountArea(payload);
	}

	render() {
		const { areaDetails, onSubmit, loading } = this.props;

		const isValidTitle = areaDetails.name.length < 1;

		return (
			<div className="area-configuration">
				{loading && <Loader className="area-configuration__loader" />}

				<div className="area-configuration__section area-configuration__title-input-container">
					<div className="area-configuration__label">
						{phrases.INPUT_TITLE_LABEL}
					</div>
					<input
						className="area-configuration__title-input-container__input"
						value={areaDetails.name}
						onChange={(event) => this.onTitleChange(event)}
						type="text"
					/>
				</div>

				<div className="area-configuration__button-wrapper">
					<Button
						size="full"
						disabled={loading || isValidTitle}
						onClick={onSubmit}
						type="success">
						{phrases.SAVE}
					</Button>
				</div>
			</div>
		);
	}
}

AreaConfiguration.defaultProps = {
	areaDetails: {
		name: '',
	},
};

AreaConfiguration.propTypes = {
	areaDetails: PropTypes.object,
	updateCountArea: PropTypes.func,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
};

export default AreaConfiguration;
