import React, { Component } from 'react';
import PropTypes from 'prop-types';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCountArea, resetState } from './store/areaConfiguration.actions';

// routing
import history from '../../../../../routerHistory';

// services
import { addCountArea, editCountArea } from '../../store/areasList.services';

// components
import { Navigation } from '@smart';
import { Button } from '@dumb';
import AreaConfiguration from './areaConfiguration.component';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// lodash
import _isEmpty from 'lodash/isEmpty';

// phrases
import phrases from './areaConfiguration.phrases';

import './areaConfiguration.css';

class AreaConfigurationContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};

		this.handleAddCountingArea = this.handleAddCountingArea.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleAddCountingArea = this.handleAddCountingArea.bind(this);
		this.handleEditCountingArea = this.handleEditCountingArea.bind(this);
	}

	componentDidMount() {
		const { resetState, countAreas, match, updateCountArea } = this.props;

		const areaId = match?.params?.areaId;

		resetState();

		if (!this.props.selectedWorkplace.id) {
			history.push({
				pathname: '/tasks/areas',
				state: { transition: 'slide-right', duration: 150 },
			});
		}
		// if edit mode, set the editable area in redux
		if (areaId && !_isEmpty(countAreas)) {
			const areaToUpdate = countAreas.find(
				(entry) => entry.id === parseInt(areaId)
			);

			if (!areaToUpdate) return;

			const payload = {
				id: areaToUpdate.id,
				name: areaToUpdate.name,
			};

			updateCountArea(payload);
		}
	}

	onBackClick() {
		history.push({
			pathname: '/tasks/areas',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	handleAddCountingArea() {
		const { areaDetails, selectedWorkplace } = this.props;

		const payload = {
			name: areaDetails.name,
			store: selectedWorkplace.id,
		};

		this.setState(() => ({ loading: true }));

		addCountArea(payload)
			.then(() => {
				history.push({
					pathname: '/tasks/areas',
					state: { transition: 'slide-right', duration: 150 },
				});
			})
			.finally(() => {
				this.setState(() => ({ loading: false }));
			});
	}

	handleEditCountingArea() {
		const { areaDetails } = this.props;

		const payload = {
			id: areaDetails.id,
			name: areaDetails.name,
		};

		this.setState(() => ({ loading: true }));

		editCountArea(payload)
			.then(() => {
				history.push({
					pathname: '/tasks/areas',
					state: { transition: 'slide-right', duration: 150 },
				});
			})
			.finally(() => {
				this.setState(() => ({ loading: false }));
			});
	}

	onSubmit() {
		const { match } = this.props;

		if (match?.params?.areaId) {
			this.handleEditCountingArea();
		} else {
			this.handleAddCountingArea();
		}
	}

	render() {
		const {
			updateCountArea,
			areaDetails,
			selectedTaskDetails,
			selectedWorkplace,
			match,
		} = this.props;

		return (
			<div>
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={
						match?.params?.areaId
							? phrases.NAV_HEADER_EDIT
							: phrases.NAV_HEADER_ADD
					}
				/>
				<AreaConfiguration
					areaId={match?.params?.areaId}
					areaDetails={areaDetails}
					updateCountArea={updateCountArea}
					selectedTaskDetails={selectedTaskDetails}
					selectedWorkplace={selectedWorkplace}
					onSubmit={this.onSubmit}
					loading={this.state.loading}
				/>
			</div>
		);
	}
}

AreaConfigurationContainer.propTypes = {
	updateCountArea: PropTypes.func,
	areaDetails: PropTypes.object,
	selectedTaskDetails: PropTypes.object,
	resetState: PropTypes.func,
	selectedWorkplace: PropTypes.object,
	match: PropTypes.object,
	countAreas: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateCountArea,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (state) => ({
	areaDetails: state.areaConfig.areaDetails,
	selectedTaskDetails: state.storeTasks.selectedTask,
	selectedWorkplace: state.workplaces.data.selectedWorkplace,
	countAreas: state.countAreas.data.countAreas,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AreaConfigurationContainer);
