import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Header, List, ListItem, AnimatedLink } from '@dumb';
import { StickyHeader } from '@smart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import phrases from './areCountOverview.phrases';

class AreaCountOverview extends Component {
	render() {
		const { countAreas, signed } = this.props;
		return (
			<div className="areas-list">
				<StickyHeader>
					<Header>{phrases.HEADER}</Header>
				</StickyHeader>
				<List>
					{countAreas.map((area) => {
						if (!signed) return null;
						const counted = signed[area.id];
						const rightContent = counted ? (
							<FontAwesomeIcon icon={['far', 'check-circle']} size="lg" />
						) : null;

						return (
							<div key={area.id}>
								<ListItem rightContent={rightContent}>
									<AnimatedLink path={`/tasks/count/${area.id}`}>
										{area.name}
									</AnimatedLink>
								</ListItem>
							</div>
						);
					})}
				</List>
			</div>
		);
	}
}

AreaCountOverview.propTypes = {
	countAreas: PropTypes.array,
};

export default AreaCountOverview;
