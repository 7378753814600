import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../../routerHistory.js';

import { Burger } from '@dumb';
import { Navigation } from '@smart';

import StoreTasks from './storeTasks.component';
import {
	toggleNavigation,
	setNavBarMovement,
} from '@smart/navigation/store/navigation.actions';

import {
	setSelectedTaskData,
	fetchingStoreTasks,
	setStoreTasks,
	setSelectedDate,
} from './store/storeTasks.actions';
import { fetchStoreTasks, fetchCountAreas } from './store/storeTasks.services';

import LoaderPage from '../loaderPage/loaderPage.component';

import './storeTasks.css';

import {
	formatDate,
	add,
	startOf,
	subtract,
	getDay,
} from '../../services/dates';

class StoreTaskContainer extends Component {
	constructor(props) {
		super(props);
		this.currentDay = getDay();

		this.fetchPreviousTasks = this.fetchPreviousTasks.bind(this);
		this.fetchStoreTasks = this.fetchStoreTasks.bind(this);
		this.onTaskSelected = this.onTaskSelected.bind(this);
	}

	fetchStoreTasks(dates, refetch = false) {
		const { setStoreTasks, fetchingStoreTasks, selectedWorkplace } = this.props;

		// Loader
		if (!refetch) fetchingStoreTasks(true);

		// Fetch store task & count areas => store redux + local storage
		return fetchStoreTasks(dates, selectedWorkplace.id)
			.then((storeTasks) => {
				setStoreTasks(storeTasks.data);
			})
			.then(() => {
				return fetchCountAreas(selectedWorkplace.id).then(() => {
					fetchingStoreTasks(false);
				});
			})
			.catch(() => {
				fetchingStoreTasks(false);
			});
	}

	componentDidMount() {
		const { selectedWorkplace } = this.props;

		if (!selectedWorkplace) {
			history.push({
				pathname: '/workplaces',
			});
			return;
		}

		const dates = {
			from: formatDate(add(getDay(), -2, 'day'), 'YYYY-MM-DD'),
			to: formatDate(add(getDay(), 7, 'day'), 'YYYY-MM-DD'),
		};

		setSelectedDate(dates);

		this.fetchStoreTasks(dates).then(() => {
			const el = document.getElementById('today');
			if (!el) return;
			el.scrollIntoView({ behavior: 'smooth' });
		});
	}

	fetchPreviousTasks() {
		const { setSelectedDate } = this.props;

		const startOfLastWeek = startOf(
			subtract(this.currentDay, 7, 'day'),
			'week'
		);
		const endOfWeek = add(this.currentDay, 7, 'day');
		const dates = {
			from: formatDate(startOfLastWeek, 'YYYY-MM-DD'),
			to: formatDate(endOfWeek, 'YYYY-MM-DD'),
		};
		setSelectedDate(dates);

		return this.fetchStoreTasks(dates, true);
	}

	/**
	 * @function onTaskSelected
	 * @description Get the path for the next view when task is selected
	 * @param {Object} taskType Task type
	 * @param {Object} taskStatus Task status
	 */
	getTaskPath(taskType, taskStatus) {
		// @TODO: <3
		if (taskType === 'count') {
			if (taskStatus === 'executed') {
				return 'pastcount';
			} else {
				return 'areas';
			}
		}

		return taskType;
	}

	/**
	 * @function onTaskSelected
	 * @description Saves the selected task details to local storage for use in offline mode
	 * @param {Object} supplier Supplier name
	 * @param {Object} task Task title
	 * @param {Object} date Date of task
	 * @param {Object} taskId Task id
	 */
	onTaskSelected({ supplier, taskTitle, date, task, taskId }) {
		const { setSelectedTaskData } = this.props;

		const payload = {
			supplier,
			taskTitle,
			date,
			taskId,
			executedEventId: task.executedEventId,
			status: task.status,
		};

		setSelectedTaskData(payload);

		const taskPath = this.getTaskPath(taskTitle, task.status);

		history.push({
			pathname: `/tasks/${taskPath}`,
			state: {
				transition: 'slide-left',
				duration: 150,
			},
		});
	}

	render() {
		const {
			storeTasks,
			toggleNavigation,
			setSelectedTaskData,
			isNavigationVisible,
			orderedStoreKeys,
			selectedWorkplace,
			isFetchingStoreTasks,
			setNavBarMovement,
			setSelectedDate,
		} = this.props;

		return (
			<div>
				<Navigation
					leftContent={
						<Burger type="text" onClick={() => toggleNavigation(true)} />
					}
					header={selectedWorkplace ? selectedWorkplace.name : ''}
				/>
				{isFetchingStoreTasks ? (
					<LoaderPage text="Fetching Tasks..." />
				) : (
					<StoreTasks
						setSelectedTaskData={setSelectedTaskData}
						storeTasks={storeTasks}
						isNavigationVisible={isNavigationVisible}
						orderedStoreKeys={orderedStoreKeys}
						setNavBarMovement={setNavBarMovement}
						currentDay={this.currentDay}
						fetchStoreTasks={this.fetchStoreTasks}
						fetchPreviousTasks={this.fetchPreviousTasks}
						onTaskSelected={this.onTaskSelected}
						setSelectedDate={setSelectedDate}
					/>
				)}
			</div>
		);
	}
}

StoreTaskContainer.propTypes = {
	storeTasks: PropTypes.object,
	toggleNavigation: PropTypes.func,
	setSelectedTaskData: PropTypes.func,
	isNavigationVisible: PropTypes.bool,
	setStoreTasks: PropTypes.func,
	orderedStoreKeys: PropTypes.array,
	selectedWorkplace: PropTypes.object,
	fetchingStoreTasks: PropTypes.func,
	isFetchingStoreTasks: PropTypes.bool,
	setNavBarMovement: PropTypes.func,
	selectedDates: PropTypes.object,
	setSelectedDate: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			toggleNavigation,
			setSelectedTaskData,
			setStoreTasks,
			fetchingStoreTasks,
			setNavBarMovement,
			setSelectedDate,
		},
		dispatch
	);
};

const mapStateToProps = (state) => ({
	storeTasks: state.storeTasks.storeTasks,
	isFetchingStoreTasks: state.storeTasks.fetchingStoreTasks,
	isNavigationVisible: state.navigation.isVisible,
	orderedStoreKeys: state.storeTasks.orderedStoreKeys,
	selectedWorkplace: state.workplaces.data.selectedWorkplace,
	selectedDates: state.storeTasks.selectedDates,
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTaskContainer);
