import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import './modal.css';

class Modal extends Component {
	constructor(props) {
		super(props);
		this.node = document.createElement('div');
		document.body.appendChild(this.node);

		this._handleClickOutside = this._handleClickOutside.bind(this);
		this._escFunction = this._escFunction.bind(this);
	}

	_handleClickOutside(e) {
		const { onClose } = this.props;

		onClose();
	}

	_escFunction(event) {
		if (event.keyCode === 27) this.props.onClose();
	}

	componentDidMount() {
		// Add class
		this.node.className = 'j-modal--wrapper';

		// Create dim
		this.dim = document.createElement('div');
		this.dim.className = 'j-modal--dim';
		this.node.insertAdjacentElement('afterbegin', this.dim);

		// Event Listeners
		document.addEventListener('keydown', this._escFunction, false);
		this.dim.addEventListener('click', this._handleClickOutside, false);
	}

	componentWillUnmount() {
		this.dim.removeEventListener('click', this._handleClickOutside, false);
		document.removeEventListener('keydown', this._escFunction, false);
		document.body.removeChild(this.node);
	}

	render() {
		const {
			children,
			className,
			isOpen,
			noScroll
		} = this.props;

		const classnames = cx('j-modal', {
			'j-modal--no-scroll': noScroll,
			[`${className}`]: className
		});

		return ReactDOM.createPortal(
			<CSSTransition
				classNames="j-modal__outer"
				timeout={200}
				in={isOpen}
				mountOnEnter
				appear>
				<div className={classnames}>
					{children}
				</div>
			</CSSTransition>,
			this.node
		);
	}
}

Modal.defaultProps = {
	header: '',
	valid: true,
	type: '',
	isOpen: true,
	className: '',
	onClose: () => {
		// console.info('"onClose" prop was not defined in Modal component.');
	},
	onConfirmClick: () => {
		console.info('"onConfirmClick" prop was not defined in Modal component.');
	},
	onCancelClick: () => {
		console.info('"onCancelClick" prop was not defined in Modal component.');
	}
};

Modal.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]),
	onClose: PropTypes.func,
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	noScroll: PropTypes.bool,
};

export default Modal;
