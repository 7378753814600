import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './burger.css';

class Burger extends Component {
	onClick(e) {
		let { onClick } = this.props;
		e.preventDefault();
		onClick();
	}

	render() {
		let { color } = this.props;

		return (
			<div className='burger' onClick={(e) => this.onClick(e)}>
				<button className={`burger__burger-button burger__burger-button--${color}`}>
					<span />
					<span />
					<span />
				</button>
			</div>
		);
	}
}

Burger.defaultProps = {
	color: 'white'
};

Burger.propTypes = {
	onClick: PropTypes.func.isRequired,
	color: PropTypes.string
};

export default Burger;