import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';
import cx from 'classnames';

import './countUnitSection.css';

class CountUnitSection extends PureComponent {
	constructor(props) {
		super(props);

		this.refDict = {};
		this.state = { isValid: true };
	}

	selectValue(event, listStatus, productId, unit) {
		const { onItemCountUpdate } = this.props;

		event.target.focus();

		// Mobile IOS hack to make input text focused
		// event.target.setSelectionRange(0, event.target.value.length);

		onItemCountUpdate(event, listStatus, productId, unit);

		const count = event ? event.target.value : null;
		if (count && listStatus === 'counted') event.target.select();
		if (count) LogRocket.track(`Counting ${this.props.product.name}`);
	}

	componentDidMount() {
		const { index, unit } = this.props;
		// Used to focus on the first item unit count input when item is selected
		if (index === 0) {
			this.refDict.input.select();
			this.refDict.input.focus();
		}

		// check if valid if count has data
		if (unit.count) {
			this.onItemChange({
				target: {
					value: unit.count,
				},
			});
		}
	}

	onItemChange(e) {
		const item = e.target.value;

		const regex = RegExp(/^[0-9]+([\,\.][0-9]{1,2})?$/g);
		this.setState({ isValid: item === '' ? true : regex.test(item) });
	}

	render() {
		const { unit, onItemCountUpdate, product, listStatus } = this.props;

		const classnames = cx('count-unit-section', {
			[`count-unit-section__invalid`]: !this.state.isValid,
		});

		return (
			<div className={classnames}>
				<div className="count-unit-section__left">
					<div>
						<p>{unit.productName}</p>
						<span>{unit.unit}</span>
					</div>
					<input
						ref={(input) => (this.refDict.input = input)}
						onFocus={(event) =>
							this.selectValue(event, listStatus, product.id, unit.id)
						}
						value={unit.count}
						onChange={(event) => {
							this.onItemChange(event);
							onItemCountUpdate(event, listStatus, product.id, unit.id);
						}}
						type="text"
						inputMode="decimal"
					/>
				</div>
				<div className="count-unit-section__right">
					<span>{unit.description}</span>
					<img
						className="count-unit-section__image"
						src={unit.image || '/images/joe.png'}
						alt="img"
					/>
				</div>
			</div>
		);
	}
}

CountUnitSection.propTypes = {
	unit: PropTypes.object,
	product: PropTypes.object,
	listStatus: PropTypes.string,
	onItemCountUpdate: PropTypes.func,
	index: PropTypes.number,
};

export default CountUnitSection;
