import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '../../../routerHistory.js';

import AreasList from './areaCountOverview.component.jsx';
import { Button } from '@dumb';
import { Navigation } from '@smart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import phrases from './areCountOverview.phrases';
import { event, set } from '@tools/tracking/analytics.service';

import './areaCountOverview.css';

class AreaCountContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allAreasCounted: false,
		};

		this.onSignClick = this.onSignClick.bind(this);
		this.onBackClick = this.onBackClick.bind(this);
	}

	onSignClick() {
		const { selectedWorkplace } = this.props;

		history.push({
			pathname: '/tasks/counts/review',
			state: { transition: 'slide-left', duration: 150 },
		});

		set({ countedWorkplace: selectedWorkplace.id });
		set({ dimension1: selectedWorkplace.id });
		event({
			category: 'Storetask',
			action: 'Sign Count',
			label: `Signed count ${selectedWorkplace.id}`,
			value: selectedWorkplace.id,
		});
	}

	onBackClick() {
		history.push({
			pathname: '/tasks',
			state: {
				transition: 'slide-right',
				duration: 150,
				from: this.props.location.pathname,
			},
		});
	}

	render() {
		const { countAreas, selectedTaskDetails, signed } = this.props;
		const allCounted = signed && Object.keys(signed).length >= countAreas.length;

		return (
			<div className="area-count-overview">
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={phrases.NAV_HEADER}
					rightContent={
						<Button
							type="text"
							onClick={this.onSignClick}
							disabled={!allCounted}>
							<span className="icon">
								<FontAwesomeIcon icon={['far', 'check-circle']} size="lg" />
							</span>
						</Button>
					}
				/>
				<AreasList
					countAreas={countAreas}
					signed={signed}
					selectedTaskDetails={selectedTaskDetails}
				/>
			</div>
		);
	}
}

AreaCountContainer.propTypes = {
	countAreas: PropTypes.array,
	selectedWorkplace: PropTypes.object,
	selectedTaskDetails: PropTypes.object,
	location: PropTypes.object,
	signed: PropTypes.object,
};



const mapStateToProps = (state) => {
	return {
		countAreas: state.countAreas.data.countAreas,
		signed: state.countAreas.data.signed[state.storeTasks.selectedTask.taskId],
		selectedTaskDetails: state.storeTasks.selectedTask,
		selectedWorkplace: state.workplaces.data.selectedWorkplace,
	};
};

export default connect(mapStateToProps)(AreaCountContainer);
