import auth0 from 'auth0-js';
import history from './routerHistory.js';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import { store } from 'store';
import { setUser } from 'actions/auth.actions';

export default class Auth {
	constructor() {
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.handleAuthentication = this.handleAuthentication.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
	}

	auth0 = new auth0.WebAuth({
		domain: 'auth.joejuice.com',
		clientID: 'Qsz88Vl8JhJasmtgRmcOOj5ejLJU74Yk',
		redirectUri: `${window.location.origin}/callback`,
		responseType: 'token id_token',
		audience: 'http://localhost:80',
		scope: 'openid',
	});

	handleAuthentication() {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult);
			} else if (err) {
				history.replace('/tasks');
				console.log(err);
			}
		});
	}

	setSession(authResult) {
		// Set the time that the Access Token will expire at
		const expiresIn = authResult.expiresIn * 1000 + new Date().getTime();

		// Custom claim for person id. The login flow through auth0 fethces the person id.
		// In order to attach to the return idTokenPayload, we need to implement a rule in auth0
		// and then fetch it with a custom namespaces key "https://joejuice.com/person_id".
		// See more under https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims#custom-claims
		// and custom auth0 rule with name "Return user with personId"
		const personId =
			authResult.idTokenPayload['https://joejuice.com/person_id'];

		Sentry.configureScope((scope) => {
			scope.setUser({ id: personId });
		});

 		LogRocket.identify(personId);

		store.dispatch(
			setUser({
				accessToken: authResult.accessToken,
				idToken: authResult.idToken,
				personId,
				expiresIn,
			})
		);

		history.replace('/tasks');
	}

	/**
	 * @function logout
	 * @description calls auth0 logout function and clears expires_in object in local storage
	 */
	logout() {
		store.dispatch(setUser({}));

		this.auth0.logout();

		// navigate to the home route
		history.replace('/tasks');
	}

	isAuthenticated() {
		// Check whether the current time is past the
		// Access Token's expiry time

		const state = store.getState();
		const expiresIn = state?.auth?.user.expiresIn;
		return new Date().getTime() < expiresIn;
	}

	login() {
		this.auth0.authorize();
	}
}
