import PropTypes from 'prop-types';
import React, { Component } from 'react';
import history from '../../../routerHistory.js';

import './animatedLink.css';

class AnimatedLink extends Component {
	constructor(props) {
		super(props);

		this.onLinkClick = this.onLinkClick.bind(this);
	}

	onLinkClick() {
		const { transition, path, duration, search, onClick } = this.props;
		
		onClick();
		
		history.push({
			pathname: path,
			search: search,
			state: {
				transition: transition,
				duration: duration,
			}
		});
	}

	render() {
		const { children } = this.props;

		return (
			<div className="animated-link" onClick={this.onLinkClick}>{children}</div>
		);
	}
}

AnimatedLink.defaultProps = {
	children: 'Link',
	path: '/',
	transition: 'slide-left',
	duration: 200,
	onClick: () => {}
};

AnimatedLink.propTypes = {
	children: PropTypes.node,
	path: PropTypes.string,
	search: PropTypes.string,
	transition: PropTypes.string,
	duration: PropTypes.number,
	onClick: PropTypes.func
};

export default AnimatedLink;