import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './stickyHeader.css';

class StickyHeader extends Component {
	render() {
		const { isNavigationVisible, children, isNavigationRelative, type } = this.props;
		const classnames = cx({
			'sticky-header': !isNavigationRelative && isNavigationVisible,
			'sticky-header--navigation-not-visible': !isNavigationVisible,
			'not-sticky': isNavigationRelative,
			[`sticky-header--${type}`]: type
		});

		return (
			<div className={classnames}>
				{children}
			</div>
		);
	}
}

StickyHeader.defaultProps = {
	isNavigationVisible: true
};

StickyHeader.propTypes = {
	isNavigationVisible: PropTypes.bool,
	children: PropTypes.node,
	isNavigationRelative: PropTypes.bool,
	type: PropTypes.string
};

const mapStateToProps = (state) => ({ 
	isNavigationVisible: state.navigation.isVisible,
	isNavigationRelative: state.navigation.isNavigationRelative
});

export default connect(mapStateToProps)(StickyHeader);