import React from 'react';
import PropTypes from 'prop-types';

import { Header, List, ListItem } from '../../../library/components';
import phrases from './countReview.phrases';

// class CountReview extends Component {

const CountReview = ({ countedItems, listOrder }) => {
	return (
		<div>
			<Header>{phrases.COUNTED_ITEMS}</Header>
			<List>
				{listOrder
					.map((key) => {
						const product = countedItems[key];
						if (!product) return null;

						const rightContent = (product?.units || []).map((unit, index) => {
							const prefix = index !== 0 ? ', ' : '';
							const count = unit.count === '' ? 0 : unit.count;

							return (
								<span key={index}>
									{prefix}
									{count} {unit.description}
								</span>
							);
						});

						return (
							<div className="count-page__section" key={key}>
								<ListItem rightContent={rightContent}>
									<span>{product?.name ?? ''}</span>
								</ListItem>
							</div>
						);
					})
					.filter((i) => i)}
			</List>
		</div>
	);
};

CountReview.defaultProps = {
	countedItems: {},
};

CountReview.propTypes = {
	countedItems: PropTypes.object,
	listOrder: PropTypes.array,
};

export default CountReview;
