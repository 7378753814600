import LogRocket from 'logrocket';

/**
 * @function addUsersnap
 * @description Add usersnap and append logrocket session url to snap
 */
export function addUsersnapService() {
	if (window?.onUsersnapCXLoad) return;

	if (process.env.NODE_ENV === 'production') {
		window.onUsersnapCXLoad = function (api) {
			if (!api) return;

			api.init({
				consoleRecorder: true,
			});

			api.on('open', function (event) {
				LogRocket.getSessionURL((sessionsUrl) => {
					console.info('-----------------------');
					console.info('logrocket session url -', sessionsUrl);
					console.info('-----------------------');
					event.api.setValue(
						'customData',
						JSON.stringify({
							logRocketSessionUrl: sessionsUrl,
						})
					);
				});
			});
		};

		const script = document.createElement('script');
		script.defer = 1;
		script.src =
			'https://widget.usersnap.com/global/load/5be48cea-92cf-448c-9cdd-cdfdffc0f489?onload=onUsersnapCXLoad';
		document.getElementsByTagName('head')[0].appendChild(script);
	}
}

/**
 * @function destroyUsersnap
 * @description removed the usersnap instance when we logout
 */
export function destroyUsersnap() {
	window.onUsersnapCXLoad = function (api) {
		if (!api) return;

		api.destroy();
	};
}
