import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// lodash
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import {
	updateItemCount,
	selectListItem,
	// signAreaCount,
	setCountArea,
	setSuggestedItems,
	setFetchingData,
	resetState,
	setSearch,
	clearSearch,
	setDate,
	moveEditedToCounted,
	setActiveCountArea,
	clearSelected,
} from './store/countPage.actions';
import { fetchProducts, executeRecount } from './store/countPage.service';
import { signCountArea } from './../areasList/store/areasList.actions';
import LoaderPage from '../../loaderPage/loaderPage.component';
import CountPage from './countPage.component';
import { Button, Loader } from '@dumb';
import { Navigation } from '@smart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Services
import toast from '@dumb/toast/toast';
import formatErrorMessage from '../../../services/formatErrorMessage';
import history from '../../../routerHistory';
import { Link } from 'react-router-dom';

class CountPageContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isExecutingTask: false,
		};

		this.onSign = this.onSign.bind(this);
		this.setUpCountAreaData = this.setUpCountAreaData.bind(this);
		this.onBackClick = this.onBackClick.bind(this);
		this.getSelect = this.getSelect.bind(this);
	}

	componentDidMount() {
		const {
			match,
			countAreas,
			selectedTaskDetails,
			setActiveCountArea,
		} = this.props;

		const areaId = match.params.areaId ?? 'pastCount';
		setActiveCountArea(selectedTaskDetails.taskId, areaId);

		const area = countAreas.find((x) => x.id === Number(areaId));

		this.props.setDate(selectedTaskDetails.date);
		this.setUpCountAreaData(area, selectedTaskDetails);
	}

	/**
	 * @function setUpCountAreaData
	 * @description This is used in order to setup the page data, is called in componentDidMount
	 * @param {object} area count areas name and details
	 * @param {object} selectedTaskDetails
	 */
	setUpCountAreaData(area, selectedTaskDetails) {
		const {
			setCountArea,
			setSuggestedItems,
			setFetchingData,
			selectedWorkplace,
		} = this.props;

		setFetchingData(true);

		// Sets count area thats is selected (from uri and redux)

		const countAreaPayload =
			selectedTaskDetails.status === 'executed'
				? { name: 'Count review' }
				: area;

		setCountArea(countAreaPayload);

		// if (taskId === selectedTaskDetails.taskId) {
		// 	setFetchingData(false);
		// 	return;
		// }

		// Fetch API data
		fetchProducts(selectedTaskDetails, selectedWorkplace)
			.then((items) => {
				setSuggestedItems({
					area,
					data: items.data,
					id: selectedTaskDetails.taskId,
				});

				setFetchingData(false);
			})
			.catch((error) => {
				toast.show(`Error: ${error.message}`, 'error', 3000);
				setFetchingData(false);
			});
		// });
	}

	executeRecount() {
		const {
			selectedTaskDetails,
			countedList,
			resetState,
			selectedWorkplace,
			suggestionsList,
		} = this.props;

		if (Object.keys(countedList).length === 0) {
			toast.show('You have not modified any count!', 'warning', 3000);
			return;
		}
		this.setState({ isExecutingTask: true });
		var itemsList = Object.assign({}, countedList, suggestionsList);

		executeRecount(selectedTaskDetails, itemsList, selectedWorkplace)
			.then(() => {
				this.setState({ isExecutingTask: false });
				history.push({
					pathname: '/tasks/counts/confirmation',
					state: { transition: 'slide-left', duration: 150 },
				});

				resetState();
			})
			.catch((error) => {
				this.setState({ isExecutingTask: false });
				toast.show(formatErrorMessage(error), 'error', 3000);
			});
	}

	onSign() {
		if (this.props.selectedTaskDetails.status !== 'executed') {
			// signAreaCount((this.props.taskId, this.props.areaId));
			this.props.signCountArea({
				taskId: this.props.taskId,
				areaId: this.props.areaId,
			});
			this.props.moveEditedToCounted();
			history.push({
				pathname: '/tasks/areas/overview',
				state: { transition: 'slide-left', duration: 150 },
			});
		} else {
			// this.props.moveEditedToCounted();
			this.executeRecount();
		}
	}

	onBackClick() {
		const { location, moveEditedToCounted } = this.props;
		// move last edited if needed
		moveEditedToCounted();

		const pathName = '/tasks/areas';

		const path = _get(location, 'state.from', '');
		if (path === '/tasks/areas/overview') {
			history.push({
				pathname: pathName,
				state: { transition: 'slide-right', duration: 150 },
			});
		} else {
			history.goBack();
		}
	}

	handleCountAreaChange(e) {
		history.replace({
			pathname: `/tasks/count/${e.target.value}`,
			state: { duration: 50 },
		});
	}

	getSelect() {
		const { countArea, countAreas } = this.props;

		return (
			<div className="count-page__select-wrapper">
				<select
					className="count-page__select-wrapper__select"
					value={countArea?.id}
					onChange={this.handleCountAreaChange}>
					{_isArray(countAreas) &&
						countAreas.map((entry, index) => (
							<option key={index} value={entry.id}>
								{entry.name}
							</option>
						))}
				</select>
			</div>
		);
	}

	render() {
		const {
			updateItemCount,
			suggestionsList,
			countedList,
			selectListItem,
			selectedListItem,
			countArea,
			selectedTaskDetails,
			isFetchingData,
			setSearch,
			search,
			clearSearch,
			moveEditedToCounted,
			restItems,
			clearSelected,
			listOrder,
		} = this.props;
		const { isExecutingTask } = this.state;

		return (
			<div>
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={this.getSelect()}
					rightContent={
						isExecutingTask ? (
							<Loader size="tiny" active />
						) : (
							<Button
								type="text"
								onClick={this.onSign}
								disabled={isExecutingTask}>
								Sign
							</Button>
						)
					}
				/>
				{isFetchingData ? (
					<LoaderPage text="Fetching Products..." />
				) : (
					<CountPage
						clearSearch={clearSearch}
						suggestionsList={suggestionsList}
						countedList={countedList}
						updateItemCount={updateItemCount}
						selectListItem={selectListItem}
						selectedListItem={selectedListItem}
						selectedTaskDetails={selectedTaskDetails}
						listOrder={listOrder}
						setSearch={setSearch}
						search={search}
						moveEditedToCounted={moveEditedToCounted}
						restItems={restItems}
						clearSelected={clearSelected}
					/>
				)}
			</div>
		);
	}
}

CountPageContainer.propTypes = {
	location: PropTypes.object,
	setCountArea: PropTypes.func,
	selectListItem: PropTypes.func,
	updateItemCount: PropTypes.func,
	suggestionsList: PropTypes.object,
	countedList: PropTypes.object,
	selectedListItem: PropTypes.string,
	countArea: PropTypes.object,
	selectedTaskDetails: PropTypes.object,
	selectedWorkplace: PropTypes.object,
	setSuggestedItems: PropTypes.func,

	setFetchingData: PropTypes.func,
	isFetchingData: PropTypes.bool,
	taskId: PropTypes.string,

	resetState: PropTypes.func,
	setSearch: PropTypes.func,
	clearSearch: PropTypes.func,
	search: PropTypes.string,
	setDate: PropTypes.func,
	moveEditedToCounted: PropTypes.func,
	countAreas: PropTypes.array,
	listOrder: PropTypes.array,
	restItems: PropTypes.object,
	match: PropTypes.object,
	clearSelected: PropTypes.func,
	setActiveCountArea: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateItemCount,
			selectListItem,
			setCountArea,
			setSuggestedItems,
			setFetchingData,
			resetState,
			setSearch,
			signCountArea,
			clearSearch,
			setDate,
			setActiveCountArea,
			moveEditedToCounted,
			clearSelected,
		},
		dispatch
	);
};

const mapStateToProps = (state) => {
	return {
		taskId: state.count.data.taskId,
		areaId: state.count.data.areaId,
		suggestionsList:
			state.count.data?.[state.count.data?.taskId]?.[state.count.data?.areaId]
				?.suggestions,
		countedList:
			state.count.data?.[state.count.data?.taskId]?.[state.count.data?.areaId]
				?.counted ?? {},
		itemList:
			state.count.data?.[state.count.data?.taskId]?.[state.count.data?.areaId]
				?.itemList ?? {},
		restItems:
			state.count.data?.[state.count.data?.taskId]?.[state.count.data?.areaId]
				.restItems ?? {},
		countArea: state.count.data.countArea,
		selectedListItem: state.count.ui.selectedListItem,
		selectedTaskDetails: state.storeTasks.selectedTask,
		selectedWorkplace: state.workplaces.data.selectedWorkplace,
		isFetchingData: state.count.ui.fetchingData,
		search: state.count.ui.search,
		countAreas: state.countAreas.data.countAreas,
		listOrder: state.count.ui.listOrder,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CountPageContainer);
