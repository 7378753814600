import constants from '../../../contants';
import fetchWrapper from '../../../services/fetchWrapper';
import toast from '../../../library/components/toast/toast';
import formatErrorMessage from 'services/formatErrorMessage';
import * as Sentry from '@sentry/browser';
import _isString from 'lodash/isString';
import _unionBy from 'lodash/unionBy';

/**
 * @function executeTask
 * @description Get order items
 * @param {Object} selectedTaskDetails store task details
 * @param {Object} workplace store workplace details
 * @param {Object} products - Counted items
 */
export const executeTask = (selectedTaskDetails, workplace, products) => {
	const formattedProducts = [];

	try {
		Object.keys(products).forEach((key) => {
			if (!products[key]) return;
			const product = products[key];

			product.units.forEach((unit) => {
				const count = _isString(unit.count)
					? unit.count.replace(/,/g, '.')
					: unit.count;

				formattedProducts.push({
					id: Number(unit.id),
					quantity: Number(count),
				});
			});
		});
	} catch (error) {
		Sentry.withScope((scope) => {
			scope.setExtra('error', error);
			Sentry.captureMessage('executeTask error  ', 'info');
		});
	}

	const payload = { products: { ...formattedProducts } };
	const endpoint =
		constants.API_ENDPOINT +
		`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/dates/${selectedTaskDetails.date}/execution`;

	return fetchWrapper({
		endpoint,
		method: 'POST',
		body: JSON.stringify(payload),
	})
		.then((x) => x)
		.catch((error) => {
			let errorMessage = formatErrorMessage(error);

			if (error.errors) {
				try {
					errorMessage = Object.keys(error.errors)
						.map((key) => {
							return key.substring(key.indexOf('.') + 1, key.lastIndexOf('.'));
						})
						.map((index) => formattedProducts[index].id)
						.map((productId) => {
							const allUnits = Object.values(products).reduce(
								(acc, product) => {
									
									if (!product.units) return acc;
									return _unionBy(acc.concat(acc, product.units), 'id');
									
								},
								[]
							);

							const faultyUnit = allUnits.find(({ id }) => id === productId);

							return faultyUnit
								? `${faultyUnit.productName}(${faultyUnit.unit})`
								: 'Undef';
						})
						.join(', ');

					errorMessage = `${error.message}: ${errorMessage}`;
				} catch (err) {
					errorMessage = formatErrorMessage(error);

					Sentry.withScope((scope) => {
						scope.setExtra('error', err);
						Sentry.captureMessage('Error in errormessage formatter  ', 'info');
					});
				}
			}

			toast.show(`${errorMessage}`, 'error', 10000);

			Sentry.withScope((scope) => {
				scope.setExtra('error', error);
				Sentry.captureMessage('executeTask Error ', 'info');
			});

			Sentry.captureException(error);
			throw error;
		});
};

/**
 * @function overwriteCountAreaProducts
 * @description Get order items
 * @param {number} countingAreaId store task details
 * @param {array} arrayRowProducts store task details
 * @param {bool} retry - Should we retry if something goes wrong
 */
export const overwriteCountAreaProducts = (
	countingAreaId,
	arrayRowProducts,
	retry = true
) => {
	const endpoint =
		constants.API_ENDPOINT +
		`/counting_areas/${countingAreaId}/global_raw_products`;

	return fetchWrapper({
		endpoint,
		method: 'POST',
		body: JSON.stringify({
			global_raw_products: arrayRowProducts,
		}),
	})
		.then((x) => {
			return x;
		})
		.catch((error) => {
			let errorMessage = formatErrorMessage(error);

			if (retry) {
				errorMessage = `${errorMessage}. Retrying`;
				setTimeout(() => {
					overwriteCountAreaProducts(countingAreaId, arrayRowProducts, false);
				}, 10000);
			}
			toast.show(`Error: ${errorMessage}`, 'error', 3000);

			Sentry.withScope((scope) => {
				scope.setExtra('error', error);
				Sentry.captureMessage('overwriteCountAreaProducts ', 'info');
			});

			throw error;
		});
};
