import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateOrderJustification } from './store/orderJustification.actions.js';
import OrderJustification from './orderJustification.component.jsx';
import { Button } from '@dumb';
import { Navigation } from '@smart';
import phrases from './orderJustification.phrases.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../../routerHistory';

import './orderJustification.css';

class OrderJustificationContainer extends Component {
	onSignClick() {
		history.push({
			pathname: '/tasks/order/confirmation',
			state: {transition: 'slide-left', duration: 150}
		});
	}

	onBackClick() {
		history.push({
			pathname: '/tasks/order',
			state: {transition: 'slide-right', duration: 150}
		});
	}

	render() {
		let { justification, updateOrderJustification } = this.props;
		const isValidJustification = justification.length >= 5;

		return (
			<div>
				<Navigation
					leftContent={<Button type="text" onClick={this.onBackClick}>
						<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
					</Button>}
					header={phrases.NAV_HEADER}
					rightContent={<Button type="text" onClick={this.onSignClick} disabled={!isValidJustification}>Sign</Button>}
				/>
				<OrderJustification
					justification={justification}
					updateOrderJustification={updateOrderJustification}
				/>
			</div>
		);
	}
}

OrderJustificationContainer.propTypes = {
	justification: PropTypes.string,
	updateOrderJustification: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		updateOrderJustification
	}, dispatch);
};

const mapStateToProps = (state) => ({ 
	justification: state.orderJustification.justification
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderJustificationContainer);