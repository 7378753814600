export default {
	mobile: {
		safari: '>=9',
		'android browser': '>3.10'
	},

	// or in general
	safari: '>=9',
	chrome: '>=40',
	firefox: '>=34',
	opera: '>=32'
};
