import React from 'react';
import { render } from 'react-dom';
import ToastContainer from './toast.container';
import Toast from './toast.component';

// is container rendered
let isRendered = false;

function renderToast(text, type, timeout) {
	const toast = {text, type, timeout};

	if (!isRendered) {
		mountToastContainer(toast);
	}
	
	injectToast(toast);
}

// This does not follow a React state of mind as we want toast to be called as a function rather than it being a
// component that is rendered the standard React way.
function injectToast(toast) {
	const target = document.getElementsByClassName('toast-container')[0];
	// Add div for following toast notifications to be mounted upon. This is done as ReactDOM.render doe not append
	// but replaces the entire content of an element.
	const element = document.createElement("div");
	target.prepend(element);

	render(<Toast timeout={toast.timeout} text={toast.text} type={toast.type} />, target.childNodes[0]);

	// TODO: Add way of knowing when an infinite toast is closed
	// toast.timeout !== -1 &&
	// setTimeout(() => {
	// 	target.childNodes[0].remove();
	// }, toast.timeout + 100);
}

// Mount the container to the toast notifications
function mountToastContainer(toast) {
	const target = document.getElementById('toast');
	
	render(<ToastContainer toast={toast} />, target);
	isRendered = true;
}

// TODO: Add way of knowing when an infinite toast is closed
/* Unmount React component */
// function hide() {
// 	let target = document.getElementById('toast');
// 	ReactDOM.unmountComponentAtNode(target);
// 	isRendered = false;
// }

const toast =
	{
		show: (text, type, timeout) => renderToast(text, type, timeout)
	};

export default toast;
