import { UPDATE_JUSTIFICATION } from './orderJustification.actions.js';

const initState = {
	justification: ''
};

export default (state = initState, action) => {
	switch (action.type) {
	case UPDATE_JUSTIFICATION:
		return {...state, justification: action.payload };
		
	default:
		return state;
	}
};