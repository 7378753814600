import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { setNavBarMovement } from '../navigation/store/navigation.actions';
import { bindActionCreators } from 'redux';
import { LetterSideScroll } from '@dumb';

import './letterScroll.css';

class LetterScroller extends Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.scrollRoot = document.getElementById('letter-scroller-anchor');
	}

	componentDidMount() {
		this.scrollRoot.appendChild(this.el);
	}

	componentWillUnmount() {
		this.scrollRoot.removeChild(this.el);
	}

	render() {
		let { setNavBarMovement, scrolledElementName } = this.props;
		// const style = isNavigationVisible ? {top: '90px', transition: 'top 300ms ease-in-out'}: {top: '44px', transition: 'top 300ms ease-in-out'};

		return ReactDOM.createPortal(
			<div className="letter-scroll">
				<LetterSideScroll scrolledElementName={scrolledElementName} setNavBarMovement={setNavBarMovement} />
			</div>,
			this.scrollRoot
		);
	}
}

LetterScroller.propTypes = {
	setNavBarMovement: PropTypes.func,
	scrolledElementName: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		setNavBarMovement
	}, dispatch);
};

export default connect(null, mapDispatchToProps)(LetterScroller);