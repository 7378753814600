import _isString from 'lodash/isString';
import * as Sentry from '@sentry/browser';

/**
 * @function calculateItemCounts
 * @description Calculates total counted items across different counting areas in the same count event
 * @param {Object} countData - Count data for one count
 * @return {Object}
 */
export const calculateItemCounts = ({ countData }) => {
	if (!countData[countData.taskId]) return {};

	return Object.keys(countData[countData.taskId]).reduce((acc, key) => {
		if (!countData?.[countData.taskId]?.[key]?.counted) return acc;

		const areaCount = countData[countData.taskId][key].counted;
		const countedItemKeys = Object.keys(areaCount);

		// Loop over each item that has been counted in the count area so that they counts can be counted together.
		countedItemKeys.forEach((itemKey) => {
			const item = areaCount[itemKey];

			try {
				// If the item is already added to the list of items, add the units together
				acc = {
					...acc,
					[item.id]: acc[item.id]
						? {
								...acc[item.id],
								units: item.units.map((uni, index) => {
									if (acc[item.id]?.units[index]?.id !== uni.id) return uni;

									const a = _isString(acc[item.id].units[index].count)
										? acc[item.id].units[index].count.replace(/,/g, '.')
										: acc[item.id].units[index].count;

									const b = _isString(uni.count)
										? uni.count.replace(/,/g, '.')
										: uni.count;

									return {
										...acc[item.id].units[index],
										count: Number(a) + Number(b),
									};
								}),
						  }
						: item,
				};
			} catch (error) {
				console.error(error);
				Sentry.withScope((scope) => {
					scope.setExtra('error', error);
					scope.setExtra('item', item);
					Sentry.captureMessage('calculateItemCounts error', 'info');
				});
			}
		});

		return acc;
	}, {});
};
