import React from 'react';
import PropTypes from 'prop-types';

import './emptyListView.css';

const EmptyListView = (props) => {
	return (
		<div className="empty_list">
			<div className="empty_list__image-wrapper">
				<img className="empty_list__image-wrapper__image" src="/images/joe.png" alt='' />
				<div className="empty_list__image-wrapper__text">
					{props.text}
				</div>
			</div>
		</div>
	);
};

EmptyListView.propTypes = {
	text: PropTypes.string
};

export default EmptyListView;