
export const UPATE_COUNT_AREA = 'AREA_CONFIG/UPATE_COUNT_AREA';
export const RESET_STATE = 'AREA_CONFIG/RESET_STATE';

/**
 * @function updateCountArea
 * @description Updates count areas title or category
 * @param {object} payload Object with modified params
 */
export const updateCountArea = (payload) => {
	return ({ type: UPATE_COUNT_AREA, payload });
};

/**
 * @function updateCountArea
 * @description Updates count areas title or category
 * @param {object} payload Object with modified params
 */
export const resetState = () => {
	return ({ type: RESET_STATE });
};