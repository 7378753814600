import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import LogRocket from 'logrocket';
import Fuse from 'fuse.js';
import {
	Header,
	List,
	ListItem,
	Button,
	Searchbar
} from '../../library/components';
import { StickyHeader, LetterScroll } from '@smart';
import CountButtons from './components/countButtons';
import { listTypes } from '../../services/common';
import _get from 'lodash/get';

import './receivedCount.css';

class OrderPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showLetterScroll: false,
			collapsedAddtional: true
		};

		this._getCountClassName = this._getCountClassName.bind(this);
		this.updateReceivedListItem = this.updateReceivedListItem.bind(this);
		this._renderList = this._renderList.bind(this);
		this._renderListItem = this._renderListItem.bind(this);
		this._onScroll = this._onScroll.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onSearchClear = this.onSearchClear.bind(this);
	}

	componentDidMount() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

		pageWrapper &&
			pageWrapper.addEventListener('scroll', this._onScroll, false);
	}

	componentWillUnmount() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

		pageWrapper &&
			pageWrapper.removeEventListener('scroll', this._onScroll, false);
	}

	_onScroll() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];
		const receivedList = document.getElementsByClassName('receivedList')[0];

		if (
			!this.state.showLetterScroll &&
			receivedList &&
			receivedList.offsetTop <= pageWrapper.scrollTop + 90 &&
			receivedList.clientHeight + receivedList.offsetTop + 30 >=
				pageWrapper.scrollTop + window.innerHeight
		) {
			this.setState(() => ({ showLetterScroll: true }));
		} else if (
			this.state.showLetterScroll &&
			receivedList.offsetTop > pageWrapper.scrollTop + 90
		) {
			this.setState(() => ({ showLetterScroll: false }));
		} else if (
			this.state.showLetterScroll &&
			receivedList.clientHeight + receivedList.offsetTop + 30 <=
				pageWrapper.scrollTop + window.innerHeight
		) {
			this.setState(() => ({ showLetterScroll: false }));
		}
	}

	showActionButtons(event, listItemStatus, index) {
		const { selectedListItem, selectListItem } = this.props;

		event.stopPropagation();

		const expandedListItem =
			selectedListItem !== `${listItemStatus}-${index}`
				? `${listItemStatus}-${index}`
				: '';

		selectListItem(expandedListItem);
	}

	_getUpdateTypeValue(type, item, value) {
		const { receivedDataList } = this.props;

		const count = _get(receivedDataList, `[${item.id}].count`, 0);
		const isAdjusted = count !== item.count;

		switch (type) {
		case 'decrement':
			if (item.count === 0) return;
			return { count: item.count - 1 };

		case 'increment':
			return { count: item.count + 1 };

		case 'set':
			return { count: Number(value) };

		default:
			return { confirmed: true, adjusted: isAdjusted };
		}
	}

	updateReceivedListItem(item, type, status, value) {
		const { updateItemCount, taskDetails } = this.props;
		const updatedValue = this._getUpdateTypeValue(type, item, value);

		if (updatedValue) {
			updateItemCount(item.id, updatedValue, status, type, taskDetails);
			LogRocket.track(`Receiving ${updatedValue.count} ${item.name} `);
		}
	}

	_getCountClassName(product) {
		const { receivedDataList } = this.props;

		if (
			receivedDataList[product.id] &&
			receivedDataList[product.id].count > product.count
		)
			return 'order-page__count--lesser';
		if (
			receivedDataList[product.id] &&
			receivedDataList[product.id].count < product.count
		)
			return 'order-page__count--greater';

		return 'order-page__count';
	}

	_renderListItem(product, key, countClassName, listStatus, itemId) {
		const { selectedListItem } = this.props;

		const rightContent = (
			<span className={countClassName}>
				( {product.count} {product.unit} )
			</span>
		);
		const isToggled = `${listStatus}-${key}` === selectedListItem;

		// This should be handled by animation package
		const classnamesToggle = cx({
			[`is-open`]: isToggled
		});

		return (
			<div className={classnamesToggle} id={itemId} key={key}>
				<ListItem
					onClick={event => this.showActionButtons(event, listStatus, key)}
					rightContent={rightContent}
				>
					<div className="received-page__list__name-content">
						<span>{product.name}</span>
						<span className="received-page__list__name-content__description">{product.description}</span>
					</div>
				</ListItem>
				{isToggled && (
					<CountButtons
						updateCount={this.updateReceivedListItem}
						listStatus={listStatus}
						product={product}
					/>
				)}
			</div>
		);
	}

	_toggleAdditionalList() {
		this.setState(() => ({ collapsedAddtional: false }));
	}

	onSearch(e) {
		const term = e.target.value;
		this.props.setSearch(term);
	}

	onSearchClear() {
		this.props.clearSearch();
	}

	_renderList(list, header, listStatus, listKeys, isCollapsed = false) {
		const { search } = this.props;

		if (!listKeys) listKeys = Object.keys(list);
		if (!Object.keys(list).length) return;

		const lastLetter = '';
		let itemModifier = false;

		// For loop is used to continue on products that are not in the ordered list

		let itemList = listKeys
			.map(key => {
				const product = list[key];
				if (!product) return null;

				product.itemIdName = '';
				product.lastLetter = lastLetter;
				product.countClassName = this._getCountClassName(product, key, list);

				if (
					listStatus === listTypes.RECEIVED_LIST &&
					product.name.charAt(0) !== lastLetter
				) {
					product.product = product.lastLetter = product.name.charAt(0);
					product.itemIdName = `${product.name.charAt(0)}-item`;
					itemModifier = this.state.showLetterScroll;
				}

				return product;
			})
			.filter(i => i);

		// Search
		if (search.length > 1) {
			var options = {
				keys: ['name'],
				threshold: 0.3
			};
			const fuse = new Fuse(itemList, options);
			itemList = fuse.search(search);
		}

		return (
			<div className={listStatus} key={listStatus}>
				<CSSTransition
					key="navbar"
					in={this.state.showLetterScroll}
					classNames="letter-scroll-slide"
					timeout={{ enter: 250, exit: 250 }}
					unmountOnExit
				>
					<LetterScroll scrolledElementName="receivedList" />
				</CSSTransition>

				<StickyHeader type="secondary">
					<Header sticky>{header}</Header>
				</StickyHeader>

				{isCollapsed ? (
					<div className="order-page__collapsable">
						<Button onClick={() => this._toggleAdditionalList()} type="pink">
							Show Additional Items...
						</Button>
					</div>
				) : // <List smallItem={itemModifier}>{itemsList}</List>
					itemList.length ? (
						<List smallItem={itemModifier}>
							{itemList.length
								? itemList.map((item, index) =>
									this._renderListItem(
										item,
										index,
										item.countClassName,
										listStatus,
										item.itemIdName
									)
							  )
								: null}
						</List>
					) : null}
			</div>
		);
	}

	render() {
		const {
			confirmedList,
			receivedList,
			adjustedList,
			confirmedListOrder,
			receivedListOrder,
			adjustedListOrder,
			additionalItemsOrder,
			additionalItems,
			search
		} = this.props;

		return (
			<div className="received-page">
				<StickyHeader>
					<Searchbar
						value={search}
						onChange={this.onSearch}
						onClear={this.onSearchClear}
					/>
				</StickyHeader>

				{[
					this._renderList(
						confirmedList,
						'Confirmed',
						'confirmedList',
						confirmedListOrder
					),

					this._renderList(
						adjustedList,
						'Adjusted',
						'adjustedList',
						adjustedListOrder
					),

					this._renderList(
						receivedList,
						'Received',
						'receivedList',
						receivedListOrder
					),

					this._renderList(
						additionalItems,
						'Additional',
						'additionalItems',
						additionalItemsOrder,
						this.state.collapsedAddtional
					)
				]}
			</div>
		);
	}
}

OrderPage.propTypes = {
	receivedList: PropTypes.object,
	adjustedList: PropTypes.object,
	confirmedList: PropTypes.object,
	receivedDataList: PropTypes.object,
	confirmedListOrder: PropTypes.array,
	receivedListOrder: PropTypes.array,
	adjustedListOrder: PropTypes.array,
	selectedListItem: PropTypes.string,
	selectListItem: PropTypes.func,
	updateItemCount: PropTypes.func,
	taskDetails: PropTypes.object,
	additionalItemsOrder: PropTypes.array,
	additionalItems: PropTypes.object,
	search: PropTypes.string,
	setSearch: PropTypes.func,
	clearSearch: PropTypes.func
};

export default OrderPage;
