

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import LogRocket from 'logrocket';

import { Button } from '../../../library/components';

import './countButtons.css';

class CountButtons extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputToggled: false
		};
	}

	expandInput() {
		this.setState((prevState) => ({ inputToggled: !prevState.inputToggled }));
	}

	setCount(value) {
		const { product, listStatus, updateCount } = this.props;

		updateCount(product, 'set', listStatus, value);

		LogRocket.track(`Received ${this.props.product.name}`);
	}

	render() {
		const { listStatus, product, updateCount } = this.props;
		const { inputToggled } = this.state;

		const classnames = cx(
			"count-buttons__button",
			"count-buttons__button--small",
			{
				'button-toggled': inputToggled
			}
		);

		return (
			<div className='count-buttons'>
				<Button className={classnames} onClick={() => this.expandInput()} type='alternative' group>
					<FontAwesomeIcon icon={['far', 'keyboard']} size="1x" />
				</Button>
				{inputToggled ?
					<Fragment>
						<input className="count-buttons__input" onChange={(event) => this.setCount(event.target.value)} type='number' placeholder="Type here!" />
					</Fragment>
					:
					<Fragment>
						<Button className="count-buttons__button" onClick={() => updateCount(product, 'decrement', listStatus)} type='alternative' group>-</Button>
						<Button className="count-buttons__button" onClick={() => updateCount(product, 'increment', listStatus)} type='alternative' group>+</Button>
					</Fragment>
				}
				{!product.confirmed &&
					<Button className="count-buttons__button" onClick={() => updateCount(product, 'confirm', listStatus)} type='alternative' group>✔</Button>
				}
			</div>
		);
	}
};

CountButtons.propTypes = {
	listStatus: PropTypes.string,
	product: PropTypes.object,
	updateCount: PropTypes.func
};

export default CountButtons;