import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { calculateItemCounts } from 'services/calculateItemCounts';

import CountReview from './countReview.component.jsx';
import { Button, Loader } from '../../../library/components';
import { Navigation } from '@smart';
import history from '../../../routerHistory.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import phrases from './countReview.phrases';

class CountReviewContainer extends Component {
	constructor(props) {
		super(props);

		this.onSign = this.onSign.bind(this);
	}

	onSign() {
		history.push({
			pathname: '/tasks/counts/review-zero',
			state: { transition: 'slide-left', duration: 150 },
		});
	}

	onBackClick() {
		history.push({
			pathname: '/tasks/areas/overview',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	render() {
		const {
			countedItems,
			listOrder,
			iscountOverViewLoader,
		} = this.props;

		return (
			<div>
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={phrases.COUNT_REVIEW}
					rightContent={
						iscountOverViewLoader ? (
							<Loader size="tiny" active />
						) : (
							<Button type="text" onClick={this.onSign}>
								{phrases.NEXT}
							</Button>
						)
					}
				/>
				<CountReview
					onClick={this.goBackToAreas}
					countedItems={countedItems}
					listOrder={listOrder}
				/>
			</div>
		);
	}
}

CountReviewContainer.propTypes = {
	countedItems: PropTypes.object,
	listOrder: PropTypes.array,
	iscountOverViewLoader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	countedItems: calculateItemCounts({
		countData: state.count.data,
	}),
	listOrder: state.count.ui.listOrder,
	iscountOverViewLoader: state.countReview.iscountOverViewLoader,
});

export default connect(
	mapStateToProps
)(CountReviewContainer);
