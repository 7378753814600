import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import toast from '@dumb/toast/toast';
import {
	fetchWorkplaces,
	setWorkplaces,
	selectWorkplace,
	toggleLoader,
} from './store/workplaceSelection.actions.js';
import LoaderPage from '../loaderPage/loaderPage.component.jsx';
import WorkplaceSelection from './workplaceSelection.component.jsx';
import { Button } from '@dumb';
import { Navigation } from '@smart';
import phrases from './workplaceSelection.phrases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../routerHistory';
import { event, set } from '@tools/tracking/analytics.service';

import './workplaceSelection.css';

class WorkplaceSelectionContainer extends Component {
	constructor(props) {
		super(props);

		this.onWorkplaceSelected = this.onWorkplaceSelected.bind(this);
	}

	componentDidMount() {
		const {
			setWorkplaces,
			selectWorkplace,
			selectedWorkplace,
			toggleLoader,
		} = this.props;

		if (selectedWorkplace) {
			selectWorkplace(selectedWorkplace);
			set({ countedWorkplace: selectedWorkplace.id });
			set({ dimension1: selectedWorkplace.id });
			event({
				category: 'Storetask',
				action: 'Select workplace',
				label: `Selected workplace ${selectedWorkplace.id}`,
				value: selectedWorkplace.id,
			});
		}

		toggleLoader(true);
		fetchWorkplaces()
			.then((workplaces) => {
				setWorkplaces(workplaces.data);
				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				toast.show(`Error: ${error.message}`, 'error', 3000);
			});
	}

	onBackClick() {
		history.push({
			pathname: '/tasks',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	onWorkplaceSelected(workplace) {
		const { selectWorkplace } = this.props;

		selectWorkplace(workplace);
		history.push({
			pathname: '/tasks',
			state: { transition: 'slide-left', duration: 150 },
		});
		set({ countedWorkplace: workplace.id });
		set({ dimension1: workplace.id });
		event({
			category: 'Storetask',
			action: 'Select workplace',
			label: `Selected workplace ${workplace.id}`,
			value: workplace.id,
		});
	}

	render() {
		const {
			workplaces,
			selectedWorkplace,
			loading,
			workplaceOrder,
		} = this.props;
		const leftContent = selectedWorkplace ? (
			<Button type="text" onClick={this.onBackClick}>
				<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
			</Button>
		) : null;

		return (
			<div>
				<Navigation leftContent={leftContent} header={phrases.NAV_HEADER} />
				{loading ? (
					<LoaderPage text="Fetching Your Workplaces..." />
				) : (
					<WorkplaceSelection
						workplaces={workplaces}
						onWorkplaceSelected={this.onWorkplaceSelected}
						selectedWorkplace={selectedWorkplace}
						workplaceOrder={workplaceOrder}
					/>
				)}
			</div>
		);
	}
}

WorkplaceSelectionContainer.propTypes = {
	workplaces: PropTypes.array,
	setWorkplaces: PropTypes.func,
	selectWorkplace: PropTypes.func,
	toggleLoader: PropTypes.func,
	selectedWorkplace: PropTypes.object,
	loading: PropTypes.bool,
	workplaceOrder: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setWorkplaces,
			selectWorkplace,
			toggleLoader,
		},
		dispatch
	);
};

const mapStateToProps = (state) => ({
	workplaces: state.workplaces.data.workplaces,
	selectedWorkplace: state.workplaces.data.selectedWorkplace,
	loading: state.workplaces.ui.loading,
	workplaceOrder: state.workplaces.ui.workplaceOrder,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WorkplaceSelectionContainer);
