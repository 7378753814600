import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './listItem.css';

class ListItem extends Component {
	render() {
		const { children, className, rightContent, onClick, selected, fullWidth } = this.props;

		const classnames = cx('list-item', {
			[`${className}`]: className,
			'list-item--selected': selected,
			'list-item--full-width': fullWidth
		});
		
		return (
			<div className={classnames} onClick={onClick}>
				{children}
				<div className="right-content">{rightContent}</div>
			</div>
		);
	}
}

ListItem.propTypes = {
	children: PropTypes.node,
	rightContent: PropTypes.node,
	className: PropTypes.string,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
	fullWidth: PropTypes.bool
};

export default ListItem;