import React, { Component } from 'react';
import phrases from './confirmationPage.phrases.js';
import PropTypes from 'prop-types';

import { AnimatedLink, Button } from '../../library/components';

class ConfirmationPage extends Component {
	render() {
		const { text } = this.props;

		return (
			<div>
				<div className="confirmation-page">
					<img className="confirmation-page__image" src="/images/name-logo.png" alt='' />
					<div className="confirmation-page__text">{text}</div>
					{/* <div className="confirmation-page__text">{note}</div> */}
				</div>
				<div className='button-container'>
					<AnimatedLink path='/tasks'>
						<Button size='full' type='success'>{phrases.DONE}</Button>
					</AnimatedLink>
				</div>
			</div>
		);
	}
}


ConfirmationPage.propTypes = {
	text: PropTypes.string,
	// note: PropTypes.string
};

export default ConfirmationPage;