import React, { Component } from 'react';

import './toast.css';

class ToastContainer extends Component {

	render() {
		return (
			<div className="toast-container" />
		);
	}
}
export default ToastContainer;