import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { List, ListItem, EmptyListView} from '../../library/components';

class WorkplaceSelection extends Component {
	render() {
		let { workplaces, onWorkplaceSelected, selectedWorkplace, workplaceOrder } = this.props;

		return (
			<div className='areas-list'>
				{ workplaces && workplaces.length !== 0 ?
					<Fragment>
						<List>
							{
								workplaceOrder.map((key) => {
									const rightContent = selectedWorkplace && selectedWorkplace.id === workplaces[key].id ? <FontAwesomeIcon icon={['far', 'check-circle']} size="lg" /> : null;

									return (
										<div key={workplaces[key].id}>
											<ListItem onClick={() => onWorkplaceSelected(workplaces[key])} rightContent={rightContent}>
												{workplaces[key].name}
											</ListItem>
										</div>
									);
								})
							}
						</List>
					</Fragment>
					:
					<EmptyListView text="You have no workplaces" />
				}
			</div>
		);
	}
}

WorkplaceSelection.propTypes = {
	workplaces: PropTypes.array,
	onWorkplaceSelected: PropTypes.func,
	selectedWorkplace: PropTypes.object,
	workplaceOrder: PropTypes.array
};

export default WorkplaceSelection;