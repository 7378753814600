import React from 'react';

// components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@dumb';

import './leftContent.css';

const LeftContent = () => {
	return (
		<div className="areas-left">
			<Button className="areas-left__edit">
				<FontAwesomeIcon size="1px" icon={faEdit} />
			</Button>
		</div>
	);
};

export default LeftContent;
