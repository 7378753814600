/* eslint-disable */
import React, { Component } from 'react';

class Arrow extends Component {
	render() {
		return (
			<svg width="105px" height="171px" viewBox="0 0 105 181" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
				<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
					<g id="iPhone-8-Copy-3" transform="translate(-230.000000, -90.000000)" stroke="#555555" strokeWidth="2">
							<path d="M326.996786,94.946129 C323.629456,167.216498 312.307778,215.144062 293.031754,238.728821 C273.75573,262.31358 253.245145,271.783769 231.5,267.139389" id="Line-3"></path>
							<path d="M316.5,109.5 L327.5,92.5" id="Line"></path>
							<path d="M333.5,110.5 L328,92" id="Line"></path>
					</g>
				</g>
			</svg>
		);
	}
};
/* eslint-enable */

export default Arrow;