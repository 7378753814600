import {
	SET_EXECUTING_LOADER
} from './countReview.actions.js';

const initState = {
	countedItems: {},
	countedItemsOrder: [],
	isExecutingTask: false
};

export default (state = initState, action) => {
	switch (action.type) {

	case SET_EXECUTING_LOADER:
		return { ...state, isExecutingTask: action.payload };

	default:
		return state;
	}
};
