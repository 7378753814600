import constants from 'contants.js';
import fetchWrapper from 'services/fetchWrapper';
import formatErrorMessage from 'services/formatErrorMessage';
import * as Sentry from '@sentry/browser';
import toast from 'library/components/toast/toast';
import _isString from 'lodash/isString';
export const SET_RECEIVED_ITEMS = 'RECEIVED/SET_RECEIVED_ITEMS';
export const UPDATE_RECEIVED_ITEM = 'RECEIVED/UPDATE_RECEIVED_ITEM';
export const RESET_STATE = 'RECEIVED/RESET_STATE';
export const SET_FETCHING_DATA = 'RECEIVED/SET_FETCHING_DATA';
export const LIST_ITEM_SELECTED = 'RECEIVED/LIST_ITEM_SELECTED';
export const EXECUTING_TASK = 'RECEIVED/EXECUTING_TASK';
export const SET_ACTIVE_RECEIVE_TASK = 'RECEIVED/SET_ACTIVE_RECEIVE_TASK';
export const SELECT_LIST_ITEM = 'RECEIVED/SELECT_LIST_ITEM';
export const UPDATE_ITEM_COUNT = 'RECEIVED/UPDATE_ITEM_COUNT';
export const SET_SEARCH = 'RECEIVED/SET_SEARCH';
export const CLEAR_SEARCH = 'RECEIVED/CLEAR_SEARCH';

// ****************
// DATA FETCHES
// ****************

/**
 * @function fetchOrderItems
 * @description Get order items
 * @param {string} payload store task details
 */
export const fetchReceivedItems = (selectedTaskDetails, workplace) => {
	if (selectedTaskDetails.status === 'planned')
		return fetchWrapper({
			endpoint:
				constants.API_ENDPOINT +
				`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/products?date=${selectedTaskDetails.date}`,
		});
	else
		return fetchWrapper({
			endpoint:
				constants.API_ENDPOINT +
				`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/executions/${selectedTaskDetails.executedEventId}`,
		});
};

/**
 * @function executeTask
 * @description Get order items
 * @param {string} payload store task details
 */
export const executeTask = (selectedTaskDetails, products, workplace) => {
	const formattedProducts = Object.keys(products).map((key) => {
		const product = products[key];

		const count = _isString(product.count)
			? product.count.replace(/,/g, '.')
			: product.count;

		return {
			id: Number(product.id),
			quantity: Number(count),
		};
	});

	const payload = { products: formattedProducts };

	return fetchWrapper({
		endpoint:
			constants.API_ENDPOINT +
			`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/dates/${selectedTaskDetails.date}/execution`,
		method: 'POST',
		body: JSON.stringify(payload),
	}).catch((error) => {
		let errorMessage = formatErrorMessage(error);

		if (error.errors) {
			errorMessage = Object.keys(error.errors)
				.map((key) => {
					return key.substring(key.indexOf('.') + 1, key.lastIndexOf('.'));
				})
				.map((index) => formattedProducts[index].id)
				.map((productId) => products[productId].name)
				.join(', ');

			errorMessage = `${error.message}: ${errorMessage}`;
		}

		Sentry.withScope((scope) => {
			scope.setExtra('error', error);
			Sentry.captureMessage('Received executeTask Error ', 'info');
		});

		Sentry.captureException(error);
		toast.show(`${errorMessage}`, 'error', 3000);
		throw error;
	});
};

// ********************
// EXPORTED ACTIONS
// ********************

/**
 * @function updateItemCount
 * @description Sets the loader status
 * @param {string} payload is loading
 */
export const updateItemCount = (
	itemId,
	updatedValue,
	listStatus,
	actionType,
	taskDetails
) => {
	return {
		type: UPDATE_ITEM_COUNT,
		itemId,
		updatedValue,
		listStatus,
		actionType,
		taskDetails,
	};
};

/**
 * @function setSearch
 * @description Sets search term
 * @param {string} payload
 */
export const setSearch = (payload) => {
	return { type: SET_SEARCH, payload };
};

/**
 * @function clearSearch
 * @description Clear search term
 */
export const clearSearch = () => {
	return { type: CLEAR_SEARCH };
};

/**
 * @function setFetchingData
 * @description Sets the loader status
 * @param {string} payload is loading
 */
export const setFetchingData = (payload) => {
	return { type: SET_FETCHING_DATA, payload };
};

/**
 * @function setReceievedItems
 * @description Set order data fetched from local storage
 * @param {string} payload object of order lists
 */
export const setReceievedItems = (payload, executed) => {
	return { type: SET_RECEIVED_ITEMS, payload, executed };
};

/**
 * @function resetState
 * @description Resets to initial state
 */
export const resetState = () => {
	return { type: RESET_STATE };
};

export const selectListItem = (payload) => {
	return { type: SELECT_LIST_ITEM, payload };
};
export const setActiveReceiveTask = (payload) => {
	return { type: SET_ACTIVE_RECEIVE_TASK, payload };
};
