import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './message.css';

const Message = props => {
	const { children, className } = props;

	const classname = cx('message', {
		[`${className}`]: className
	});

	return (
		<p className={classname}>{children}</p>
	);
};

Message.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string
};

export default Message;
