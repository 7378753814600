export const SET_USER = 'AUTH/SET_USER';
export const SET_ACCESS_TOKEN = 'AUTH/SET_ACCESS_TOKEN';

export const setUser = (user) => ({
	type: SET_USER,
	payload: user,
});

export const setAccessToken = (accessToken) => ({
	type: SET_ACCESS_TOKEN,
	payload: accessToken,
});
