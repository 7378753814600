import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './button.css';

export default class Button extends PureComponent {

	onClick(e) {
		const { onClick } = this.props;
		e.preventDefault();
		onClick(e);
	}

	render() {
		const { children, className, size, type, group, disabled } = this.props;

		const classnames = cx('button', {
			[`${className}`]: className,
			[`button--${size}`]: size,
			[`button--${type}`]: type,
			[`button--group`]: group,
		});

		return (
			// Add empty onTouchStart="" to execute :active css animation on mobile
			<button onTouchStart={() => {}} className={classnames} onClick={(e) => this.onClick(e)} disabled={disabled}>
				{children}
			</button>
		);
	}
}

Button.defaultProps = {
	group: false,
	type: 'default',
	size: 'small',
	onClick: () => {}
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	type: PropTypes.string,
	size: PropTypes.string,
	group: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool
};
