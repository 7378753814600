import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './header.css';

class Header extends Component {
	render() { 
		const { children, modifierClassName } = this.props;

		const classname = cx('header', {
			[`${modifierClassName}`]: modifierClassName
		});

		return (
			<h2 className={classname} >{children}</h2>
		);
	}
}

Header.propTypes = {
	children: PropTypes.string.isRequired,
	modifierClassName: PropTypes.string
};

export default Header;