import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Bowser from 'bowser';
import browserList from '../supportedBrowsers';
import { store, setBrowserValidity } from '../store.js';

class PageWrapper extends PureComponent {
	componentDidMount() {
		const browser = Bowser.getParser(window.navigator.userAgent);
		const isValidBrowser = browser.satisfies(browserList);
		store.dispatch(setBrowserValidity({ validity: isValidBrowser }));
	}

	render() {
		return this.props.render(this.props);
	}
}

PageWrapper.propTypes = {
	render: PropTypes.func.isRequired,
};

export default withRouter(PageWrapper);
