import { store } from '../../../store.js';
import constants from '../../../contants.js';
import fetchWrapper from '../../../services/fetchWrapper';

import { setCountAreas } from '../../count/areasList/store/areasList.actions';
import toast from '@dumb/toast/toast';

/**
 * @function fetchStoreTasks
 * @description Fetches store tasks from API
 * @param {Object} dates takes an object of date range.
 */
export const fetchStoreTasks = (dates, workplaceId) => {
	return fetchWrapper({
		endpoint:
			constants.API_ENDPOINT +
			`/workplaces/${workplaceId}/store_tasks?from=${dates.from}&to=${dates.to}`,
	}).catch((error) => {
		toast.show(`Error: ${error.message}`, 'error', 3000);
		throw error;
	});
};

/**
 * @function fetchCountAreas
 * @description fetch count areas from api
 */
export const fetchCountAreas = (workplaceId) => {
	const endpoint = `${constants.API_ENDPOINT}/workplaces/${workplaceId}/counting_areas/`;
	return fetchWrapper({
		endpoint,
		method: 'get',
	})
		.then((res) => {
			// Save to local storage

			// Actually store to redux of areaList page
			store.dispatch(setCountAreas(res.data));

			return res;
		})
		.catch((error) => {
			toast.show(`Error: ${error.message}`, 'error', 3000);
			throw error;
		});
};
