import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import {
	toggleNavigation,
	setNavBarVisibility,
	setNavBarRelative
} from './store/navigation.actions';
import { Navbar, Navigation } from '@dumb';

import './navigation.css';

class NavbarContainer extends Component {
	constructor(props) {
		super(props);

		// Navbar on scroll hide variables
		this.previousY = 0;
		this.previousIndexedY = 0;
		this.onScroll = this.onScroll.bind(this);

		// Navigation
		this.toggleNavigation = this.toggleNavigation.bind(this);
	}

	componentDidMount() {
		let { setNavBarVisibility } = this.props;
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];
		setNavBarVisibility(true);
		pageWrapper.addEventListener('scroll', this.onScroll);
	}

	componentWillUnmount() {
		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

		pageWrapper.removeEventListener('scroll', this.onScroll);
	}

	onScroll() {
		const {
			setNavBarVisibility,
			isNavbarVisible,
			setNavBarRelative,
			disableMovement
		} = this.props;

		if (disableMovement) return;

		const pageWrapper = document.getElementsByClassName('page-wrapper')[0];
		const difference = pageWrapper.scrollTop - this.previousIndexedY;

		if (this.previousY - pageWrapper.scrollTop > 0) {
			this.previousIndexedY = pageWrapper.scrollTop;
		}

		if (difference < 0 && isNavbarVisible) return;
		// Scroll down hide the navbar
		if (difference > 44 && pageWrapper.scrollTop > 44 && isNavbarVisible) {
			setNavBarVisibility(false);
			setNavBarRelative(false);
		}
		// Scroll top and nav bar is visible make position absolute, for animation
		else if (pageWrapper.scrollTop <= 50 && isNavbarVisible) {
			setNavBarRelative(false);
		}
		// Scroll to top show navbar and make position relative
		else if (pageWrapper.scrollTop <= 50 && !isNavbarVisible) {
			setNavBarVisibility(true);
			setNavBarRelative(true);
		}
		// Scroll up show navbar
		else if (
			(difference < -7 || pageWrapper.scrollTop <= 50) &&
			!isNavbarVisible &&
			pageWrapper.scrollTop + window.innerHeight <= pageWrapper.scrollHeight
		) {
			setNavBarVisibility(true);
			setNavBarRelative(false);
		}

		this.previousY = pageWrapper.scrollTop;
	}

	toggleNavigation = (isToggled = !this.props.isNavigationToggled) => {
		let { toggleNavigation } = this.props;

		toggleNavigation(isToggled);
	};

	render() {
		const {
			isNavigationToggled,
			leftContent,
			header,
			rightContent,
			isNavbarVisible,
			isNavigationRelative
		} = this.props;

		return (
			<Fragment>
				<div className="navigation-container">
					{isNavigationRelative ? (
						<div className="navigation-container__relative-postion-header">
							<Navbar
								leftContent={leftContent}
								header={header}
								rightContent={rightContent}
								relative={isNavigationRelative}
							/>
						</div>
					) : (
						<CSSTransition
							key="navbar"
							in={isNavbarVisible}
							classNames="slide-up"
							timeout={{ enter: 250, exit: 250 }}
							unmountOnExit>
							<Navbar
								leftContent={leftContent}
								header={header}
								rightContent={rightContent}
							/>
						</CSSTransition>
					)}
					{!isNavbarVisible && (
						<div className="navigation-container__top-bar" />
					)}
				</div>
				<CSSTransition
					key="navigation"
					in={isNavigationToggled}
					classNames="navigation-slide"
					timeout={{ enter: 100, exit: 100 }}
					unmountOnExit>
					<Navigation
						isOpen={isNavigationToggled}
						toggleNavigation={() => this.toggleNavigation(false)}
					/>
				</CSSTransition>
			</Fragment>
		);
	}
}

NavbarContainer.propTypes = {
	leftContent: PropTypes.node,
	header: PropTypes.node,
	rightContent: PropTypes.node,
	toggleNavigation: PropTypes.func,
	isNavigationToggled: PropTypes.bool,
	setNavBarVisibility: PropTypes.func,
	isNavbarVisible: PropTypes.bool,
	isNavigationRelative: PropTypes.bool,
	setNavBarRelative: PropTypes.func,
	disableMovement: PropTypes.bool
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleNavigation,
			setNavBarVisibility,
			setNavBarRelative
		},
		dispatch
	);
};

const mapStateToProps = state => ({
	isNavigationToggled: state.navigation.isNavigationToggled,
	isNavbarVisible: state.navigation.isVisible,
	isNavigationRelative: state.navigation.isNavigationRelative,
	disableMovement: state.navigation.isMovementDisabled
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NavbarContainer);
