import {
	UPATE_COUNT_AREA,
	RESET_STATE
} from './areaConfiguration.actions';

const initState = {
	areaDetails: {
		name: '',
		store: null
	}
};

export default (state = initState, action) => {
	switch (action.type) {
	case UPATE_COUNT_AREA:
		return {
			...state,
			areaDetails: { ...state.areaDetails, ...action.payload }
		};

	case RESET_STATE:
		return { ...initState };

	default:
		return state;
	}
};
