import { store } from '../../../../store.js';
import {
	deleteCountArea as deleteArea,
	addCountArea as addArea,
	editCountArea as editArea,
	toggleAreaLoader,
} from './areasList.actions';
import fetchWrapper from '../../../../services/fetchWrapper';
import constants from '../../../../contants';
import toast from '../../../../library/components/toast/toast';

// lodash
import _isEmpty from 'lodash/isEmpty';

/**
 * @function deleteCountArea
 * @description fetch count areas from local storage
 */
export const deleteCountArea = (countAreaId) => {
	const endpoint = `${constants.API_ENDPOINT}/counting_areas/${countAreaId}`;
	store.dispatch(toggleAreaLoader(countAreaId));

	return fetchWrapper({
		endpoint,
		method: 'delete',
	})
		.then(() => {
			store.dispatch(deleteArea(countAreaId));
			store.dispatch(toggleAreaLoader(countAreaId));
		})
		.catch((error) => {
			store.dispatch(toggleAreaLoader(countAreaId));
			toast.show(`Error: ${error.message}`, 'error', 3000);
		});
};

/**
 * @function addCountArea
 * @description fetch count areas from local storage
 */
export const addCountArea = (payload) => {
	const endpoint = `${constants.API_ENDPOINT}/counting_areas`;

	return fetchWrapper({
		endpoint,
		method: 'post',
		body: JSON.stringify(payload),
	})
		.then((res) => {
			store.dispatch(addArea(res.data[0]));
			return res.data[0];
		})
		.catch((error) => {
			toast.show(`Error: ${error.message}`, 'error', 3000);
			throw error;
		});
};

/**
 * @function editCountArea
 * @description edits a counting area
 */
export const editCountArea = (payload) => {
	const endpoint = `${constants.API_ENDPOINT}/counting_areas/${payload.id}`;

	return fetchWrapper({
		endpoint,
		method: 'post',
		body: JSON.stringify(payload),
	})
		.then((res) => {
			if (_isEmpty(res.data)) return;

			store.dispatch(editArea(res.data[0]));
			return res.data[0];
		})
		.catch((error) => {
			toast.show(`Error: ${error.message}`, 'error', 3000);
			throw error;
		});
};

/**
 * @function executeZeroCount
 * @description fetch count areas from local storage
 */
export const executeZeroCount = (selectedTaskDetails, workplace) => {
	const endpoint =
		constants.API_ENDPOINT +
		`/workplaces/${workplace.id}/planned_tasks/${selectedTaskDetails.taskId}/dates/${selectedTaskDetails.date}/execution`;

	return fetchWrapper({
		endpoint,
		method: 'POST',
	});
};
