import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	setFetchingData,
	fetchReceivedItems,
	setReceievedItems,
	executeTask,
	setActiveReceiveTask,
	resetState,
	selectListItem,
	updateItemCount,
	clearSearch,
	setSearch,
} from './store/receivedCount.actions.js';
import LoaderPage from '../loaderPage/loaderPage.component.jsx';
import ReceivedCount from './receivedCount.component.jsx';
import { Button, Loader } from '@dumb';
import { Navigation } from '@smart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { getSelectedTaskData } from '../storeTasks/store/storeTasks.services';
import history from '../../routerHistory';
import toast from '../../library/components/toast/toast';

class OrderPageContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			executing: false,
		};

		this.onSignClick = this.onSignClick.bind(this);
	}

	onSignClick() {
		let {
			confirmedList,
			adjustedList,
			selectedTaskDetails,
			selectedWorkplace,
		} = this.props;
		const finalList = Object.assign({}, confirmedList, adjustedList);
		this.setState({ executing: true });
		executeTask(selectedTaskDetails, finalList, selectedWorkplace)
			.then(() => {
				this.setState(() => ({ executinresetStateg: false }));
				history.push({
					pathname: '/tasks/order/confirmation',
					state: { transition: 'slide-right', duration: 150 },
				});
			})
			.catch((error) => {
				this.setState(() => ({ executing: false }));
				toast.show(`Error: ${error.message}`, 'error', 3000);
			});
	}

	onBackClick() {
		history.push({
			pathname: '/tasks',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	componentDidMount() {
		const {
			setReceievedItems,
			setFetchingData,
			selectedWorkplace,
			setActiveReceiveTask,
			selectedTaskDetails,
		} = this.props;

		setActiveReceiveTask(selectedTaskDetails.taskId);

		setFetchingData(true);

		fetchReceivedItems(selectedTaskDetails, selectedWorkplace)
			.then((receivedList) => {
				setFetchingData(false);
				setReceievedItems(receivedList.data);
			})
			.catch((error) => {
				setFetchingData(false);
				toast.show(`Error: ${error.message}`, 'error', 3000);
			});
	}

	checkIfAllOrderReviewed(notConfirmedList) {
		if (Object.keys(notConfirmedList).length === 0) {
			return true;
		}

		return false;
	}

	render() {
		const {
			isFetchingData,
			selectedTaskDetails,
			receivedList,
			adjustedList,
			confirmedList,
			confirmedListOrder,
			receivedListOrder,
			adjustedListOrder,
			selectListItem,
			selectedListItem,
			updateItemCount,
			receivedDataList,
			additionalItemsOrder,
			additionalItems,
			search,
			setSearch,
			clearSearch,
		} = this.props;

		const { executing } = this.state;

		return (
			<div>
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={selectedTaskDetails.supplier}
					rightContent={
						executing ? (
							<Loader size="tiny" active />
						) : (
							<Button
								type="text"
								onClick={this.onSignClick}
								disabled={Object.keys(receivedList).length !== 0}>
								Sign
							</Button>
						)
					}
				/>
				{isFetchingData ? (
					<LoaderPage text="Fetching Products..." />
				) : (
					<ReceivedCount
						receivedList={receivedList}
						adjustedList={adjustedList}
						confirmedList={confirmedList}
						confirmedListOrder={confirmedListOrder}
						receivedListOrder={receivedListOrder}
						adjustedListOrder={adjustedListOrder}
						selectListItem={selectListItem}
						selectedListItem={selectedListItem}
						updateItemCount={updateItemCount}
						receivedDataList={receivedDataList}
						taskDetails={selectedTaskDetails}
						additionalItemsOrder={additionalItemsOrder}
						additionalItems={additionalItems}
						search={search}
						setSearch={setSearch}
						clearSearch={clearSearch}
					/>
				)}
			</div>
		);
	}
}

OrderPageContainer.propTypes = {
	isFetchingData: PropTypes.bool,
	selectedTaskDetails: PropTypes.object,
	receivedList: PropTypes.object,
	adjustedList: PropTypes.object,
	confirmedList: PropTypes.object,
	confirmedListOrder: PropTypes.array,
	receivedListOrder: PropTypes.array,
	adjustedListOrder: PropTypes.array,
	additionalItems: PropTypes.object,
	additionalItemsOrder: PropTypes.array,
	setReceievedItems: PropTypes.func,
	selectListItem: PropTypes.func,
	resetState: PropTypes.func,
	selectedListItem: PropTypes.string,
	updateItemCount: PropTypes.func,
	receivedDataList: PropTypes.object,
	setFetchingData: PropTypes.func,
	search: PropTypes.string,
	setSearch: PropTypes.func,
	setActiveReceiveTask: PropTypes.func,
	clearSearch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setReceievedItems,
			selectListItem,
			updateItemCount,
			setFetchingData,
			setActiveReceiveTask,
			resetState,
			setSearch,
			clearSearch,
		},
		dispatch
	);
};

const mapStateToProps = (state) => ({
	receivedList:
		state.received.data?.[state.received.data?.taskId]?.receivedList ?? {},
	adjustedList:
		state.received.data?.[state.received.data?.taskId]?.adjustedList ?? {},
	additionalItems:
		state.received.data?.[state.received.data?.taskId]?.additionalItems ?? {},
	receivedDataList:
		state.received.data?.[state.received.data?.taskId]?.receivedDataList ?? {},
	confirmedList:
		state.received.data?.[state.received.data?.taskId]?.confirmedList ?? {},

	//receivedList: state.received.data.receivedList,
	//adjustedList: state.received.data.adjustedList,
	//additionalItems: state.received.data.additionalItems,
	// receivedDataList: state.received.data.receivedDataList,
	//confirmedList: state.received.data.confirmedList,

	isFetchingData: state.received.ui.fetchingData,
	selectedTaskDetails: state.storeTasks.selectedTask,
	confirmedListOrder: state.received.ui.confirmedListOrder,
	receivedListOrder: state.received.ui.receivedListOrder,
	adjustedListOrder: state.received.ui.adjustedListOrder,
	selectedListItem: state.received.ui.selectedListItem,
	selectedWorkplace: state.workplaces.data.selectedWorkplace,
	additionalItemsOrder: state.received.ui.additionalItemsOrder,
	search: state.received.ui.search,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPageContainer);
