import { store } from 'store';

/**
 *
 * @param {Object}
 * @description Wrapper around fetch to make sure all calls can have authentication headers, same content type etc
 */
export default function fetchWrapper({
	endpoint = '',
	method = 'GET',
	headers = {},
	body = null,
}) {
	const state = store.getState();
	const accessToken = state?.auth?.user?.accessToken;

	const header = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...(accessToken && { Authorization: `Bearer ${accessToken}` }),
		//Authorization: `Bearer ${accessToken}`,
		...headers,
	};

	return fetch(endpoint, { headers: header, body, method }).then((response) => {
		// Wait for stream to complete before resolving
		if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
			if (response.status === 204) return response;
			else {
				return response.json().then((json) => {
					return response.ok ? json : Promise.reject(json);
				});
			}
		} else
			return response.blob().then((blob) => {
				return response.ok ? blob : Promise.reject(blob);
			});
	});
}
