import React, { Component, Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

class Toast extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isVisible: false
		};

		this.handleCloseClick = this.handleCloseClick.bind(this);
	}

	componentDidMount() {
		this._setTimeout();
	}

	/**
 * @function _setTimeout
 * @description Sets the timeout for the toast notification
 */
	_setTimeout() {
		const { timeout } = this.props;

		// Show
		setTimeout(() => {
			this.setState(() => ({ isVisible: true }));
		}, 100); // wait 100ms after the component is called to animate toast.

		// Timeout -1 displays toast as a persistent notification
		if (timeout === -1) {
			return;
		}

		// Hide after timeout
		setTimeout(() => {
			this.setState(() => ({ isVisible: false }));
		}, timeout);
	}

	handleCloseClick() {
		this.setState(() => ({ isVisible: false }));
	}

	render() { 
		const { text, type } = this.props;
		const { isVisible } = this.state;

		return (
			<Fragment>
				<CSSTransition key='navbar' in={isVisible} classNames="toast-up" timeout={{enter: 100, exit: 100}} unmountOnExit>
					<div className='toast'>
						<div className={`toast__wrapper toast__wrapper--${type}`}>
							<div className='toast__content'>
								{text}
							</div>
							<div className='toast__right-content' onClick={() => this.handleCloseClick()}>
							x
							</div>
						</div>
					</div>
				</CSSTransition>
			</Fragment>
		);
	}
}

Toast.propTypes = {
	text: PropTypes.string,
	timeout: PropTypes.number,
	type: PropTypes.string
};

Toast.defaultProps = {
	type: 'error',
	timeout: -1
};

export default Toast;