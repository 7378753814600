/**
 * @function fixItemCounterPosition
 * @description Fixes the count buttons in lists to the bottom so that they don't scroll to be overflowed
 */
function fixItemCounterPosition() {
	// Keep count button in same spot when it approaches the bottom of the page
	const countSection = document.getElementsByClassName('count-buttons')[0];
	const pageWrapper = document.getElementsByClassName('page-wrapper')[0];

	const scrollableWrapperHeight = pageWrapper.offsetHeight - 90;
	const countButtonSectionPosition =
		countSection.getBoundingClientRect().y - 20;
	if (
		countSection &&
		scrollableWrapperHeight - countButtonSectionPosition <= 110
	) {
		countSection.scrollIntoView(false);
		pageWrapper.scrollTop += 107;
	}
}

/**
 * @function scrollToCountButtons
 * @description Scrolls to count buttons
 */
function scrollToCountButtons() {
	const buttonSection = document.getElementsByClassName(
		'order-page__button-section'
	)[0];
	if (buttonSection) {
		document
			.getElementsByClassName('page-wrapper')[0]
			.scrollTo({
				top: buttonSection.offsetTop - window.innerHeight + 319,
				behavior: 'smooth',
			});
	}
}

/**
 * @function sortObjectByAlphabet
 * @description Sorts an objects contents alphabetically and returns an array of the sorted keys
 * @param {object} list
 */
function sortObjectByAlphabet(list = {}) {
	return Object.keys(list).sort((key, key2) => {
		const item = list[key];
		const item2 = list[key2];

		if (item.name < item2.name) {
			return -1;
		}
		if (item.name > item2.name) {
			return 1;
		}
		return 0;
	});
}

export default {
	fixItemCounterPosition,
	sortObjectByAlphabet,
	scrollToCountButtons,
};
