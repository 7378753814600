import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loader } from '@dumb';

import './loaderPage.css';

class LoaderPage extends Component {

	render() {
		const { text } = this.props;

		return (
			<div className='loader-wrapper'>
				<Loader active size='large'>{text}</Loader>
			</div>
		);
	}
}

LoaderPage.propTypes = {
	text: PropTypes.string,
};

export default LoaderPage;