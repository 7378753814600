export default {
	name: 'storetast',
	env: process.env.NODE_ENV,
	// redirectBetaUsers: process.env.REDIRECT_BETA_USERS,
	// newsUrl: process.env.NEWS_URL,
	// api: {
	// 	protocol: 'https',
	// 	host: process.env.wpApiHost,
	// 	path: process.env.wpApiPath,
	// 	version: process.env.wpApiVersion
	// },
	// auth0: {
	// 	clientId: process.env.auth0ClientId,
	// 	domain: process.env.auth0Domain,
	// 	connection: process.env.auth0Connection
	// },
	// usersnap: {
	// 	key: process.env.usersnap
	// }
};
