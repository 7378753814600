import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toggleNavigation } from '@smart/navigation/store/navigation.actions.js';
import ConfirmationPage from './confirmationPage.component';
import { Navigation } from '@smart';
import { Burger } from '../../library/components';
import phrases from './confirmationPage.phrases.js';

import './confirmationPage.css';

class ConfirmationPageContainer extends Component {
	render() {
		const { text, toggleNavigation } = this.props;

		return (
			<Fragment>
				<Navigation 
					leftContent={<Burger onClick={() => toggleNavigation(true)} />}
					header={phrases.NAV_HEADER}
				/>
				<ConfirmationPage text={text} />
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		toggleNavigation
	}, dispatch);
};

ConfirmationPageContainer.propTypes = {
	text: PropTypes.string,
	toggleNavigation: PropTypes.func
};

export default connect(null, mapDispatchToProps)(ConfirmationPageContainer);