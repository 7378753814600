import { store } from '../../../../store.js';

// ******************************
// GLOBAL APP ACTIONS
// ******************************

export const TOGGLE_NAVIGATION = 'APP/TOGGLE_NAVIGATION';
export const SET_NAVBAR = 'APP/SET_NAVBAR';
export const SET_NAVBAR_VISIBILITY = 'APP/SET_NAVBAR_VISIBILITY';
export const SET_NAVBAR_RELATIVE = 'APP/SET_NAVBAR_RELATIVE';
export const SET_NAVBAR_MOVEMENT = 'APP/SET_NAVBAR_MOVEMENT';

/**
 * @function toggleNavigation
 * @description Toggles if the navigation dropdown is shown
 * @param {bool} payload
 */
export const toggleNavigation = (payload) => {
	return { type: TOGGLE_NAVIGATION, payload };
};

/**
 * @function setNavBarContent
 * @description Sets the content of the navbar
 * @param {object} payload
 */
export const setNavBarContent = (payload) => {
	store.dispatch({ type: SET_NAVBAR, payload });
};

/**
 * @function setNavBarVisibility
 * @description sets Navbar visibility
 * @param {bool} payload
 */
export const setNavBarVisibility = (payload) => {
	return { type: SET_NAVBAR_VISIBILITY, payload };
};

/**
 * @function setNavBarRelative
 * @description sets Navbar postition to relative
 * @param {bool} payload
 */
export const setNavBarRelative = (payload) => {
	return { type: SET_NAVBAR_RELATIVE, payload };
};

/**
 * @function setNavBarRelative
 * @description sets Navbar postition to relative
 * @param {bool} payload
 */
export const setNavBarMovement = (payload, closeNavbar = false) => {
	return { type: SET_NAVBAR_MOVEMENT, payload, closeNavbar };
};
