import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { List, ListItem } from '../../../../library/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DayTasks extends Component {
	renderRightContent(taskTitle) {
		const { supplier, suppliers } = this.props;
		return suppliers[supplier][taskTitle].status === 'executed' ? (
			<FontAwesomeIcon icon={['far', 'check-circle']} size="lg" />
		) : null;
	}

	render() {
		const { supplier, date, suppliers, onTaskSelected } = this.props;

		return (
			<div>
				<List>
					{Object.keys(suppliers[supplier]).map((taskId) => {
						const suppliersTask = _get(
							suppliers,
							`['${supplier}'][${taskId}]`,
							'Task'
						);

						return (
							<ListItem
								key={taskId}
								rightContent={this.renderRightContent(taskId)}
							>
								<div className="list-item-link">
									<div
										onClick={() =>
											onTaskSelected({
												supplier,
												taskTitle: suppliersTask.title,
												date,
												task: suppliersTask,
												taskId
											}
											)}
									>
										{suppliersTask.title}{' '}
										{supplier !== 'null' ? ` - ${supplier}` : ''}
									</div>
								</div>
							</ListItem>
						);
					})}
				</List>
			</div>
		);
	}
}

DayTasks.propTypes = {
	supplier: PropTypes.string,
	suppliers: PropTypes.object,
	date: PropTypes.string,
	onTaskSelected: PropTypes.func,
};

export default DayTasks;
