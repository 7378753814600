import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app.jsx';
import { store, persistor } from './store.js';
import { addUsersnapService } from './services/tracking/usersnap.service';
import { PersistGate } from 'redux-persist/es/integration/react';

import { unregister } from './registerServiceWorker.js';
// Use Router over BrowserRouter for custom history module
import { Router } from 'react-router';
// Create history instance
import history from './routerHistory.js';

// Log Rocket stuff
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// Sentry error reporting
import { configSentry } from './errorReporting';

// analytics
import { initialize as initializeAnalytics } from '@tools/tracking/analytics.service';
import './index.css';

configSentry();
initializeAnalytics();

setupLogRocketReact(LogRocket);
addUsersnapService();
if (process.env.NODE_ENV === 'production') LogRocket.init('ydjtdv/storetask');

ReactDOM.render(
	<Router history={history}>
		<Provider store={store}>
			<PersistGate
				loading="loading"
				persistor={persistor}
			>
				<App />
			</PersistGate>
		</Provider>
	</Router>,
	document.getElementById('root')
);

unregister();
