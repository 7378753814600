import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './list.css';

class List extends Component {
	render() {
		const { children, className, smallItem } = this.props;

		const classnames = cx('list', {
			[`${className}`]: className
		});

		const itemClassName = cx('list__item', {
			'list__item--small': smallItem
		});

		// const style = smallItem ? { marginRight: '25px', transition: 'margin-right 300ms ease-in-out'}: {marginRight: '0px', transition: 'margin-right 300ms ease-in-out'};

		return (
			<ul className={classnames}>
				{React.Children.map(children, (child, i) => (
					<li className={itemClassName} key={i}>
						{child}
					</li>
				))}
			</ul>
		);
	}
}

List.defaultProps = {
	smallItem: false
};

List.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	smallItem: PropTypes.bool
};

export default List;
