import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import PageWrapper from './pagesRouterWrapper';
import { set as setAnalyticsParams } from '@tools/tracking/analytics.service';
import { destroyUsersnap } from './../services/tracking/usersnap.service';

import { store, setBrowserValidity } from '../store.js';

// Tools
import LogRocket from 'logrocket';

import {
	OrderPage,
	StoreTasks,
	ConfirmationPage,
	OrderJustification,
	CountPage,
	CountAreasList,
	AreaConfiguration,
	AreaCountOverview,
	CountReview,
	ReceivePage,
	WorkplaceSelection,
	CountReviewZero,
} from './index';
import { Button } from '@dumb';
import LoaderPage from './loaderPage/loaderPage.component';
import Auth from '../auth';
import ConfirmationPhrases from './confirmationPage/confirmationPage.phrases';

const auth = new Auth();

const handleAuthentication = (nextState) => {
	if (/access_token|id_token|error/.test(nextState.location.hash)) {
		auth.handleAuthentication();
	}
};

const closeModal = () => {
	store.dispatch(setBrowserValidity({ validity: true, cancelled: true }));
};

const checkIfAuthenticated = async ({ user }) => {
	const isAuthenticated = auth.isAuthenticated();

	if (!isAuthenticated) {
		destroyUsersnap();
		return auth.login();
	}
	const state = store.getState();

	const personId = state?.auth?.user?.personId;

	Sentry.configureScope((scope) => {
		scope.setUser({ id: personId });
		LogRocket.getSessionURL((sessionsUrl) => {
			scope.setExtra('sessionURL', sessionsUrl);
		});
	});

	setAnalyticsParams({
		userId: personId,
	});

	LogRocket.identify(personId);
};

const ProtectedRoute = ({ component: Component, ...props }) => {
	checkIfAuthenticated(props);
	return (
		<Route
			{...props}
			render={(props) => (
				<PageWrapper
					render={(router) => <Component {...props} match={router.match} />}
				/>
			)}
		/>
	);
};

ProtectedRoute.propTypes = {
	component: PropTypes.func,
};

function PagesRoutes({ location, global }) {
	return (
		<>
			{!global.isValidBrowser && !global.isCancelled && (
				<div className="intro-message">
					<div>
						<h2>Yo! Your software is out of date.</h2>
						<p>
							Some things might not work! Download newest version of{' '}
							<a href="https://www.google.com/chrome/">Chrome</a> (or Safari) to
							continue!
						</p>
						<Button type="small" size="default" onClick={() => closeModal()}>
							Close
						</Button>
					</div>
				</div>
			)}

			<TransitionGroup
				childFactory={(child) =>
					React.cloneElement(child, {
						classNames: location.state ? location.state.transition : '',
						timeout: location.state ? location.state.duration : 0,
					})}
			>
				{/* Timeout is defined in order to get rid of warning message */}
				<CSSTransition key={location.key} timeout={0}>
					<div className="page-wrapper">
						{/* Passing location gets rid of animation issue rendering the same exiting and entering route
						This happens as location is immutable and the base location is not */}
						<Switch location={location}>
							<ProtectedRoute exact path="/" component={StoreTasks} />
							<ProtectedRoute exact path="/tasks" component={StoreTasks} />
							{/* RECEIVE PAGES */}
							<ProtectedRoute
								exact
								path="/tasks/receive"
								component={ReceivePage}
							/>
							{/* WORKPLACES SELECTION */}
							<ProtectedRoute
								exact
								path="/workplaces"
								component={WorkplaceSelection}
							/>
							{/* ORDER PAGES */}
							<ProtectedRoute exact path="/tasks/order" component={OrderPage} />
							<ProtectedRoute
								exact
								path="/tasks/order/justification"
								component={OrderJustification}
							/>
							<ProtectedRoute
								exact
								path="/tasks/order/confirmation"
								component={() => (
									<ConfirmationPage
										text={ConfirmationPhrases.ORDER_CONFIRMATION_TEXT}
									/>
								)}
							/>
							{/* COUNT PAGES */}
							<ProtectedRoute
								exact
								path="/tasks/areas"
								component={CountAreasList}
							/>
							<ProtectedRoute
								exact
								path="/tasks/areas/add"
								component={AreaConfiguration}
							/>
							<ProtectedRoute
								exact
								path="/tasks/areas/edit/:areaId"
								component={AreaConfiguration}
							/>
							<ProtectedRoute
								exact
								path="/tasks/areas/overview"
								component={AreaCountOverview}
							/>
							<ProtectedRoute
								exact
								path="/tasks/count/:areaId"
								component={CountPage}
							/>
							<ProtectedRoute
								exact
								path="/tasks/pastcount"
								component={CountPage}
							/>
							<ProtectedRoute
								exact
								path="/tasks/counts/review"
								component={CountReview}
							/>
							<ProtectedRoute
								exact
								path="/tasks/counts/review-zero"
								component={CountReviewZero}
							/>
							<ProtectedRoute
								exact
								path="/tasks/counts/confirmation"
								component={() => (
									<ConfirmationPage
										text={ConfirmationPhrases.COUNT_CONFIRMATION_TEXT}
									/>
								)}
							/>
							<Route
								path="/callback"
								render={(props) => {
									handleAuthentication(props);
									return <LoaderPage text="Logging in..." />;
								}}
							/>
						</Switch>
					</div>
				</CSSTransition>
			</TransitionGroup>
			{/* This is the path that is rendered when logging in */}
		</>
	);
}

PagesRoutes.propTypes = {
	location: PropTypes.object,
	global: PropTypes.object,
};

const mapStateToProps = (state) => ({
	global: state.global,
	user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(PagesRoutes));
