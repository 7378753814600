import { SET_USER, SET_ACCESS_TOKEN } from 'actions/auth.actions';

const defaultState = {
	user: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_USER: {
		return {
			...state,
			user: payload,
		};
	}
	case SET_ACCESS_TOKEN: {
		return {
			...state,
			accessToken: payload,
		};
	}

	default:
		return state;
	}
}

export default reducer;
