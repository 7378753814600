// Tools
import _get from 'lodash/get';
import listService from '../../../../services/listService';
import { listTypes } from '../../../../services/common';
import _isString from 'lodash/isString'
;

import { initializeCountingAreaDataStructure } from './countpage.utils';

import {
	UPDATE_ITEM_COUNT,
	LIST_ITEM_SELECTED,
	SET_COUNT_AREA,
	// SIGN_AREA_COUNT,
	SET_SUGGESTED_ITEMS,
	SET_FETCHING_DATA,
	SET_ACTIVE_COUNT_AREA,
	RESET_STATE,
	RESET_COUNTS,
	SET_SEARCH,
	CLEAR_SEARCH,
	UPDATE_EDITED_TO_COUNTED,
	SET_DATE,
	CLEAR_SELECTED,
} from './countPage.actions';

const initState = {
	data: {
		updatedItem: {},
		countArea: {
			name: '',
		},
		taskId: null,
		payloadId: null,
		areaId: null,
		date: null,
	},
	ui: {
		listOrder: [],
		selectedListItem: '',
		fetchingData: false,
		search: '',
	},
};

export default (state = initState, action) => {
	const payload = action.payload;

	switch (action.type) {
		case SET_SUGGESTED_ITEMS: {
			const fetchedData = payload.data;
			const suggestedList = {};
			const itemList = {};
			const restItems = {};
			const rawProductList = {};
			const taskId = state.data.taskId;
			const areaId = state.data.areaId;

			const countArea = state.data?.[taskId]?.[areaId] ?? {};
			const countedInArea = countArea?.counted ?? {};

			const definedSuggestionIds = _get(
				state,
				'data.countArea.globalRawProducts.data',
				[]
			);

			// If suggested item is already counted don't add it to the suggested item list
			fetchedData.map((item) => {
				const rawProductId = _get(
					item,
					'supplier_raw_product.data.global_raw_product.data.id',
					null
				);

				const rawSuplierName = _get(
					item,
					'supplier_raw_product.data.name',
					null
				);
				const globalRawProductName = _get(
					item,
					'supplier_raw_product.data.global_raw_product.data.name',
					null
				);
				let description = _get(
					item,
					'supplier_raw_product.data.description',
					null
				);
				const nameSubProduct = description;

				// Split unit formatting
				if (item.supplier_raw_product.data.description.match(/^\d /)) {
					description = item.supplier_raw_product.data.description
						.split(/\s+/)
						.slice(1, 2)[0];
				}

				const itemQuantity = item.quantity ? item.quantity : '';

				// Format count unit object
				const unitInfo = {
					unit: nameSubProduct || rawSuplierName,
					productName: rawSuplierName,
					count: itemQuantity,
					id: item.id,
					description,
					unitName: item.unit_name,
					image: _get(item, 'supplier_raw_product.data.thumbnail_url', null),
				};

				if (rawProductList[rawProductId]) {
					// This is required if its repeat rawProductItem, so push into same product
					itemList[rawProductList[rawProductId]].units.push(unitInfo);
				} else {
					// Craete objectand push it to
					const formattedItem = {
						id: item.id,
						rawProductId,
						name: globalRawProductName,
						// name: rawSuplierName,
						description: _get(
							item,
							'supplier_raw_product.data.description',
							null
						),
						units: [unitInfo],
					};

					rawProductList[rawProductId] = formattedItem.id;
					itemList[item.id] = formattedItem;

					// If the item is NOT counted already add it to the suggested list
					if (!countedInArea[item.id]) {
						// Check if its part of
						if (definedSuggestionIds.some((x) => x.id === rawProductId)) {
							suggestedList[item.id] = formattedItem;
						} else {
							restItems[item.id] = formattedItem;
						}
					}
				}

				return item;
			});

			// Order list items alphabetically
			const listOrder = listService.sortObjectByAlphabet(itemList);

			return {
				...state,
				data: {
					...state.data,
					taskId: payload.id,
					[taskId]: {
						...state.data[taskId],
						[areaId]: {
							...state.data[taskId][areaId],
							itemList,
							restItems,
							suggestions: suggestedList,
						},
					},
				},
				ui: { ...state.ui, listOrder },
			};
		}

		case UPDATE_ITEM_COUNT: {
			const taskId = state.data.taskId;
			const areaId = state.data.areaId;
			const countArea = state.data?.[taskId]?.[areaId] ?? {};

			const updatedUnitArray = countArea[action.listStatus][
				action.id
			].units.map((unit) => {
				if (unit.id === action.payload.unit) {
					return { ...unit, count: action.payload.count };
				}
				return unit;
			});

			const updatedItem = {
				...countArea[action.listStatus][action.id],
				units: updatedUnitArray,
			};

			return {
				...state,
				data: {
					...state.data,
					updatedItem: updatedItem,
					[taskId]: {
						...state.data[taskId],
						[areaId]: {
							...state.data[taskId][areaId],
							[action.listStatus]: {
								...state.data[taskId][areaId][action.listStatus],
								[action.id]: updatedItem,
							},
						},
					},
				},
			};
		}
		case LIST_ITEM_SELECTED: {
			const updatedListItem = state.data.updatedItem;
			let selectedListItem = payload;

			// SUGGESTIONS If the update is made to a suggested item
			const taskId = state.data.taskId;
			const areaId = state.data.areaId;
			const countArea = state.data?.[taskId]?.[areaId] ?? {};
			const countedInArea = countArea?.counted ?? {};
			const isSuggestionItem = countArea?.suggestions[updatedListItem.id];
			const isRestItem = countArea?.restItems[updatedListItem.id];
			const itemList = countArea?.itemList;

			const isCountedItem = countedInArea[updatedListItem.id];

			if (isSuggestionItem || isRestItem) {
				// default valuies

				// let difference - used to see if the item that was selected has a count difference
				// This is used in order to move the item from the suggestions list to the counted list
				let difference = false;
				updatedListItem.units.forEach((unit, index) => {
					if (
						unit.count !== '' &&
						Number(unit.count) !==
							itemList[updatedListItem.id].units[index].count
					) {
						difference = true;
					}
				});

				// If there is a difference move the item from the suggested list to the counted list
				if (difference) {
					const countedList = {
						...countedInArea,
						[updatedListItem.id]: updatedListItem,
					};

					// IF SUGGESTIONS ITEM: remove counted item from suggestions list
					const suggestionsListClone = { ...countArea.suggestions };
					if (isSuggestionItem) {
						delete suggestionsListClone[updatedListItem.id];
					}

					// IF REST ITEM: remove counted item from suggestions list
					const restListClone = { ...countArea.restItems };
					if (isRestItem) {
						delete restListClone[updatedListItem.id];
					}

					// if there is a change in count and an item is selected decrease the index by 1 as the list is reordered
					if (action.clickedOn) {
						var selectedList = selectedListItem.split('-')[0];
						var itemKey = Number(selectedListItem.split('-')[1]);
						// if the newly selected item is smaller than the previous item don't add + 1 to selected item key, as items before the previous count won't
						// be assigned a new key. If selecting an item placed after the current item add 1 as the the currently selected item will move to a different list
						// and the all items after the moved item will get a new key of 1 less.
						if (itemKey < Number(state.ui.selectedListItem.split('-')[1]))
							selectedListItem = `${selectedList}-${itemKey}`;
						else selectedListItem = `${selectedList}-${itemKey - 1}`;
					}

					return {
						...state,
						data: {
							...state.data,
							[taskId]: {
								...state.data[taskId],
								[areaId]: {
									...state.data[taskId][areaId],
									suggestions: suggestionsListClone,
									restItems: restListClone,
									counted: { ...countedList },
									updatedItem: {},
								},
							},
							updatedItem: {},
						},
						ui: {
							...state.ui,
							selectedListItem,
						},
					};
				}
			}

			// If a counted items count is set back to '' move the item back to the SUGGESTIONS or
			if (isCountedItem) {
				let isCountZero = true;

				updatedListItem.units.forEach((unit) => {
					const unitCount = _isString(unit.count)
						? unit.count.replace(/,/g, '.')
						: unit.count;

					if (Number(unitCount) > '') isCountZero = false;
				});

				if (isCountZero) {
					let suggestedList = countArea.suggestions;
					let restItems = countArea.restItems;

					// WHICH LIST TO SAVE TO
					// Not the best way, but simply get the list of suggestions that come from area object
					// settings and then check if its from there else, just put to the "rest" list
					const areaSuggestionList = _get(
						state,
						'data.countArea.globalRawProducts.data',
						[]
					);

					if (
						areaSuggestionList.some(
							(x) => x.id === updatedListItem.rawProductId
						)
					) {
						suggestedList = {
							...suggestedList,
							[updatedListItem.id]: updatedListItem,
						};
					} else {
						restItems = {
							...restItems,
							[updatedListItem.id]: updatedListItem,
						};
					}

					// remove item with 0 count for all units from counted list
					const countListClone = { ...countedInArea };
					delete countListClone[updatedListItem.id];

					return {
						...state,
						data: {
							...state.data,
							updatedItem: {},
							[taskId]: {
								...state.data[taskId],
								[areaId]: {
									...state.data[taskId][areaId],
									counted: countListClone,
									suggestions: { ...suggestedList },
									restItems: { ...restItems },
									updatedItem: {},
								},
							},
						},
						ui: {
							...state.ui,
							selectedListItem,
						},
					};
				}

				const selectedListSplit = selectedListItem.split('-')[0];
				const itemKeySplit = Number(selectedListItem.split('-')[1]);

				// If is selected from enter press, this is done in order to select items that are not counted in order
				if (action.autoPick) {
					selectedListItem = `${selectedListSplit}-${itemKeySplit + 1}`;
				}

				// If a an already counted item is modified again save the modification to local storage
				const countList = {
					...countedInArea,
					[updatedListItem.id]: updatedListItem,
				};

				return {
					...state,
					data: {
						...state.data,
						[taskId]: {
							...state.data[taskId],
							[areaId]: {
								...state.data[taskId][areaId],
								counted: countList,
								updatedItem: {},
							},
						},
						updatedItem: {},
					},
					ui: { ...state.ui, selectedListItem: selectedListItem },
				};
			}

			var selList = selectedListItem.split('-')[0];
			var itKey = Number(selectedListItem.split('-')[1]);

			// If is selected from enter press, this is done in order to select items that are not counted in order
			if (action.autoPick) {
				selectedListItem = `${selList}-${itKey + 1}`;
			} else if (itKey === Number(state.ui.selectedListItem.split('-')[1])) {
				selectedListItem = '';
			}

			// If an item is selected and there have been no differences in count
			return {
				...state,
				ui: { ...state.ui, selectedListItem },
			};
		}

		case SET_COUNT_AREA: {
			return {
				...state,
				data: { ...state.data, countArea: payload },
			};
		}

		case UPDATE_EDITED_TO_COUNTED: {
			const updatedListItem = state.data.updatedItem;
			const taskId = state.data.taskId;
			const areaId = state.data.areaId;
			const countArea = state.data?.[taskId]?.[areaId] ?? {};
			const countedInArea = countArea?.counted ?? {};

			let difference = false;

			const isSuggestionList =
				updatedListItem.listStatus === listTypes.SUGGESTIONS_LIST;
			const isRestList = updatedListItem.listStatus === listTypes.REST_ITEMS;
			const isCountList = updatedListItem.listStatus === listTypes.COUNTED_LIST;

			// only if its part of suggestions list (count list auto updates)
			if (isSuggestionList || isRestList || isCountList) {
				updatedListItem.units.forEach((unit) => {
					if (unit.count !== '') difference = true;
				});
			} else {
				return state;
			}

			let countedList = {};
			if (difference) {
				countedList = {
					...countedInArea,
					[updatedListItem.id]: updatedListItem,
				};
			} else {
				return state;
			}

			// remove counted item from suggestions list
			const suggestionsListClone = { ...countArea.suggestions };
			if (isSuggestionList) {
				delete suggestionsListClone[updatedListItem.id];
			}

			// remove fromrest list
			const restItemsClone = { ...countArea.restItems };
			if (isRestList) {
				delete restItemsClone[updatedListItem.id];
			}

			return {
				...state,
				data: {
					...state.data,
					updatedItem: {},
					[taskId]: {
						...state.data[taskId],
						[areaId]: {
							...state.data[taskId][areaId],
							suggestions: suggestionsListClone,
							counted: { ...countedList },
							restItems: restItemsClone,
							updatedItem: {},
						},
					},
				},
				ui: {
					...state.ui,
					selectedListItem: '',
				},
			};
		}
		case SET_FETCHING_DATA: {
			return { ...state, ui: { ...state.ui, fetchingData: action.payload } };
		}
		case RESET_STATE: {
			return { ...initState };
		}
		case RESET_COUNTS: {
			return {
				...initState,
				data: {
					...initState.data,
				},
			};
		}
		case SET_DATE: {
			return { ...state, data: { ...state.data, date: action.payload } };
		}
		case SET_SEARCH: {
			return { ...state, ui: { ...state.ui, search: action.payload } };
		}
		case SET_ACTIVE_COUNT_AREA: {
			const taskId = action.taskId;
			const areaId = action.areaId;

			return {
				...state,
				data: {
					...state.data,
					taskId,
					areaId,
					...initializeCountingAreaDataStructure({
						data: state.data,
						taskId,
						areaId,
					}),
				},
			};
		}
		case CLEAR_SEARCH: {
			return { ...state, ui: { ...state.ui, search: '' } };
		}
		case CLEAR_SELECTED: {
			return { ...state, ui: { ...state.ui, selectedListItem: '' } };
		}
		default:
			return state;
	}
};
