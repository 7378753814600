import React, { Component } from 'react';
import PropTypes from 'prop-types';

import phrases from './orderJustification.phrases.js';

import './orderJustification.css';

class orderJustification extends Component {
	constructor(props) {
		super(props);

		this.onJustificationUpdate = this.onJustificationUpdate.bind(this);
	}

	onJustificationUpdate(event) {
		const { updateOrderJustification } = this.props;

		updateOrderJustification(event.target.value);
	}

	render() {
		const { justification } = this.props;

		return (
			<div className='order-justification'>
				<textarea className="justification-text-area" onChange={(event) => this.onJustificationUpdate(event)} value={justification} placeholder={phrases.PLACEHOLDER} />
			</div>
		);
	}
}

orderJustification.propTypes = {
	justification: PropTypes.string,
	updateOrderJustification: PropTypes.func
};

export default orderJustification;