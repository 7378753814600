import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	updateOrderList,
	selectListItem,
	setOrderData,
	fetchOrderItems,
	executeTask,
	executingTask,
	setActiveOrder,
	orderFetchSuccess,
	setFetchingData,
	toggleSuggestionsList,
	setSearch,
	clearSearch,
} from './store/orderPage.actions.js';
import LoaderPage from '../../loaderPage/loaderPage.component.jsx';
import OrderPage from './orderPage.component.jsx';
import { Button, Loader } from '@dumb';
import { Navigation } from '@smart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { getSelectedTaskData } from '../../storeTasks/store/storeTasks.services';
import history from '../../../routerHistory';
import toast from '../../../library/components/toast/toast';

import './orderPage.css';

class OrderPageContainer extends Component {
	constructor(props) {
		super(props);

		this.onSignClick = this.onSignClick.bind(this);
		this.toggleSuggestionsList = this.toggleSuggestionsList.bind(this);
	}

	onSignClick() {
		const {
			adjustedList,
			selectedTask,
			confirmedList,
			executingTask,
			selectedWorkplace,
		} = this.props;
		const path =
			Object.keys(adjustedList).length > 0 ? 'justification' : 'confirmation';
		const finalList = Object.assign({}, confirmedList, adjustedList);

		executingTask(true);
		executeTask(selectedTask, finalList, selectedWorkplace)
			.then(() => {
				executingTask(false);
				history.push({
					pathname: `/tasks/order/${path}`,
					state: { transition: 'slide-left', duration: 150 },
				});
			})
			.catch((error) => {
				executingTask(false);
				toast.show(`Error: ${error.message}`, 'error', 3000);
			});
	}

	onBackClick() {
		history.push({
			pathname: '/tasks',
			state: { transition: 'slide-right', duration: 150 },
		});
	}

	componentDidMount() {
		const {
			orderFetchSuccess,
			setFetchingData,
			selectedTask,
			selectedWorkplace,
			setActiveOrder
		} = this.props;

		setFetchingData(true);

		setActiveOrder(selectedTask.taskId);
		// Fetch data from API
		fetchOrderItems(selectedTask, selectedWorkplace)
			.then((items) => {
				setFetchingData(false);
				orderFetchSuccess(items.data, selectedTask.taskId);
			})
			.catch((error) => {
				toast.show(`Error: ${error.message}`, 'error', 3000);
			});
	}

	checkIfAllOrderReviewed(notConfirmedList) {
		if (Object.keys(notConfirmedList).length === 0) {
			return true;
		}

		return false;
	}

	toggleSuggestionsList() {
		const { toggleSuggestionsList, isSuggestionsCollapsed } = this.props;

		toggleSuggestionsList(!isSuggestionsCollapsed);
	}

	render() {
		const {
			updateOrderList,
			dataOrderList,
			confirmedList,
			adjustedList,
			notConfirmedList,
			selectListItem,
			selectedListItem,
			selectedTask,
			isNavigationVisible,
			notConfirmedListOrder,
			suggestionsList,
			suggestionsListOrder,
			confirmedListOrder,
			isFetchingData,
			adjustedListOrder,
			isSuggestionsCollapsed,
			isExecutingTask,
			search,
			setSearch,
			clearSearch,
		} = this.props;

		const orderReviewComplete = this.checkIfAllOrderReviewed(notConfirmedList);

		return (
			<div>
				<Navigation
					leftContent={
						<Button type="text" onClick={this.onBackClick}>
							<FontAwesomeIcon icon={['fas', 'angle-left']} size="2x" />
						</Button>
					}
					header={selectedTask.supplier}
					rightContent={
						selectedTask.status === 'executed' ? null : isExecutingTask ? (
							<Loader size="tiny" active />
						) : (
							<Button
								type="text"
								onClick={this.onSignClick}
								disabled={!orderReviewComplete || isExecutingTask}>
								Sign
							</Button>
						)
					}
				/>
				{isFetchingData ? (
					<LoaderPage text="Fetching Products..." />
				) : (
					<OrderPage
						dataOrderList={dataOrderList}
						updateOrderList={updateOrderList}
						confirmedList={confirmedList}
						adjustedList={adjustedList}
						notConfirmedList={notConfirmedList}
						selectListItem={selectListItem}
						selectedListItem={selectedListItem}
						selectedTaskDetails={selectedTask}
						isNavigationVisible={isNavigationVisible}
						notConfirmedListOrder={notConfirmedListOrder}
						suggestionsList={suggestionsList}
						suggestionsListOrder={suggestionsListOrder}
						confirmedListOrder={confirmedListOrder}
						adjustedListOrder={adjustedListOrder}
						isSuggestionsCollapsed={isSuggestionsCollapsed}
						toggleSuggestionsList={this.toggleSuggestionsList}
						setSearch={setSearch}
						clearSearch={clearSearch}
						search={search}
					/>
				)}
			</div>
		);
	}
}

OrderPageContainer.propTypes = {
	updateOrderList: PropTypes.func,
	confirmedList: PropTypes.object,
	adjustedList: PropTypes.object,
	notConfirmedList: PropTypes.object,
	dataOrderList: PropTypes.object,
	selectListItem: PropTypes.func,
	selectedListItem: PropTypes.string,
	setOrderData: PropTypes.func,
	selectedTask: PropTypes.object,
	isNavigationVisible: PropTypes.bool,
	orderFetchSuccess: PropTypes.func,
	notConfirmedListOrder: PropTypes.array,
	suggestionsListOrder: PropTypes.array,
	suggestionsList: PropTypes.object,
	confirmedListOrder: PropTypes.array,
	setFetchingData: PropTypes.func,
	isFetchingData: PropTypes.bool,
	taskId: PropTypes.string,
	adjustedListOrder: PropTypes.array,
	isSuggestionsCollapsed: PropTypes.bool,
	toggleSuggestionsList: PropTypes.func,
	isExecutingTask: PropTypes.bool,
	executingTask: PropTypes.func,
	setActiveOrder: PropTypes.func,
	search: PropTypes.string,
	setSearch: PropTypes.func,
	clearSearch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateOrderList,
			selectListItem,
			setOrderData,
			setActiveOrder,
			executingTask,
			orderFetchSuccess,
			setFetchingData,
			toggleSuggestionsList,
			setSearch,
			clearSearch,
		},
		dispatch
	);
};

const mapStateToProps = (state) => ({
	dataOrderList: state.order.data?.[state.order.data.taskId]?.orderList ?? {},
	confirmedList: state.order.data?.[state.order.data.taskId]?.confirmedList ?? {},
	adjustedList: state.order.data?.[state.order.data.taskId]?.adjustedList ?? {},
	notConfirmedList: state.order.data?.[state.order.data.taskId]?.notConfirmedList ?? {},
	suggestionsList: state.order.data?.[state.order.data.taskId]?.suggestionsList ?? {},
	taskId: state.order.data.taskId,
	selectedListItem: state.order.ui.selectedListItem,
	selectedTask: state.storeTasks.selectedTask,
	isNavigationVisible: state.navigation.isVisible,
	isExecutingTask: state.order.ui.executingTask,
	notConfirmedListOrder: state.order.ui.notConfirmedListOrder,
	suggestionsListOrder: state.order.ui.suggestionsListOrder,
	confirmedListOrder: state.order.ui.confirmedListOrder,
	adjustedListOrder: state.order.ui.adjustedListOrder,
	selectedWorkplace: state.workplaces.data.selectedWorkplace,
	isFetchingData: state.order.ui.fetchingData,
	isSuggestionsCollapsed: state.order.ui.isSuggestionsCollapsed,
	search: state.order.ui.search,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPageContainer);
