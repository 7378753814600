export const STORE_TASKS_FETCH_SUCCESS = 'STORETASK/STORE_TASKS_FETCH_SUCCESS';
export const SET_SELECTED_TASK_DATA = 'STORETASK/SET_SELECTED_TASK_DATA';
export const FETCHING_STORE_TASKS = 'STORETASK/FETCHING_STORE_TASKS';
export const SET_SELECTED_DATES = 'STORETASK/SET_SELECTED_DATES';

/**
 * @function setStoreTasks
 * @description Fetches store tasks from API
 * @param {Object} payload takes an object of orderList item.
 */
export const setStoreTasks = payload => {
	return { type: STORE_TASKS_FETCH_SUCCESS, payload };
};

/**
 * @function setSelectedTaskData
 * @description Sets the selected task details for use in fetching data.
 * This is used for both count and order tasks.
 * @param {Object} payload takes an object of taskTitle, date and supplier.
 */
export const setSelectedTaskData = payload => {
	return { type: SET_SELECTED_TASK_DATA, payload };
};

/**
 * @function fetchingStoreTasks
 * @description Sets loading boolean
 * @param {boolean} payload loading bool
 */
export const fetchingStoreTasks = (payload) => {
	return { type: FETCHING_STORE_TASKS, payload };
};


/**
 * @function setSelectedDate
 * @description Sets selected dates
 * @param {object} payload from, to
 */
export const setSelectedDate = (payload) => {
	return {type: SET_SELECTED_DATES, payload};
};